import * as React from 'react'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'

const ModalDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}))

const ModalDialogTitle = (props) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

ModalDialogTitle.propTypes = {
    children: PropTypes.element,
    onClose: PropTypes.func,
}

const ModalDialogPage = (props) => {

    return (
        <div>
            {props.button}

            <ModalDialog
                fullWidth
                maxWidth="lg"
                onClose={props.close}
                aria-labelledby="customized-dialog-title"
                open={props.open}
            >
                <ModalDialogTitle
                    id="customized-dialog-title"
                    onClose={props.close}>
                    <Typography>
                        {props.title}
                    </Typography>
                </ModalDialogTitle>
                <DialogContent dividers>
                    {props.children}
                </DialogContent>
            </ModalDialog>
        </div>
    )
}

ModalDialogPage.propTypes = {
    children: PropTypes.element,
    button: PropTypes.element,
    open: PropTypes.bool,
    close: PropTypes.func,
    title: PropTypes.string
}


export default ModalDialogPage