import { formateDateTimeString } from "src/utils/format-date"
import { formatDecimalCase } from "src/utils/format-decimal"

export default class TableFormatterService {

    verifyTableStatus(value, from) {
        let text = "Não informado"
        let color = "error"
        switch (value) {
            case "DONE":
                text = `${from} | Finalizado`
                color = "success"
                break
            case "FILE_PROCESSING":
                text = `${from} | Validação do Arquivo`
                color = "primary"
                break
            case "IN_PROGRESS":
                text = `${from} | Em Andamento`
                color = "warning"
                break
            case "READY_TO_CALCULATE":
                text = `${from} | Pronto para Calcular PD`
                color = "primary"
                break
            case "CALCULATING":
                text = `${from} | Calculando PD`
                color = "primary"
                break
            case "PENDING":
                text = `${from} | Aguardando`
                color = "warning"
                break
            case "ERROR":
                text = `${from} | Erro no Cálculo`
                color = "error"
                break
            case "EXCEEDED_RETRIES":
                text = `${from} | Tentativas Excedidas`
                color = "error"
                break
            case "REPORT_IN_PROGRESS":
                text = `${from} | Gerando Relatório`
                color = "warning"
                break
            case "REPORT_DONE":
                text = `${from} | Relatório Finalizado`
                color = "success"
                break
        }

        return {
            text: text,
            color: color,
            rawStatus: value
        }
    }

    progressBarWallet(Arquivo, EstoqueLote) {
        const { TotalRegistros } = Arquivo
        const { OperacoesCalculadas } = EstoqueLote

        const valueBar = (OperacoesCalculadas / TotalRegistros) * 100
        let formatedValue = formatDecimalCase(valueBar)

        if (TotalRegistros < 0.1) {
            formatedValue = 0
        }

        return {
            registers: TotalRegistros,
            processed: OperacoesCalculadas,
            progress: Number(formatedValue)
        }
    }

    progressBarConcession(Arquivo, Concessao) {
        const { TotalRegistros } = Arquivo
        const { QuantidadeOperacoes } = Concessao
        const valueBar = (QuantidadeOperacoes / TotalRegistros) * 100
        let formatedValue = formatDecimalCase(valueBar)

        if (TotalRegistros < 0.1) {
            formatedValue = 0
        }
        return {
            registers: TotalRegistros,
            processed: QuantidadeOperacoes,
            progress: Number(formatedValue)
        }
    }

    formatDate(value) {
        if (value === null) {
            return ""
        }
        return formateDateTimeString(value)
    }

    getFileSize(size) {
        const mbSize = size / (1024 * 1024)
        return parseFloat(mbSize).toFixed(2)
    }


}