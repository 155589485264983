import React from 'react'
import { Fab } from "@mui/material"
import PropTypes from 'prop-types'

const FabButton = (props) => {

    return (
        <Fab
            onClick={props.action.onClick}
            color={props.action.color ? props.action.color : 'primary'}
            aria-label="add"
            id="fab-button-add"
            variant={props.action.contained ? props.action.contained : 'contained'}
            sx={{
                position: 'fixed',
                bottom: 16,
                right: 16,
            }}
        >
            {props.action.icon}
            {props.action.text}
        </Fab>
    )
}

FabButton.propTypes = {
    action: PropTypes.object
}


export default FabButton
