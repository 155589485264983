import { DataGrid } from "@mui/x-data-grid"
import React, { useEffect, useState } from "react"
import LayoutPage from "src/components/page-layout/layout-page"
import { Grid, Paper, Button } from "@mui/material"
import { localeBRDataGridText } from "src/utils/data-grid-local-text"
import SelectValue from "src/components/select-value"
import ReceivableService from "src/services/receivable-service"
import DatePickerSelectDate from "src/components/date/date-picker-select-date"
import dayjs from "dayjs"
import { DATE_FORMAT } from "src/utils/constants"
import { formatDecimalCaseReplaceComma } from "src/utils/format-decimal"

const MarketRatesPage = () => {
    const receivableService = new ReceivableService()
    const limiteDatas = 90
    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedMethod, setSelectedMethod] = useState(null)
    const [dateSelected, setDateSelected] = useState(null)
    const [methodList, setMethodList] = useState([])
    const [enableDateList, setEnableDateList] = useState([])

    useEffect(() => {
        loadModality()
        loadDates()
    }, [])

    const loadDates = () => {
        setLoading(true)
        receivableService.getLastDays(limiteDatas).then((response) => {
            const { body } = response
            const { data } = body

            const datas = data.map(item => item.DataReferencia)
            setEnableDateList(datas)

            if (datas.length > 0) {
                const newDate = dayjs(datas[0])
                changeDate(newDate)
            }

            setLoading(false)
        })
    }

    const loadModality = () => {
        setLoading(true)
        receivableService.getBacenSCR().then((response) => {
            const { body } = response
            const { results } = body

            const modalityList = results.map((result) => {
                return { value: `CodSegmento=${result.CodSegmento}&CodModalidade=${result.CodModalidade}`, description: `${result.ModalidadeBacen} ${result.Segmento}` }
            }).sort((a, b) => {
                // Convertendo as descrições para minúsculas para garantir uma comparação sem distinção entre maiúsculas e minúsculas
                let descricaoA = a.description.toLowerCase()
                let descricaoB = b.description.toLowerCase()
            
                if (descricaoA < descricaoB) {
                    return -1 // Retorna um valor negativo se 'a' deve vir antes de 'b'
                }
                if (descricaoA > descricaoB) {
                    return 1 // Retorna um valor positivo se 'a' deve vir depois de 'b'
                }
                return 0 // Retorna 0 se os dois são iguais
            })
            
            setMethodList(modalityList)
            setLoading(false)
        })
    }

    const loadTaxes = () => {
        const dateFormatted = dayjs(dateSelected).format(DATE_FORMAT)
        const query = `${selectedMethod}&DataReferencia=${dateFormatted}&limit=10000`

        setLoading(true)
        receivableService.getBacenTaxFees(query).then((response) => {
            const { body } = response
            const { results } = body

            const rows = results.map((obj, index) => ({ ...obj, id: index })).sort((a, b) => a.TaxaMes - b.TaxaMes)
            setRows(rows)
            setLoading(false)
        })
    }

    const columns = [
        {
            field: 'IF',
            headerName: 'IF',
            flex: 1,
        },
        {
            field: 'TaxaMes',
            headerName: 'Taxa Mês',
            flex: 1,
            renderCell: (params) => formatDecimalCaseReplaceComma(params.value),
        },
        {
            field: 'TaxaAno',
            headerName: 'Taxa Ano',
            flex: 1,
            renderCell: (params) => formatDecimalCaseReplaceComma(params.value),
        }
    ]

    const changeDate = (date) => {
        setDateSelected(date)
    }

    return (
        <LayoutPage title="Taxa de Mercado" loading={loading}>
            <Grid container alignItems="center" justifyContent="flex-end" spacing={3}>
                <Grid item sm={12} md={4} xl={4} xs={12}>
                    <SelectValue
                        label={"Modalidade"}
                        value={selectedMethod}
                        id="select-method"
                        changeValue={setSelectedMethod}
                        options={methodList} />
                </Grid>
                <Grid item>
                    <DatePickerSelectDate onChange={changeDate} enableDateList={enableDateList} value={dateSelected} />
                </Grid>
                <Grid item>
                    <Button variant={"contained"} disabled={selectedMethod === null || dateSelected === null} onClick={loadTaxes}>
                        Consultar
                    </Button>
                </Grid>
            </Grid>

            <br />

            <Grid container direction="row" justifyContent="center" spacing={2}>
                <Grid item xl={12} md={12} xs={12}>
                    <Paper>
                        <DataGrid
                            initialState={{
                                pagination: {
                                    paginationModel: { pageSize: 25, page: 0 },
                                },
                            }}
                            autoHeight
                            getRowHeight={() => 45}
                            rows={rows}
                            columns={columns}
                            pageSizeOptions={[25, 50, 100]}
                            disableRowSelectionOnClick
                            sortingOrder={['asc', 'desc']}
                            localeText={localeBRDataGridText}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </LayoutPage>
    )

}

export default MarketRatesPage