import React, { useState } from "react"
import PropTypes from 'prop-types'
import { AppBar, Button, Dialog, FormControlLabel, Grid, IconButton, Switch, Toolbar, Typography } from "@mui/material"
import LineChartComponent from "./line-chart"
import theme from "src/utils/theme"
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone'
import InsertPhotoTwoToneIcon from '@mui/icons-material/InsertPhotoTwoTone'
import { ExportFile } from "src/utils/export-file"

const LineChartModalComponent = (props) => {

    const [checked, setChecked] = useState(false)
    const exportFile = new ExportFile()
    const [chartTitle, setChartTitle] = useState("")

    const generateChartImage = () => {
        setChartTitle(`${props.title} - ${props.selectedDate}`)

        setTimeout(() => {
            const element = checked ? document.getElementById('chartCaptureTwo') : document.getElementById('chartCapture')
            exportFile.toImage(element, `${props.title}_${props.selectedDate}`)
            setChartTitle("")
        }, 100)
    }

    const generateCsvFile = () => {
        exportFile.toCSV(props.csvData, `${props.title}_${props.selectedDate}`, ";")
    }

    const handleChange = (event) => {
        if (event.target.value) {
            setChecked(event.target.checked)
        } else {
            setChecked(event.target.checked)
        }
    }

    return <Dialog
        fullScreen
        open={props.open}
        onClose={() => props.onClose()}>
        <AppBar theme={theme} sx={{ position: 'relative' }}>
            <Toolbar color="primary">
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => props.onClose()}
                    aria-label="close"
                >
                    <CloseRoundedIcon />
                </IconButton>
                <Grid container direction="row" alignItems={"center"} justifyContent="flex-start" spacing={1}>
                    <Grid item xs={12} xl={6} md={6}  sx={{ textAlign: "left" }}>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {`${props.title} - ${props.selectedDate}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} xl={6} md={6} sx={{ textAlign: "right" }}>
                        <Button color="primary" variant="contained" onClick={generateChartImage}>
                            <InsertPhotoTwoToneIcon />
                            &nbsp; Gerar Imagem
                        </Button>
                        <Button color="primary" variant="contained" onClick={generateCsvFile}>
                            <DescriptionTwoToneIcon />
                            &nbsp; Gerar CSV
                        </Button>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>

        <div style={{ padding: 30 }}>
            <Grid container direction="row" alignItems={"start"} justifyContent="flex-start" spacing={0}>
                <Grid item sm={12} md={12} xl={12} xs={12}>
                    <div>
                        {!checked ? <LineChartComponent
                            id="chartCapture"
                            title={chartTitle}
                            xTitle={props.chartOneData.xTitle}
                            yTitle={props.chartOneData.yTitle}
                            xValue={props.chartOneData.xValue}
                            values={props.chartOneData.values} /> : null
                        }
                    </div>
                </Grid>
                <Grid item sm={12} md={12} xl={12} xs={12}>
                    {checked ? <LineChartComponent
                        id="chartCaptureTwo"
                        disableXAxis={props.disableXAxis}
                        title={chartTitle}
                        xTitle={props.chartTwoData.xTitle}
                        yTitle={props.chartTwoData.yTitle}
                        xValue={props.chartTwoData.xValue}
                        values={props.chartTwoData.values} /> : null
                    }
                </Grid>
            </Grid>

            {props.chartTwoData ?
                <Grid container direction="row" alignItems={"center"} justifyContent="center" spacing={0}>
                    <Grid item>
                        Taxa &nbsp;&nbsp;
                        <FormControlLabel
                            control={<Switch
                                checked={checked}
                                onChange={handleChange} />}
                            label="Volatilidade"
                        />
                    </Grid>
                </Grid>
                : null}
        </div>
    </Dialog>
}

LineChartModalComponent.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    disableXAxis: PropTypes.bool,
    title: PropTypes.string,
    selectedDate: PropTypes.string,
    chartOneData: PropTypes.object,
    chartTwoData: PropTypes.object,
    chartOneSelectTitle: PropTypes.string,
    chartTwoSelectTitle: PropTypes.string,
    csvData: PropTypes.array
}

export default LineChartModalComponent