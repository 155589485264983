import React, { useState } from "react"
import { ClickAwayListener, Collapse, List, ListItemButton, ListItemIcon, ListItemText, Tooltip } from "@mui/material"
import theme from "src/utils/theme"
import PropTypes from 'prop-types'
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import { useLocation, useNavigate } from "react-router-dom"
import { TokenService } from "src/services/token-service"
import StorageService from "src/services/storage-service"
import { CURRENT_PAGE_STORAGE_KEY } from "src/utils/constants"

const iconButton = {
    color: theme.palette.primary.inverse
}

const MenuListItemCollapse = (props) => {
    const location = useLocation()
    const navigate = useNavigate()
    const tokenService = new TokenService()
    const storageService = new StorageService()
    const [open, setOpen] = useState(false)

    const goToPage = (subItem) => {
        storageService.saveItemLocalStorage(CURRENT_PAGE_STORAGE_KEY, subItem.id)
        navigate(subItem.route)
    }

    const verifyPermission = (menu) => {
        const userAdmin = tokenService.verifyUserIsAdmin()

        if (userAdmin && menu.admin) {
            return true
        }

        if (menu.id == null) {
            return true
        }

        return tokenService.verifyPermission(menu.id)
    }

    return (
        <div>
            {verifyPermission(props.item) ?
                <List>
                    <ClickAwayListener
                        mouseEvent="onMouseUp"
                        onClickAway={() => {
                            props.closeSideMenu()
                            setOpen(false)
                        }}>
                        <Tooltip title={props.item.title} arrow placement="right">
                            <ListItemButton
                                id={props.sideMenu}
                                onClick={() => {
                                    props.openSideMenu()
                                    setOpen(true)
                                }}>
                                <ListItemIcon sx={iconButton}>{props.item.icon}</ListItemIcon>
                                <ListItemText sx={iconButton} primary={props.item.title} />
                                {open ? <ExpandLess sx={iconButton} /> : <ExpandMore sx={iconButton} />}
                            </ListItemButton>
                        </Tooltip>

                    </ClickAwayListener>
                    <Collapse
                        in={open}
                        timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {props.item.subLinks.map((subItem, subItemIndex) => (
                                verifyPermission(subItem) ?
                                    <ListItemButton
                                        id={subItem.id}
                                        key={subItemIndex}
                                        sx={{ pl: 4 }}
                                        selected={location.pathname === subItem.route}
                                        onClick={() => {
                                            goToPage(subItem)
                                        }}>
                                        <ListItemIcon sx={iconButton}>
                                            {subItem.icon}
                                        </ListItemIcon>
                                        <ListItemText sx={iconButton} primary={subItem.title} />
                                    </ListItemButton>
                                    : null
                            ))}
                        </List>
                    </Collapse>
                </List>

                : null}
        </div>
    )
}

MenuListItemCollapse.propTypes = {
    openSideMenu: PropTypes.func,
    closeSideMenu: PropTypes.func,
    sideMenu: PropTypes.string,
    item: PropTypes.shape({
        title: PropTypes.string,
        route: PropTypes.string.isRequired,
        icon: PropTypes.element.isRequired,
        id: PropTypes.string,
        subLinks: PropTypes.array,
    })
}


export default MenuListItemCollapse