import React, { useEffect, useState } from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers"
import PropTypes from 'prop-types'


const DateTimePickerSelectDate = (props) => {
    const [date, setDate] = useState(dayjs())

    useEffect(() => {
        if (props.value) {
            setDate(dayjs(props.value))
        }
    }, [props.value])

    const changeDate = (value) => {
        setDate(value)
        props.onChange(value)
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
                label={props.label}
                value={date}
                onChange={changeDate}
                format="DD/MM/YYYY HH:mm" // formato de data pra visualização
                renderInput={(params) => <input {...params} />} // render do input
            />
        </LocalizationProvider>
    )
}

DateTimePickerSelectDate.propTypes = {
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    value: PropTypes.string
}

export default DateTimePickerSelectDate
