import React, { useEffect, useState } from "react"
import { formatDateDataGrid } from "src/utils/format-date"
import PropTypes from 'prop-types'
import DatalakeService from "src/services/datalake-service"
import dayjs from "dayjs"
import { DATE_FORMAT } from "src/utils/constants"
import ParameterPaginationTable from "src/components/table/parameter-pagination-table"


const ParameterDatalakePrecosProcessadosTable = (props) => {
    const dataLakeService = new DatalakeService()
    const [data, setData] = useState([])
    const [pagination, setPagination] = useState({ count: 0, next: null, previous: null })

    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        props.loading(true)
        dataLakeService.loadPaginationEndpoint(props.endpoint).then((response) => {
            props.loading(false)

            const { body } = response
            const { results, count, next, previous } = body
            const list = results.map((obj, index) => ({ ...obj, id: index }))

            setPagination({ count: count, next: next, previous: previous })
            setData(list)
        })
    }

    const createRow = async () => {
        const body = {
            "CETIP": "N/A",
            "DataReferencia": dayjs().format(DATE_FORMAT),
            "Indicador": "N/A",
            "TipoTaxaFINCS": "N/A",
            "IDRiskFree": 0,
            "DurationAccrual": 0,
            "PreviaInflacao": 0,
            "TaxaRiskFree": 0,
            "SpreadCurvaRating": 0,
            "SpreadCredito": 0,
            "SpreadRelativo": 0,
            "TaxaTIR": 0,
            "TaxaMTM": 0,
            "PU_MTM": 0,
            "DurationMTM": 0,
            "DurationAno": 0,
            "PUPAR": 0,
            "PercentualPUPAR": 0,
            "LOGERRO": 0
        }

        props.loading(true)
        return dataLakeService.createTableEndpoint(props.endpoint, body).then(() => {
            props.loading(false)
            loadData(0, 25)
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const updateRow = async (newRow) => {
        const { ID, CETIP, DataReferencia, Indicador, TipoTaxaFINCS, IDRiskFree, DurationAccrual, PreviaInflacao,
            TaxaRiskFree, SpreadCurvaRating, SpreadCredito, SpreadRelativo, TaxaTIR, TaxaMTM, PU_MTM, DurationMTM, DurationAno,
            PUPAR, PercentualPUPAR, LOGERRO
        } = newRow

        const body = {
            "CETIP": CETIP,
            "DataReferencia": DataReferencia,
            "Indicador": Indicador,
            "TipoTaxaFINCS": TipoTaxaFINCS,
            "IDRiskFree": IDRiskFree,
            "DurationAccrual": DurationAccrual,
            "PreviaInflacao": PreviaInflacao,
            "TaxaRiskFree": TaxaRiskFree,
            "SpreadCurvaRating": SpreadCurvaRating,
            "SpreadCredito": SpreadCredito,
            "SpreadRelativo": SpreadRelativo,
            "TaxaTIR": TaxaTIR,
            "TaxaMTM": TaxaMTM,
            "PU_MTM": PU_MTM,
            "DurationMTM": DurationMTM,
            "DurationAno": DurationAno,
            "PUPAR": PUPAR,
            "PercentualPUPAR": PercentualPUPAR,
            "LOGERRO": LOGERRO
        }

        props.loading(true)
        return dataLakeService.updateTableEndpoint(props.endpoint, ID, body).then(() => {
            props.loading(false)
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const deleteRow = async (row) => {
        props.loading(true)
        const { ID } = row
        return dataLakeService.deleteTableEndpoint(props.endpoint, ID).then(() => {
            props.loading(false)
            const listRemoved = data.filter((item) => item.ID !== ID)
            setData(listRemoved)
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const onFilter = async (filterList, limit, offset) => {
        const query = filterList.map(obj => `${encodeURIComponent(obj.field)}=${encodeURIComponent(obj.value)}`).join('&')

        props.loading(true)
        dataLakeService.loadPaginationEndpoint(props.endpoint, query, limit, offset).then((response) => {
            props.loading(false)
            const { body } = response
            const { results, count, next, previous } = body
            const list = results.map((obj) => ({ ...obj, id: obj.ID }))
            setPagination({ count: count, next: next, previous: previous })
            setData(list)
        })
    }

    const changePage = async (endpoint) => {
        props.loading(true)
        dataLakeService.paginate(endpoint).then((response) => {
            props.loading(false)
            const { body } = response
            const { results, count, previous, next } = body
            const list = results.map((obj) => ({ ...obj, id: obj.ID }))

            setPagination({ count: count, next: next, previous: previous })
            setData([])
            setData(list)
        })
    }

    const columns = [
        {
            field: 'ID',
            headerName: '#',
            disableColumnMenu: true,
            disableFilter: true,
            flex: true,
            minWidth: 80,
        },
        {
            field: 'CETIP',
            headerName: 'CETIP',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'DataReferencia',
            headerName: 'DataReferencia',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'date',
            // type: 'date',
            // valueGetter: ({ value }) => value && dayjs(value).toDate(),
            // valueFormatter: params => dayjs(params.value).format(DATE_FORMAT),
            // valueParser: (newValue) => dayjs(newValue).format(DATE_FORMAT),
            disableColumnMenu: true,
            editable: true,
        },
        {
            field: 'Indicador',
            headerName: 'Indicador',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'TipoTaxaFINCS',
            headerName: 'TipoTaxaFINCS',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'IDRiskFree',
            headerName: 'IDRiskFree',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'DurationAccrual',
            headerName: 'DurationAccrual',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            typeComponent: 'number',
            type: 'number',
            editable: true
        },
        {
            field: 'PreviaInflacao',
            headerName: 'PreviaInflacao',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'TaxaRiskFree',
            headerName: 'TaxaRiskFree',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'SpreadCurvaRating',
            headerName: 'SpreadCurvaRating',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'SpreadCredito',
            headerName: 'SpreadCredito',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'SpreadRelativo',
            headerName: 'SpreadRelativo',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'TaxaTIR',
            headerName: 'TaxaTIR',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'TaxaMTM',
            headerName: 'TaxaMTM',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'PU_MTM',
            headerName: 'PU_MTM',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'DurationMTM',
            headerName: 'DurationMTM',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'DurationAno',
            headerName: 'DurationAno',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'PUPAR',
            headerName: 'PUPAR',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'PercentualPUPAR',
            headerName: 'PercentualPUPAR',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'LOGERRO',
            headerName: 'LOGERRO',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'TIR_Accrual',
            headerName: 'TIR_Accrual',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'CriadoEm',
            headerName: 'Data de Criação',
            flex: true,
            minWidth: 150,
            disableColumnMenu: true,
            disableFilter: true,
            renderCell: (params) => formatDateDataGrid(params)
        },
        {
            field: 'AtualizadoEm',
            headerName: 'Data de Atualização',
            flex: true,
            minWidth: 150,
            disableColumnMenu: true,
            disableFilter: true,
            renderCell: (params) => formatDateDataGrid(params)
        }
    ]

    return (
        <div>
            <ParameterPaginationTable
                columns={columns}
                data={data}
                setData={setData}
                createRow={createRow}
                deleteRow={deleteRow}
                updateRow={updateRow}
                pagination={pagination}
                onChangePage={changePage}
                filterFunction={onFilter}
                customFilter={true} />
        </div>
    )
}

ParameterDatalakePrecosProcessadosTable.propTypes = {
    loading: PropTypes.func,
    endpoint: PropTypes.string,
}

export default ParameterDatalakePrecosProcessadosTable
