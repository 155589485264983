import { CALC_FAIXA_DURATION_PATH, CALC_GROUP_ACTIVE_PATH, CALC_GROUP_INDICATOR_PATH, CALC_ID_CURVAS_PATH, CALC_IR_PATH, CALC_MULTIPLICADOR_LIQUIDEZ_PATH, CALC_REGRAS_OUTLIERS_PATH, CALC_SCORE_RATING_PATH, CALC_SETORIAL_PATH, CALC_REGRAS_VOLATILIDADE_PATH, CALC_ALIAS_INDICADORES_PATH, CALC_CRITERIOS_LIQUIDEZ_PATH, CALC_CURVAS_NSS_INICIAL_PATH } from "src/utils/api-routes"


export const parameterCalcTableList = [
    { value: "Calc_AliquotaIR", description: "Calc_AliquotaIR" },
    { value: "Calc_FaixaDuration", description: "Calc_FaixaDuration" },
    { value: "Calc_GrupoAtivo", description: "Calc_GrupoAtivo" },
    { value: "Calc_GrupoIndicador", description: "Calc_GrupoIndicador" },
    { value: "Calc_IDCurvas", description: "Calc_IDCurvas" },
    { value: "Calc_MultiplicadorLiquidez", description: "Calc_MultiplicadorLiquidez" },
    { value: "Calc_RegrasDurasOutliers", description: "Calc_RegrasDurasOutliers" },
    { value: "Calc_ScoreRating", description: "Calc_ScoreRating" },
    { value: "Calc_Setorial", description: "Calc_Setorial" },
    { value: "Calc_RegrasDurasVolatilidade", description: "Calc_RegrasDurasVolatilidade" },
    { value: "Calc_AliasIndicadores", description: "Calc_AliasIndicadores" },
    { value: "Calc_CriteriosLiquidez", description: "Calc_CriteriosLiquidez" },
    { value: "Calc_CurvasNSSInicial", description: "Calc_CurvasNSSInicial" }
]

export const parameterCalcEndpoint = [
    { value: "Calc_AliquotaIR", endpoint: CALC_IR_PATH },
    { value: "Calc_FaixaDuration", endpoint: CALC_FAIXA_DURATION_PATH },
    { value: "Calc_GrupoAtivo", endpoint: CALC_GROUP_ACTIVE_PATH },
    { value: "Calc_GrupoIndicador", endpoint: CALC_GROUP_INDICATOR_PATH },
    { value: "Calc_IDCurvas", endpoint: CALC_ID_CURVAS_PATH },
    { value: "Calc_MultiplicadorLiquidez", endpoint: CALC_MULTIPLICADOR_LIQUIDEZ_PATH },
    { value: "Calc_RegrasDurasOutliers", endpoint: CALC_REGRAS_OUTLIERS_PATH },
    { value: "Calc_ScoreRating", endpoint: CALC_SCORE_RATING_PATH },
    { value: "Calc_Setorial", endpoint: CALC_SETORIAL_PATH },
    { value: "Calc_RegrasDurasVolatilidade", endpoint: CALC_REGRAS_VOLATILIDADE_PATH },
    { value: "Calc_AliasIndicadores", endpoint: CALC_ALIAS_INDICADORES_PATH },
    { value: "Calc_CriteriosLiquidez", endpoint: CALC_CRITERIOS_LIQUIDEZ_PATH },
    { value: "Calc_CurvasNSSInicial", endpoint: CALC_CURVAS_NSS_INICIAL_PATH }
   
]