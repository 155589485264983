import { Button, Grid, TextField } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import ModalDialogPage from "src/components/modal-dialog"
import TableGray from "src/components/table/table-gray"
import EmissaoService from "src/services/emissao-service"
import { DATE_FORMAT_SHOW } from "src/utils/constants"
import PropTypes from 'prop-types'
import SkeletonLine from "src/components/skeleton-line"
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'

const tableExample = [
    {
        cells: ["CETIP: ", "ISIN: "]
    }
]

const UltimoEventoCreditoEmissao = ({ cetipIsin, loading }) => {
    const ultimoCreditoEmissaoDefault = { data_referencia: "", evento_credito: "", score_evento: "" }
    const [openMostrarTodos, setOpenMostrarTodos] = useState(false)
    const emissaoService = new EmissaoService()
    const [ultimoCreditoEmissao, setUltimoCreditoEmissao] = useState(ultimoCreditoEmissaoDefault)
    const [loadingEventoCreditoEmissao, setLoadingEventoCreditoEmissao] = useState(false)
    const [rowsEventoCreditoEmissao, setRowsEventoCreditoEmissao] = useState([])

    useEffect(() => {
        console.log('UltimoEventoCreditoEmissao: ', cetipIsin)
        if (cetipIsin && cetipIsin.length > 4) loadData(cetipIsin)
    }, [cetipIsin])

    const loadData = (cetipIsin) => {
        console.log('UltimoEventoCreditoEmissao: ', cetipIsin)
        setLoadingEventoCreditoEmissao(true)
        emissaoService.getUltimoEventoCreditoEmissao(cetipIsin).then((response) => {
            const { body } = response
            const { data } = body

            if (data !== null) {
                const { DataReferencia, EventoCreditoEmissao, ScoreEvento } = data

                setUltimoCreditoEmissao({
                    data_referencia: dayjs(DataReferencia).format(DATE_FORMAT_SHOW),
                    evento_credito: EventoCreditoEmissao,
                    score_evento: ScoreEvento,
                })
            } else {
                setUltimoCreditoEmissao(ultimoCreditoEmissaoDefault)
            }
        }).catch(() => {
            setUltimoCreditoEmissao(ultimoCreditoEmissaoDefault)
        }).finally(() => {
            setLoadingEventoCreditoEmissao(false)
        })
    }

    const openEventoCreditoEmissaoModal = () => {
        loading(true)
        emissaoService.eventoCreditoEmissao("get", `?CETIP=${cetipIsin.toUpperCase()}`)
            .then((response) => {
                setOpenMostrarTodos(true)
                const { body } = response
                const { results } = body
                const rows = results.map((obj, index) => ({ ...obj, id: index }))
                setRowsEventoCreditoEmissao(rows)
            })
            .catch(() => { })
            .finally(() => {
                loading(false)
            })
    }

    const deleteItem = (params) => {
        const { row } = params
        const { ID } = row

        loading(true)
        emissaoService.eventoCreditoEmissao("delete", ID)
            .then(() => { openEventoCreditoEmissaoModal() })
            .finally(() => { 
                loading(false)
            })
    }

    const addRow = () => {
        if (rowsEventoCreditoEmissao.length === 0) {
            return
        }
        const lastItem = rowsEventoCreditoEmissao[rowsEventoCreditoEmissao.length - 1]

        loading(true)
        emissaoService.eventoCreditoEmissao("post", lastItem)
            .then(() => {
                openEventoCreditoEmissaoModal()
            })
            .catch(() => { })
            .finally(() => {
                loading(false)
            })
    }

    return <>
        {loadingEventoCreditoEmissao ? <SkeletonLine /> :
            <Grid container direction="row" alignItems={"center"} justifyContent="space-between" spacing={2}>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <TextField fullWidth label="Data Referência" value={ultimoCreditoEmissao.data_referencia} />
                </Grid>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <TextField fullWidth label="Evento de Crédito" value={ultimoCreditoEmissao.evento_credito} />
                </Grid>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <TextField fullWidth label="Score Evento" value={ultimoCreditoEmissao.score_evento} />
                </Grid>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <Button fullWidth variant="contained" onClick={openEventoCreditoEmissaoModal}>Mostrar Todos</Button>
                </Grid>
            </Grid>
        }

        <ModalDialogPage open={openMostrarTodos}
            title="Último Evento de Crédito Emissão"
            close={() => {
                setOpenMostrarTodos(false)
            }}>
            <>
                <Grid container direction="column" spacing={2}>
                    <Grid item xs={12} md={12} xl={12}>
                        <TableGray data={tableExample} />
                    </Grid>
                </Grid>
                <br />
                <Grid container direction="row">
                    <Grid item xs={3} md={3} xl={3}>
                        <Button fullWidth variant="contained" onClick={addRow}>+ Adicionar Rating</Button>
                    </Grid>
                </Grid>
                <br />
                <Grid container direction="column">
                    <Grid item xs={12} md={12} xl={12}>
                        <DataGrid
                            autoHeight
                            columns={[
                                { field: 'DataReferencia', headerName: 'Data Referencia', flex: 1 },
                                { field: 'EventoCreditoEmissao', headerName: 'Evento', flex: 1 },
                                { field: 'ScoreEvento', headerName: 'Score Evento', flex: 1 },
                                {
                                    field: "",
                                    headerName: "Remover",
                                    minWidth: 150,
                                    renderCell: (params) => <Button color="error" variant="outlined" onClick={() => deleteItem(params)}>
                                        <DeleteRoundedIcon />
                                    </Button>
                                }
                            ]}
                            rows={rowsEventoCreditoEmissao}
                        />
                    </Grid>
                </Grid>
            </>
        </ModalDialogPage>
    </>
}

UltimoEventoCreditoEmissao.propTypes = {
    cetipIsin: PropTypes.string,
    loading: PropTypes.func,
}

export default UltimoEventoCreditoEmissao