import { ROUTINE_CURVA_PATH, ROUTINE_LIQUIDEZ_PATH, ROUTINE_PRECOS_PATH, ROUTINE_SPREAD_NEGOCIADO_PATH, ROUTINE_SPREAD_RELATIVO_PATH, ROUTINE_VOLATILIDADE_PATH, ROUTINE_PRECOS_TUNEL_PATH, ROUTINE_PRECOS_ANDADOS_PATH, ROUTINE_SPREADSEMISSAO_PATH, ROUTINE_SPREADSBRUTO_PATH, ROUTINE_RISKFREE_WORKFLOW_PATH, ROUTINE_EVENTOS_FINANCEIROS_PUPAR_PATH, ROUTINE_EVENTOS_FINANCEIROS_PUMTM_PATH, PRICES_CALCULATION_CETIP_PATH } from "src/utils/api-routes"

export const routineDataList = [
    { value: "SpreadRelativo", description: "Spread Relativo" },
    { value: "VolatilidadeCurvas", description: "Volatilidade de Curvas" },
    { value: "Curva", description: "Curva" },
    { value: "Liquidez", description: "Liquidez" },
    { value: "Precos", description: "Preços" },
    { value: "SpreadNegociado", description: "Spread Negociado" },
    { value: "PrecosTunel", description: "Túnel de Preços" },
    { value: "PrecosAndados", description: "Preços Andados" },
    { value: "SpreadEmissao", description: "Spreads Emissão" },
    { value: "SpreadBruto", description: "Spreads Brutos" },
    { value: "TratamentoRiskFree", description: "Tratamento Risk Free" },
    { value: "EventosFinanceirosPumtm", description: "Eventos Financeiros Pumtm" },  
    { value: "EventosFinanceirosPupar", description: "Eventos Financeiros Pupar" },  
    { value: "CalculationCetip", description: "Calculo Cetip" },   
]

export const routineEndpointList = [
    { value: "SpreadRelativo", endpoint: ROUTINE_SPREAD_RELATIVO_PATH },
    { value: "VolatilidadeCurvas", endpoint: ROUTINE_VOLATILIDADE_PATH },
    { value: "Curva", endpoint: ROUTINE_CURVA_PATH },
    { value: "Liquidez", endpoint: ROUTINE_LIQUIDEZ_PATH },
    { value: "Precos", endpoint: ROUTINE_PRECOS_PATH },
    { value: "SpreadNegociado", endpoint: ROUTINE_SPREAD_NEGOCIADO_PATH },
    { value: "PrecosTunel", endpoint: ROUTINE_PRECOS_TUNEL_PATH },
    { value: "PrecosAndados", endpoint: ROUTINE_PRECOS_ANDADOS_PATH },
    { value: "SpreadEmissao", endpoint: ROUTINE_SPREADSEMISSAO_PATH },
    { value: "SpreadBruto", endpoint: ROUTINE_SPREADSBRUTO_PATH },
    { value: "TratamentoRiskFree", endpoint: ROUTINE_RISKFREE_WORKFLOW_PATH },
    { value: "EventosFinanceirosPupar", endpoint: ROUTINE_EVENTOS_FINANCEIROS_PUPAR_PATH },
    { value: "EventosFinanceirosPumtm", endpoint: ROUTINE_EVENTOS_FINANCEIROS_PUMTM_PATH },
    { value: "CalculationCetip", endpoint: PRICES_CALCULATION_CETIP_PATH },
    
]