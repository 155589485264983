class StorageService {

    getObjectLocalStorage = (key) => {
        const item = localStorage.getItem(key)
        if (item !== null) {
            return JSON.parse(item)
        }

        return null
    }

    saveObjectLocalStorage = (key, item) => localStorage.setItem(key, JSON.stringify(item))

    getItemLocalStorage = (key) => localStorage.getItem(key)

    saveItemLocalStorage = (key, item) => localStorage.setItem(key, item)

    clearAllStorage = () => localStorage.clear()
}

export default StorageService