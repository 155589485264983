import * as React from 'react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import PropTypes from 'prop-types'
import { ListItemIcon } from '@mui/material'

const ITEM_HEIGHT = 48

const MoreVertButton = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <div>
            <IconButton
                aria-label="more"
                id={`vertical-menu-${props.id}`}
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
            >
                {props.options.map((option, index) => (
                    <MenuItem key={`menu-item-${index}`} id={`menu-item-${index}-${props.id}`} onClick={() => {
                        option.function(props.item)
                        handleClose()
                    }}>
                        <ListItemIcon>
                            {option.icon}
                        </ListItemIcon>
                        {option.text}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    )
}

MoreVertButton.propTypes = {
    options: PropTypes.array,
    item: PropTypes.object,
    id: PropTypes.string
}

export default MoreVertButton