export const replaceEmptyWithNull = (obj) => {
    for (const key in obj) {
        // Verifica se a propriedade realmente pertence ao objeto e não é herdada do protótipo
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            if (obj[key] === '') {
                obj[key] = null
            } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                // Se o valor é um objeto e não é nulo, chama a função recursivamente
                replaceEmptyWithNull(obj[key])
            }
        }
    }
    return obj
}