import React, { useState, useRef, useEffect } from "react"
import PropTypes from 'prop-types'
import { TextField } from "@mui/material"

const EditableInputComponent = ({ fieldValue, handleChange, editable = true, helperText }) => {
    const [isEditing, setIsEditing] = useState(fieldValue.length === 0)
    const [inputValue, setInputValue] = useState(fieldValue)
    const inputRef = useRef(null)

    useEffect(() => {
        if (isEditing && inputRef.current) {
            inputRef.current.focus()
        }
    }, [isEditing])

    const handleKeyDown = (event) => {
        if ([' ', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {
            event.stopPropagation()  // Previne que o espaço e as setas saiam da edição
        }
    }

    const handleEditKeyUp = (event) => {
        if (inputValue === '') return
        if (event.keyCode === 13) { // Enter key
            handleChange(inputValue)
            setIsEditing(false)
        }
    }

    const handleBlur = () => {
        if (inputValue === '') return
        handleChange(inputValue)
        setIsEditing(false)
    }

    const handleEditChange = (event) => {
        setInputValue(event.target.value)
    }

    return (
        isEditing && editable ? (
            <TextField
                inputProps={{
                    style: {
                        height: "10px",
                        width: "250px",
                        fontSize: "0.875rem",
                    },
                }}
                variant="standard"
                helperText={helperText}
                onKeyUp={handleEditKeyUp}
                onKeyDown={handleKeyDown}
                onBlur={handleBlur}
                onChange={handleEditChange}
                value={inputValue}
                ref={inputRef}
            />
        ) : (
            <div onClick={() => setIsEditing(true)}>{inputValue}</div>
        )
    )
}

EditableInputComponent.propTypes = {
    fieldValue: PropTypes.any.isRequired,
    handleChange: PropTypes.func.isRequired,
    editable: PropTypes.bool,
    helperText: PropTypes.string,
}

export default EditableInputComponent
