import React from "react"
import TableColumns from "src/components/table/table-columns"
import PropTypes from 'prop-types'
import { formatStringToFloatDecimalCase } from "src/utils/format-decimal"
import { DATE_FORMAT_SHOW } from "src/utils/constants"
import dayjs from "dayjs"

const PriceMemoryCalc = (props) => {

    const getDate = (value) => {
        if (props.data == null || props.data[value] == null) {
            return null
        }

        return dayjs(props.data[value]).format(DATE_FORMAT_SHOW)
    }

    const getValueFormated = (value) => {
        if (props.data == null || props.data[value] == null) {
            return null
        }

        return formatStringToFloatDecimalCase(props.data[value])
    }

    const getValue = (value, { defaultValue = null } = {}) => {
        if (props.data == null) {
            return null
        }
        
        return props.data[value] !== null ? props.data[value] : defaultValue
    }

    const getValueInflacaoAnual = (value, { defaultValue = null } = {}) => {
        if (props.data == null) {
            return null
        }

        const inflacaoAnual =  props.data[value] !== null ? props.data[value] : defaultValue
        if(inflacaoAnual != null) {
            return inflacaoAnual == 1 ? "Sim" : "Não"
        }

        return null
    }



    const columns = [
        { keyA: "Tipo Juros PUPAR", valueA: getValue('TipoJurosPUPAR'), keyB: "Tipo Capitalização PUPAR", valueB: getValue('TipoCapitalizacaoPUPAR') },
        { keyA: "Tipo Juros PU MTM", valueA: getValue('TipoJurosPU_MTM'), keyB: "Tipo Capitalização PU MTM", valueB: getValue('TipoCapitalizacaoPU_MTM') },
        { keyA: "Início Rentabilidade", valueA: getDate('InicioRentabilidade'), keyB: "Inflação Anual", valueB: getValueInflacaoAnual('InflacaoAnual', { defaultValue: 'Não se aplica' }) },
        { keyA: "Fim Rentabilidade", valueA: getDate('FimRentabilidade'), keyB: "Mês Aniversário", valueB: getValue('MesAniversario') },
        { keyA: "Valor Nominal", valueA: getValueFormated('ValorNominal'), keyB: "Projeção Inflação", valueB: getValue('ProjecaoInflacao') },
        { keyA: "Defasagem", valueA: getValue('Defasagem'), keyB: "Inflação Negativa", valueB: getValue('InflacaoNegativa', { defaultValue: 'Não se aplica' }) },
    ]


    return (
        <>
            <TableColumns columns={columns} title={"Parâmetros para Cálculo de Preços"} />
        </>
    )
}

PriceMemoryCalc.propTypes = {
    data: PropTypes.object
}


export default PriceMemoryCalc