import { DELETE_DATALAKE_PATH} from "src/utils/api-routes"
import { ServiceRequest } from "src/utils/service-request"


class DeleteDataService {
    constructor() {
        this.serviceRequest = new ServiceRequest()
    }

    getAPPList() {
        return [
            { value: 'cadastro', description: "Cadastro" },
            { value: 'calculo', description: "CreditCalc" },
            { value: 'datalake', description: "DataLake" },
            { value: 'rater', description: "Rater" },
            { value: 'recebiveis', description: "GR" },
        ]
    }

    async sendDataLakeFile(selectedFile, importType, app, truncate) {
        const formData = new FormData()

        formData.append('arquivo', selectedFile)
        formData.append('modelo', importType)
        formData.append('app', app)
        formData.append('truncate', truncate)

        return this.serviceRequest.postFormData(DELETE_DATALAKE_PATH, formData)
    }

   /*  async sendReceivableFile(formData) {
        return this.serviceRequest.postFormData(IMPORT_RECEIVABLE_PATH, formData)
    } */

}

export default DeleteDataService