import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import PropTypes from 'prop-types'
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Divider, Grid } from '@mui/material'
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo'
import dayjs from 'dayjs'

const DateRangeModal = (props) => {

  const [firstDate, setFirstDate] = useState(dayjs(new Date()))
  const [secondDate, setSecondDate] = useState(dayjs(new Date()))

  useEffect(() => {
    if (props.value) {
      const newValue = dayjs(props.value)
      setFirstDate(newValue)
      setSecondDate(newValue)
    }
  }, [props.value])

  const changeFirstDate = (value) => {
    setFirstDate(value)
  }

  const changeSecondDate = (value) => {
    setSecondDate(value)
  }

  const returnValue = () => {
    if (props.callback) {
      props.callback(firstDate, secondDate)
    }
  }

  return <div>
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth={"md"}
    >
      <DialogTitle id="responsive-dialog-title">
        {"Selecione um intervalo"}
      </DialogTitle>
      <DialogContent>
        <Grid container
          direction={"row"}
          alignItems={"center"}
          spacing={2}>
          <Grid item>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DateCalendar']}>
                <DemoItem label="Data Ínicial">
                  <DateCalendar
                    value={firstDate}
                    onChange={changeFirstDate} />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DateCalendar']}>
                <DemoItem label="Data Final">
                  <DateCalendar
                    value={secondDate}
                    minDate={firstDate}
                    onChange={changeSecondDate} />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={props.onClose}>
          Voltar
        </Button>
        <Button autoFocus variant='contained' onClick={returnValue}>
          Aplicar
        </Button>
      </DialogActions>
    </Dialog>
  </div>

}

DateRangeModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  value: PropTypes.string,
  callback: PropTypes.func
}


export default DateRangeModal
