import React from "react"
import TableColumns from "src/components/table/table-columns"
import PropTypes from 'prop-types'
import dayjs from "dayjs"
import { DATE_FORMAT_SHOW } from "src/utils/constants"

const PriceCharacteristicsGuarantee = (props) => {
    const getDate = (value) => {
        if (props.data == null || props.data[value] == null) {
            return null
        }

        return dayjs(props.data[value]).format(DATE_FORMAT_SHOW)
    }

    const getValue = (value) => {
        if (props.data == null) {
            return null
        }

        return props.data[value]
    }

    const columns = [
        { keyA: "Data Documento", valueA: getDate('DataDocumento'), fullLine: true },
        { keyA: "Tipo Lastro", valueA: getValue('TipoLastro'), fullLine: true },
        { keyA: "Repactuação Crédito", valueA: getValue('RepactuacaoCredito'), fullLine: true },
        { keyA: "Coobrigação", valueA: getValue('Coobrigacao'), fullLine: true },
        { keyA: "Conta Vinculada", valueA: getValue('ContaVinculada'), fullLine: true },
        { keyA: "Alienação Fiduciária", valueA: getValue('AlienacaoFiduciaria'), fullLine: true },
        { keyA: "Cessao Fiduciária", valueA: getValue('CessaoFiduciaria'), fullLine: true },
        { keyA: "Fiança Bancária", valueA: getValue('FiancaBancaria'), fullLine: true },
        { keyA: "Hipoteca", valueA: getValue('Hipoteca'), fullLine: true },
        { keyA: "Penhor", valueA: getValue('Penhor'), fullLine: true },
        { keyA: "Avalista", valueA: getValue('Avalista'), fullLine: true },
        { keyA: "Fiança", valueA: getValue('Fianca'), fullLine: true },
        { keyA: "Fundo Obra", valueA: getValue('FundoObra'), fullLine: true },
        { keyA: "Fundo Reserva", valueA: getValue('FundoReserva'), fullLine: true },
        { keyA: "Fundo Liquidez", valueA: getValue('FundoLiquidez'), fullLine: true },
        { keyA: "Seguro", valueA: getValue('Seguro'), fullLine: true },
        { keyA: "Sobre Garantia", valueA: getValue('Sobregarantia'), fullLine: true },

        { keyA: "Covenants", valueA: getValue('Covenants'), fullLine: true },
    ]

    return (
        <>
            <TableColumns columns={columns} title={`Garantias`} selectValueTitle={props.selectTitleList} onChangeSelect={props.onChangeTitle} changeValue={props.titleValue} />
        </>
    )
}

PriceCharacteristicsGuarantee.propTypes = {
    data: PropTypes.object,
    selectTitleList: PropTypes.array,
    onChangeTitle: PropTypes.func,
    titleValue: PropTypes.number
}


export default PriceCharacteristicsGuarantee