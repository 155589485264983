import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import CalcService from "src/services/calc-service"

import ParameterTable from "src/components/table/parameter-table"
import dayjs from "dayjs"
import { DATE_TIME_FORMAT_SHOW } from "src/utils/constants"
import { formatDateUtc } from "src/utils/date-format"

const ParameterIDCurvasTable = (props) => {
    const calcService = new CalcService()
    const [data, setData] = useState([])

    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        props.loading(true)
        calcService.loadEndpoint(props.endpoint).then((response) => {
            props.loading(false)
            const { body } = response
            const { results } = body
            const list = results.map((obj, index) => ({ ...obj, id: index }))

            setData(list)
        })
    }

    const createRow = async () => {
        const body = {
            'IDCurva': 0,
            'NomeCurvas': "N/A",
            'Descricao': "N/A",
            'GrupoAtivos': "N/A",
            'GrupoIndicador': "N/A",
            'RiskFree': "N/A",
            'TipoTaxa': "N/A",
            'Rating': "N/A",
            'Setor': "N/A",
            'SubSetor': "N/A",
            'TipoCurva': "N/A",
            'GrupoRating': "N/A",
        }

        props.loading(true)
        return calcService.createTableEndpoint(props.endpoint, body).then(() => {
            props.loading(false)
            loadData()
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const updateRow = async (newRow) => {
        const { ID, IDCurva, NomeCurvas, Descricao, GrupoAtivos, GrupoIndicador, RiskFree, TipoTaxa, Rating, Setor, SubSetor, TipoCurva, GrupoRating } = newRow

        const body = {
            'IDCurva': IDCurva,
            'NomeCurvas': NomeCurvas,
            'Descricao': Descricao,
            'GrupoAtivos': GrupoAtivos,
            'GrupoIndicador': GrupoIndicador,
            'RiskFree': RiskFree,
            'TipoTaxa': TipoTaxa,
            'Rating': Rating,
            'Setor': Setor,
            'SubSetor': SubSetor,
            'TipoCurva': TipoCurva,
            'GrupoRating': GrupoRating,
        }

        props.loading(true)
        return calcService.updateTableEndpoint(props.endpoint, ID, body).then(() => {
            props.loading(false)
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const deleteRow = async (row) => {
        const { ID } = row
        return calcService.deleteTableEndpoint(props.endpoint, ID).then(() => {
            props.loading(false)
            loadData()
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const columns = [
        {
            field: 'ID',
            headerName: '#',
            minWidth: 100,
            flex: true,
        },
        {
            field: 'IDCurva',
            headerName: 'IDCurva',
            flex: 1,
            minWidth: 100,
            editable: true
        },
        {
            field: 'NomeCurvas',
            headerName: 'NomeCurvas',
            flex: 1,
            minWidth: 260,
            editable: true
        },
        {
            field: 'Descricao',
            headerName: 'Descricao',
            flex: 1,
            minWidth: 260,
            editable: true
        },
        {
            field: 'GrupoAtivos',
            headerName: 'GrupoAtivos',
            flex: 1,
            minWidth: 180,
            editable: true
        },
        {
            field: 'GrupoIndicador',
            headerName: 'GrupoIndicador',
            flex: 1,
            minWidth: 150,
            editable: true
        },
        {
            field: 'RiskFree',
            headerName: 'RiskFree',
            flex: 1,
            minWidth: 100,
            editable: true
        },
        {
            field: 'TipoTaxa',
            headerName: 'TipoTaxa',
            flex: 1,
            minWidth: 100,
            editable: true
        },
        {
            field: 'Rating',
            headerName: 'Rating',
            flex: 1,
            minWidth: 100,
            editable: true
        },
        {
            field: 'Setor',
            headerName: 'Setor',
            flex: 1,
            minWidth: 100,
            editable: true
        },
        {
            field: 'SubSetor',
            headerName: 'SubSetor',
            flex: 1,
            minWidth: 100,
            editable: true
        },
        {
            field: 'TipoCurva',
            headerName: 'TipoCurva',
            flex: 1,
            minWidth: 100,
            editable: true
        },
        {
            field: 'CriadoEm',
            headerName: 'Data de Criação',
            flex: true,
            minWidth: 200,
            type: 'date',
            valueGetter: ({ value }) => value && dayjs(value).toDate(),
            valueFormatter: params => formatDateUtc(params.value, DATE_TIME_FORMAT_SHOW),
        },
        {
            field: 'AtualizadoEm',
            headerName: 'Data de Atualização',
            flex: true,
            minWidth: 200,
            type: 'date',
            valueGetter: ({ value }) => value && dayjs(value).toDate(),
            valueFormatter: params => formatDateUtc(params.value, DATE_TIME_FORMAT_SHOW),
        }, 
        {
            field: 'GrupoRating',
            headerName: 'GrupoRating',
            flex: 1,
            minWidth: 100,
            editable: true
        }
    ]

    return (
        <div>
            <ParameterTable
                columns={columns}
                data={data}
                setData={setData}
                createRow={createRow}
                deleteRow={deleteRow}
                updateRow={updateRow}
                loadData={loadData} />
        </div>
    )
}

ParameterIDCurvasTable.propTypes = {
    loading: PropTypes.func,
    endpoint: PropTypes.string,
}

export default ParameterIDCurvasTable
