import { Grid, Button, Paper } from "@mui/material"
import React, { useEffect, useState, useCallback } from "react"
import {
    DataGrid,
    GridToolbarContainer,
    GridActionsCellItem,
    GridCellModes
} from "@mui/x-data-grid"
import { localeBRDataGridText } from "src/utils/data-grid-local-text"
import ReceivableService from "src/services/receivable-service"
import Loading from "src/components/loading"
import AddIcon from "@mui/icons-material/Add"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import ConfirmDelete from "../components/confirmDelete"
// import EditInputCell from "../components/editInputCell"

import PropTypes from "prop-types"

const BacenSRCParameters = (props) => {
    const { openAlert } = props
    const receivableService = new ReceivableService()
    const [isLoading, setIsloading] = useState(true)
    const [pagination, setPagination] = useState({
        totalItems: 0,
        current: 0,
        size: 0,
        next: 0,
    })
    const [deleteWarningOpen, setDeleteWarningOpen] = useState(false)
    const [selectedRow, setSelectedRow] = useState()

    const handleClose = () => {
        setDeleteWarningOpen(false)
    }

    const [rows, setRows] = useState([])
    const [rowModesModel, setRowModesModel] = useState({})
    const [cellModesModel, setCellModesModel] = useState({})

    const handleCellEditStop = (
        params,
        event
    ) => {
        const { id, field } = params

        event.defaultMuiPrevented = true
        setCellModesModel({
            ...cellModesModel,
            [id]: { ...cellModesModel[id], [field]: { mode: GridCellModes.View } },
        })
    }

    const handleDeleteClick = (id) => {
        setSelectedRow(id)
        setDeleteWarningOpen(true)
    }
    
    const deleteParameter = () => {
        receivableService.deleteBacenTaxaMediaPF(selectedRow).then(() => {
            setRows(rows.filter((row) => row.id !== selectedRow))
        })
        setDeleteWarningOpen(false)
    }
    
    const processRowUpdate = (newRow, oldRow) => {

        const stringifyRow = (row) => JSON.stringify(Object.entries(row).sort())
        const areRowsEqual = stringifyRow(newRow) === stringifyRow(oldRow)
        if(areRowsEqual) return newRow

        const requestBody = {
            DataReferencia: newRow.DataReferencia,
            TaxaMedia: newRow.TaxaMedia
        }

        receivableService.updateBacenTaxaMediaPF({ ...requestBody, id: newRow.id }).then(() => {
            setRows(rows.map((row) => (row.id === newRow.id ? newRow : row)))
        })

        return newRow
    }

    const onProcessRowUpdateError = useCallback((error) => {
        openAlert(`Não foi possível atualizar o valor. (${error})`, "error")
    }, [])

    const handleProcessRowUpdateError = useCallback((error) => {
        openAlert(`Não foi possível atualizar o valor. (${error})`, "error")
    }, [])
      
    // function renderEdit(params) {
    //     return <EditInputCell {...params} />
    // }

    // function validateLength(value) {
    //     const isEmpty = value.length === 0
    //     return isEmpty ? "O valor não pode ser nulo" : null
    // }

    // const preProcessEditDataReferenciaProps = (params) => {
    //     const errorMessage = validateLength(params.props.value.toString())
    //     return { ...params.props, error: errorMessage }
    // }

    // const preProcessEditTaxaMediaProps = (params) => {
    //     const errorMessage = validateLength(params.props.value.toString())
    //     return { ...params.props, error: errorMessage }
    // }

    const loadTable = () => {
        setIsloading(true)
        receivableService.getBacenTaxaMediaPF().then((response) => {
            const { body } = response
            const { results } = body
            const rows = results.map((obj) => ({ ...obj }))
            setPagination({
                totalItems: body.count,
                current: 0,
                next: 1,
                size: results.length,
            })
            setRows(rows)
            setIsloading(false)
        })
    }

    useEffect(() => {
        loadTable()
    }, [])

    function EditToolbar(props) {
        const { setRows, setRowModesModel } = props
      
        const handleClick = () => {
            const requestBody = {
                DataReferencia: new Date().toISOString().split('T')[0],
                TaxaMedia: "0.0"
            }

            receivableService.createBacenTaxaMediaPF(requestBody).then((response) => {
                const { body } = response
                
                setRows([body, ...rows])
                setRowModesModel((oldModel) => ({
                    ...oldModel,
                    [body.id]: { fieldToFocus: 'TaxaMedia' },
                }))
                openAlert("Linha adicionada com sucesso.", "success")
            }).catch(error => {
                openAlert(`Não foi possível criar a nova entrada. (${error})`, "error")
            })

        }
        
        return (
            <GridToolbarContainer>
                <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                    Add record
                </Button>
            </GridToolbarContainer>
        )
    }

    EditToolbar.propTypes = {
        setRows: PropTypes.func,
        setRowModesModel: PropTypes.func
    }

    const columns = [
        {
            field: 'ID',
            headerName: 'ID',
            align: "center", headerAlign: "center",
            disableColumnMenu: true,
            sortable: false,
            flex: 1
        },
        {
            field: 'CriadoEm',
            headerName: 'Criado Em',
            align: "center", headerAlign: "center",
            disableColumnMenu: true,
            sortable: false,
            flex: 1,
            renderCell: (params) => <span>{new Date(params.row.CriadoEm).toLocaleString('pt-BR')}</span>
        },
        // {
        //     field: 'DataReferencia',
        //     headerName: 'Data Referência',
        //     align: "center", headerAlign: "center",
        //     disableColumnMenu: true,
        //     sortable: false,
        //     flex: 1,
        //     editable: true,
        //     preProcessEditCellProps: preProcessEditDataReferenciaProps,
        //     renderEditCell: renderEdit
        // },
        // {
        //     field: 'TaxaMedia',
        //     headerName: 'Taxa Média',
        //     align: "center", headerAlign: "center",
        //     disableColumnMenu: true,
        //     sortable: false,
        //     flex: 1,
        //     editable: true,
        //     preProcessEditCellProps: preProcessEditTaxaMediaProps,
        //     renderEditCell: renderEdit
        // },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Excluir',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={() => handleDeleteClick(id)}
                        color="inherit"
                        key="2"
                    />,
                ]
            },
        }
    ]
    
    const { value, index } = props
    return (
        <div
            role="GRTabPanel"
            hidden={value !== index}
            id={`gr-tab-panel-${index}`}
            aria-labelledby={`tab-${index}`}
        >
            <Loading show={isLoading} />
                <Grid container direction="row" justifyContent="center" spacing={2}>
                    <Grid item xl={12} md={12} xs={12}>
                        <Paper>  
                            <DataGrid
                                rows={rows}
                                columns={columns}


                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 25,
                                        },
                                    },
                                }}

                                autoHeight
                                getRowHeight={() => 'auto'}
                                page={pagination}
                                disableRowSelectionOnClick
                                sortingOrder={['asc', 'desc']}
                                localeText={localeBRDataGridText}

                                cellModesModel={cellModesModel}   

                                onCellEditStop={handleCellEditStop}
                                onCellModesModelChange={(model) => setCellModesModel(model)}

                                rowModesModel={rowModesModel}
                                processRowUpdate={processRowUpdate}
                                onProcessRowUpdateError={onProcessRowUpdateError}
                                handleProcessRowUpdateError={handleProcessRowUpdateError}

                                slots={{
                                    toolbar: EditToolbar
                                }}
                                slotProps={{
                                    toolbar: { setRows, setRowModesModel }
                                }}

                            />
                        </Paper>
                    </Grid>
                </Grid>
                <ConfirmDelete open={deleteWarningOpen} cancel={handleClose} confirm={deleteParameter} />
        </div>
    )
}

export default BacenSRCParameters

BacenSRCParameters.propTypes = {
    value: PropTypes.number,
    index: PropTypes.number,
    openAlert: PropTypes.func
}
