import { Button, Grid, Paper, TextField } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import React, { useEffect, useState } from "react"
import ModalDialogPage from "src/components/modal-dialog"
import TableGray from "src/components/table/table-gray"
import EmissaoService from "src/services/emissao-service"
import PropTypes from 'prop-types'
import dayjs from "dayjs"
import { DATE_FORMAT_SHOW } from "src/utils/constants"
import SkeletonLine from "src/components/skeleton-line"
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'

const tableExample = [
    {
        cells: ["CETIP: ", "ISIN: ", "Vencimento: ", "Data Resgate Antecipado: "]
    },
    {
        cells: ["CPFCNPJ: ", "Devedor: ", "Setor: ", "Subsetor:"]
    }
]

const UltimoRatingEmissao = ({ cetipIsin, loading }) => {
    const ultimoRatingEmissaoDefault = { data_validade: "", fonte: "", rating: "" }
    const [openMostrarTodos, setOpenMostrarTodos] = useState(false)
    const emissaoService = new EmissaoService()
    const [ultimoRatingEmissao, setUltimoRatingEmissao] = useState(ultimoRatingEmissaoDefault)
    const [loadingRatingEmissao, setLoadingRatingEmissao] = useState(false)
    const [rowsEmissao, setRowsEmissao] = useState([])

    useEffect(() => {
        console.log('UltimoRatingEmissao: ', cetipIsin)
        if (cetipIsin && cetipIsin.length > 4) loadData(cetipIsin)
    }, [cetipIsin])

    const loadData = (cetipIsin) => {
        setLoadingRatingEmissao(true)
        emissaoService.getUltimoRatingEmissao(cetipIsin).then((response) => {
            const { body } = response
            const { data } = body

            if (data !== null) {
                const { DataValidade, fonte, Rating } = data

                setUltimoRatingEmissao({
                    data_validade: dayjs(DataValidade).format(DATE_FORMAT_SHOW),
                    fonte: fonte,
                    rating: Rating,
                })
            } else {
                setUltimoRatingEmissao(ultimoRatingEmissaoDefault)
            }
        }).catch(() => {
            setUltimoRatingEmissao(ultimoRatingEmissaoDefault)
        }).finally(() => {
            setLoadingRatingEmissao(false)
        })
    }

    const openRatingEmissaoModal = () => {
        loading(true)
        emissaoService.ratingEmissao("get", `?CETIP=${cetipIsin.toUpperCase()}`)
            .then((response) => {
                setOpenMostrarTodos(true)
                const { body } = response
                const { results } = body
                const rows = results.map((obj, index) => ({ ...obj, id: index }))
                setRowsEmissao(rows)
            })
            .catch(() => { })
            .finally(() => {
                loading(false)
            })
    }

    const deleteItem = (params) => {
        const { row } = params
        const { ID } = row

        loading(true)
        emissaoService.ratingEmissao("delete", ID)
            .then(() => { openRatingEmissaoModal() })
            .finally(() => { 
                loading(false)
            })
    }

    const addRow = () => {
        if (rowsEmissao.length === 0) {
            return
        }
        const lastItem = rowsEmissao[rowsEmissao.length - 1]

        loading(true)
        emissaoService.ratingEmissao("post", lastItem)
            .then(() => {
                openRatingEmissaoModal()
            })
            .catch(() => { })
            .finally(() => {
                loading(false)
            })
    }

    return <>
        {loadingRatingEmissao ? <SkeletonLine /> :
            <Grid container direction="row" alignItems={"center"} justifyContent="space-between" spacing={2}>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <TextField fullWidth label="Data Validade" value={ultimoRatingEmissao.data_validade} />
                </Grid>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <TextField fullWidth label="Fonte" value={ultimoRatingEmissao.fonte} />
                </Grid>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <TextField fullWidth label="Rating" value={ultimoRatingEmissao.rating} />
                </Grid>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <Button fullWidth variant="contained" onClick={openRatingEmissaoModal}>Mostrar Todos</Button>
                </Grid>
            </Grid>
        }
        <ModalDialogPage open={openMostrarTodos}
            title="Último Rating Emissão"
            close={() => {
                setOpenMostrarTodos(false)
            }}>
            <>
                {/* <Loading show={loadingModal} /> */}
                <Grid container direction="column" spacing={2}>
                    <Grid item xs={12} md={12} xl={12}>
                        <TableGray data={tableExample} />
                    </Grid>
                </Grid>
                <br />
                <Grid container direction="row">
                    <Grid item xs={3} md={3} xl={3}>
                        <Button fullWidth variant="contained" onClick={addRow}>+ Adicionar Rating</Button>
                    </Grid>
                </Grid>
                <br />
                <Paper>
                    <DataGrid
                        autoHeight
                        columns={[
                            { field: 'DataReferencia', headerName: 'Data Referencia', minWidth: 130 },
                            { field: 'DataValidade', headerName: 'Data Validade', minWidth: 130 },
                            { field: 'Setor', headerName: 'Setor', minWidth: 250 },
                            { field: 'SubSetor', headerName: 'Subsetor', minWidth: 230 },
                            { field: 'Fonte', headerName: 'Fonte', minWidth: 100 },
                            { field: 'ScoreRating', headerName: 'Score Rating', minWidth: 100 },
                            { field: 'Rating', headerName: 'Rating', minWidth: 100 },
                            { field: 'Sinal', headerName: 'Sinal', minWidth: 100 },
                            { field: 'Perspectiva', headerName: 'Perspectiva', minWidth: 100 },
                            { field: 'Observação', headerName: 'Observação', minWidth: 100 },
                            { field: 'AnaliseCreditoEmissao', headerName: 'Análise Crédito Emissão', flex: true, minWidth: 250 },
                            {
                                field: "",
                                headerName: "Remover",
                                minWidth: 150,
                                renderCell: (params) => <Button color="error" variant="outlined" onClick={() => deleteItem(params)}>
                                    <DeleteRoundedIcon />
                                </Button>
                            }
                        ]}
                        rows={rowsEmissao}
                    />
                </Paper>
            </>
        </ModalDialogPage>
    </>
}

UltimoRatingEmissao.propTypes = {
    cetipIsin: PropTypes.string,
    loading: PropTypes.func,
}

export default UltimoRatingEmissao