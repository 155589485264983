import { Button, Grid, TextField } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import ModalDialogPage from "src/components/modal-dialog"
import TableGray from "src/components/table/table-gray"
import EmissaoService from "src/services/emissao-service"
import { DATE_FORMAT_SHOW } from "src/utils/constants"
import PropTypes from 'prop-types'
import SkeletonLine from "src/components/skeleton-line"

const tableExample = [
    {
        cells: ["Razão Social: ", "CNPJ: ", "Grupo Econômico: "]
    }
]

const UltimoEventoCreditoDevedor = ({ cpfCnpj }) => {
    const ultimoEventoCreditoDevedorDefault = { data_referencia: "", evento_credito_devedor: "", score_evento: "" }
    const [openMostrarTodos, setOpenMostrarTodos] = useState(false)
    const emissaoService = new EmissaoService()
    const [ultimoEventoCreditoDevedor, setUltimoEventoCreditoDevedor] = useState(ultimoEventoCreditoDevedorDefault)
    const [loadingCreditoDevedor, setLoadingCreditoDevedor] = useState(false)

    useEffect(() => {
        console.log("UltimoEventoCreditoDevedor: ", cpfCnpj)
        if (cpfCnpj) loadData(cpfCnpj)
    }, [cpfCnpj])

    const loadData = (cpfCnpj) => {
        setLoadingCreditoDevedor(true)
        emissaoService.getUltimoEventoCreditoDevedor(cpfCnpj).then((response) => {
            const { body } = response
            const { data } = body

            if (data !== null) {
                const { DataReferencia, EventoCreditoDevedor, ScoreEvento } = data

                setUltimoEventoCreditoDevedor({
                    data_referencia: dayjs(DataReferencia).format(DATE_FORMAT_SHOW),
                    evento_credito_devedor: EventoCreditoDevedor,
                    score_evento: ScoreEvento,
                })
            } else {
                setUltimoEventoCreditoDevedor(ultimoEventoCreditoDevedorDefault)
            }
        }).catch(() => {
            setUltimoEventoCreditoDevedor(ultimoEventoCreditoDevedorDefault)
        }).finally(() => {
            setLoadingCreditoDevedor(false)
        })
    }

    return <>
        {loadingCreditoDevedor ? <SkeletonLine /> :
            <Grid container direction="row" alignItems={"center"} justifyContent="space-between" spacing={2}>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <TextField fullWidth label="Data Referência" value={ultimoEventoCreditoDevedor.data_referencia} />
                </Grid>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <TextField fullWidth label="Evento Crédito" value={ultimoEventoCreditoDevedor.evento_credito_devedor} />
                </Grid>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <TextField fullWidth label="Score Evento" value={ultimoEventoCreditoDevedor.score_evento} />
                </Grid>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <Button fullWidth variant="contained" onClick={() => setOpenMostrarTodos(true)}>Mostrar Todos</Button>
                </Grid>
            </Grid>}

        <ModalDialogPage open={openMostrarTodos}
            title="Último Evento de Crédito Devedor"
            close={() => {
                setOpenMostrarTodos(false)
            }}>
            <>
                <Grid container direction="column" spacing={2}>
                    <Grid item xs={12} md={12} xl={12}>
                        <TableGray data={tableExample} />
                    </Grid>
                </Grid>
                <br />
                <Grid container direction="row">
                    <Grid item xs={3} md={3} xl={3}>
                        <Button fullWidth variant="contained" onClick={() => { }}>+ Adicionar Rating</Button>
                    </Grid>
                </Grid>
                <br />
                <Grid container direction="column">
                    <Grid item xs={12} md={12} xl={12}>
                        <DataGrid
                            autoHeight
                            columns={[
                                { field: 'DataReferencia', headerName: 'Data Referencia', flex: 1 },
                                { field: 'DataValidade', headerName: 'Data Validade', flex: 1 },
                                { field: 'Evento', headerName: 'Evento', flex: 1 },
                                { field: 'Score Evento', headerName: 'Score Evento', flex: 1 },
                            ]}
                            rows={[]}
                        />
                    </Grid>
                </Grid>
            </>
        </ModalDialogPage>
    </>
}

UltimoEventoCreditoDevedor.propTypes = {
    cpfCnpj: PropTypes.string
}


export default UltimoEventoCreditoDevedor