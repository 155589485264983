import { CssBaseline, Grid, ThemeProvider } from "@mui/material"
import { Box, Container } from "@mui/system"
import React, { useEffect } from "react"
import { Copyright } from "src/components/copyright"
import theme from "src/utils/theme"
import PropTypes from 'prop-types'
import Loading from "src/components/loading"
import AlertMessage from "src/components/alert-message"

const boxWhite = {
    marginTop: "30%",
    display: 'flex',
    padding: 4,
    backgroundColor: "#fff",
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 3,
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"
}

const logo = {
    marginBottom: 2
}

const LoginLayout = (props) => {

    useEffect(() => {
        document.title = props.title
    }, [props])

    return (
        <ThemeProvider theme={theme}>
            <Loading show={props.loading} />
            {props.alert ? <AlertMessage open={props.alert.open} message={props.alert.message} type={props.alert.type} close={() => {}} /> : null}
            <CssBaseline />
            <Container component="main" maxWidth="xs">
                <Box sx={boxWhite}>
                    <Grid sx={logo}>
                        <img src="../logo.png" alt="C2P Logo" title="C2P Logo" height={80} />
                    </Grid>

                    <Box noValidate sx={{ mt: 1 }}>
                        {props.children}
                    </Box>
                </Box>
                <br />
                <Copyright />
            </Container>
        </ThemeProvider>

    )
}

LoginLayout.propTypes = {
    children: PropTypes.element,
    title: PropTypes.string,
    loading: PropTypes.bool,
    alert: PropTypes.object
}


export default LoginLayout

