import {
  BACEN_TAXA_MEDIA_PF_PATH,
  BACEN_TAXA_MEDIA_PJ_PATH,
  RAZAO_RATING_PATH,
  BV_MODELO_SCORE_PATH,
  PD_RATING_PATH,
  TIPO_RECEBIVEL_PATH,
  TIPO_GARANTIA_PATH,
  SCORE_RATING_PATH,
  SCORE_BUREAU_RATING_PATH,
  RECEIVABLE_CONCESSIONS_PATH,
  RECEIVABLE_WALLETS_PATH,
  BACEN_SCR_PATH,
  BACEN_TAX_FEE
} from "src/utils/api-routes"
import { ServiceRequest } from "src/utils/service-request"

class ReceivableService {

  constructor() {
    this.serviceRequest = new ServiceRequest()
  }

  async getConcessions(limit, offset) {
    return this.serviceRequest.get(`${RECEIVABLE_CONCESSIONS_PATH}?limit=${limit}&offset=${offset}`)
  }

  async updateConcessionName(id, filename) {
    const body = {
      NomeConcessao: filename
    }

    return this.serviceRequest.patch(`${RECEIVABLE_CONCESSIONS_PATH}/${id}`, body)
  }

  async updateWalletName(id, filename) {
    const body = {
      NomeCarteira: filename
    }

    return this.serviceRequest.patch(`${RECEIVABLE_WALLETS_PATH}/${id}`, body)
  }

  async getWallets(limit, offset) {
    return this.serviceRequest.get(`${RECEIVABLE_WALLETS_PATH}?limit=${limit}&offset=${offset}`)
  }

  async getParameters(path, limit, offset) {
    return this.serviceRequest.get(`${path}?limit=${limit}&offset=${offset}`)
  }

  async createParameter(path, parameter) {
    const body = {
      ...parameter
    }
    return this.serviceRequest.post(path, body)
  }

  async updateParameter(path, parameter) {
    const body = {
      ...parameter
    }
    return this.serviceRequest.put(`${path}/${parameter.id}`, body)
  }

  async deleteParameter(path, id) {
    return this.serviceRequest.delete(`${path}/${id}`)
  }

  // BACEN TAXA MEDIA PF

  async getBacenTaxaMediaPF(limit, offset) {
    return this.serviceRequest.get(`${BACEN_TAXA_MEDIA_PF_PATH}?limit=${limit}&offset=${offset}`)
  }

  async createBacenTaxaMediaPF(parameter) {
    const body = {
      ...parameter
    }
    return this.serviceRequest.post(BACEN_TAXA_MEDIA_PF_PATH, body)
  }

  async updateBacenTaxaMediaPF(parameter) {
    const body = {
      ...parameter
    }
    return this.serviceRequest.put(`${BACEN_TAXA_MEDIA_PF_PATH}/${parameter.id}`, body)
  }

  async deleteBacenTaxaMediaPF(id) {
    return this.serviceRequest.delete(`${BACEN_TAXA_MEDIA_PF_PATH}/${id}`)
  }

  // BACEN TAXA MEDIA PJ

  async getBacenTaxaMediaPJ(limit, offset) {
    return this.serviceRequest.get(`${BACEN_TAXA_MEDIA_PJ_PATH}?limit=${limit}&offset=${offset}`)
  }

  async createBacenTaxaMediaPJ(parameter) {
    const body = {
      ...parameter
    }
    return this.serviceRequest.post(BACEN_TAXA_MEDIA_PJ_PATH, body)
  }

  async updateBacenTaxaMediaPJ(parameter) {
    const body = {
      ...parameter
    }
    return this.serviceRequest.put(`${BACEN_TAXA_MEDIA_PJ_PATH}/${parameter.id}`, body)
  }

  async deleteBacenTaxaMediaPJ(id) {
    return this.serviceRequest.delete(`${BACEN_TAXA_MEDIA_PJ_PATH}/${id}`)
  }

  // RAZAO RATING

  async getRazaoRating(limit, offset) {
    return this.serviceRequest.get(`${RAZAO_RATING_PATH}?limit=${limit}&offset=${offset}`)
  }

  async createRazaoRating(parameter) {
    const body = {
      ...parameter
    }
    return this.serviceRequest.post(RAZAO_RATING_PATH, body)
  }

  async updateRazaoRating(parameter) {
    const body = {
      ...parameter
    }
    return this.serviceRequest.put(`${RAZAO_RATING_PATH}/${parameter.id}`, body)
  }

  async deleteRazaoRating(id) {
    return this.serviceRequest.delete(`${RAZAO_RATING_PATH}/${id}`)
  }

  // BV MODELO

  async getBVModelo(limit, offset) {
    return this.serviceRequest.get(`${BV_MODELO_SCORE_PATH}?limit=${limit}&offset=${offset}`)
  }

  async createBVModelo(parameter) {
    const body = {
      ...parameter
    }
    return this.serviceRequest.post(BV_MODELO_SCORE_PATH, body)
  }

  async updateBVModelo(parameter) {
    const body = {
      ...parameter
    }
    return this.serviceRequest.put(`${BV_MODELO_SCORE_PATH}/${parameter.id}`, body)
  }

  async deleteBVModelo(id) {
    return this.serviceRequest.delete(`${BV_MODELO_SCORE_PATH}/${id}`)
  }

  // PD Rating

  async getPDRating(limit, offset) {
    return this.serviceRequest.get(`${PD_RATING_PATH}?limit=${limit}&offset=${offset}`)
  }

  async createPDRating(parameter) {
    const body = {
      ...parameter
    }
    return this.serviceRequest.post(PD_RATING_PATH, body)
  }

  async updatePDRating(parameter) {
    const body = {
      ...parameter
    }
    return this.serviceRequest.put(`${PD_RATING_PATH}/${parameter.id}`, body)
  }

  async deletePDRating(id) {
    return this.serviceRequest.delete(`${PD_RATING_PATH}/${id}`)
  }

  // Tipo Recebivel

  async getTipoRecebivel(limit, offset) {
    return this.serviceRequest.get(`${TIPO_RECEBIVEL_PATH}?limit=${limit}&offset=${offset}`)
  }

  async createTipoRecebivel(parameter) {
    const body = {
      ...parameter
    }
    return this.serviceRequest.post(TIPO_RECEBIVEL_PATH, body)
  }

  async updateTipoRecebivel(parameter) {
    const body = {
      ...parameter
    }
    return this.serviceRequest.put(`${TIPO_RECEBIVEL_PATH}/${parameter.id}`, body)
  }

  async deleteTipoRecebivel(id) {
    return this.serviceRequest.delete(`${TIPO_RECEBIVEL_PATH}/${id}`)
  }

  // Tipo Garantia

  async getTipoGarantia(limit, offset) {
    return this.serviceRequest.get(`${TIPO_GARANTIA_PATH}?limit=${limit}&offset=${offset}`)
  }

  async createTipoGarantia(parameter) {
    const body = {
      ...parameter
    }
    return this.serviceRequest.post(TIPO_GARANTIA_PATH, body)
  }

  async updateTipoGarantia(parameter) {
    const body = {
      ...parameter
    }
    return this.serviceRequest.put(`${TIPO_GARANTIA_PATH}/${parameter.id}`, body)
  }

  async deleteTipoGarantia(id) {
    return this.serviceRequest.delete(`${TIPO_GARANTIA_PATH}/${id}`)
  }

  // Score Rating

  async getScoreRating(limit, offset) {
    return this.serviceRequest.get(`${SCORE_RATING_PATH}?limit=${limit}&offset=${offset}`)
  }

  async createScoreRating(parameter) {
    const body = {
      ...parameter
    }
    return this.serviceRequest.post(SCORE_RATING_PATH, body)
  }

  async updateScoreRating(parameter) {
    const body = {
      ...parameter
    }
    return this.serviceRequest.put(`${SCORE_RATING_PATH}/${parameter.id}`, body)
  }

  async deleteScoreRating(id) {
    return this.serviceRequest.delete(`${SCORE_RATING_PATH}/${id}`)
  }

  // Score Bureau Rating

  async getScoreBureauRating(limit, offset) {
    return this.serviceRequest.get(`${SCORE_BUREAU_RATING_PATH}?limit=${limit}&offset=${offset}`)
  }

  async createScoreBureauRating(parameter) {
    const body = {
      ...parameter
    }
    return this.serviceRequest.post(SCORE_BUREAU_RATING_PATH, body)
  }

  async updateScoreBureauRating(parameter) {
    const body = {
      ...parameter
    }
    return this.serviceRequest.put(`${SCORE_BUREAU_RATING_PATH}/${parameter.id}`, body)
  }

  async deleteScoreBureauRating(id) {
    return this.serviceRequest.delete(`${SCORE_BUREAU_RATING_PATH}/${id}`)
  }

  // Download de CSVs e PDFs

  async downloadFile(url) {
    return this.serviceRequest.getBlob(`${url}`)
  }

  async getBacenSCR() {
    return this.serviceRequest.get(`${BACEN_SCR_PATH}`)
  }

  async getLastDays(diasLimite) {
    return this.serviceRequest.get(`${BACEN_TAX_FEE}/data-referencia?dias-limite=${diasLimite}`)
  }

  async getBacenTaxFees(query) {
    return this.serviceRequest.get(`${BACEN_TAX_FEE}?${query}`)
  }

}

export default ReceivableService