import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import CalcService from "src/services/calc-service"
import ParameterTable from "src/components/table/parameter-table"
import { DATE_TIME_FORMAT_SHOW, DATE_FORMAT } from "src/utils/constants"
import dayjs from "dayjs"
import { formatDateUtc } from "src/utils/date-format"


const ParameterCurvasNssInicial = (props) => {
    const calcService = new CalcService()
    const [data, setData] = useState([])

    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        props.loading(true)
        calcService.loadEndpoint(props.endpoint).then((response) => {
            props.loading(false)
            const { body } = response
            const { results } = body
            const list = results.map((obj, index) => ({ ...obj, id: index }))

            setData(list)
        })
    }

    const createRow = async () => {
        const body = {
            'IDCurva':0,
            'DataReferencia': dayjs().format(DATE_FORMAT),
            'Beta1_Inicial': 0.00,
            'Beta2_Inicial': 0.00,
            'Beta3_Inicial': 0.00,
            'Beta4_Inicial': 0.00,
            'Lambda1_Inicial':0.00,
            'Lambda2_Inicial':0.00,
        }

        props.loading(true)
        return calcService.createTableEndpoint(props.endpoint, body).then(() => {
            props.loading(false)
            loadData()
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const updateRow = async (newRow) => {
        const { ID, IDCurva, DataReferencia, Beta1_Inicial, Beta2_Inicial, Beta3_Inicial, Beta4_Inicial, Lambda1_Inicial, Lambda2_Inicial  } = newRow

        const body = {
            'IDCurva':IDCurva,
            'DataReferencia': DataReferencia,
            'Beta1_Inicial': Beta1_Inicial,
            'Beta2_Inicial': Beta2_Inicial,
            'Beta3_Inicial': Beta3_Inicial,
            'Beta4_Inicial': Beta4_Inicial,
            'Lambda1_Inicial':Lambda1_Inicial,
            'Lambda2_Inicial':Lambda2_Inicial,
            
        }

        props.loading(true)
        return calcService.updateTableEndpoint(props.endpoint, ID, body).then(() => {
            props.loading(false)
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const deleteRow = async (row) => {
        const { ID } = row
        return calcService.deleteTableEndpoint(props.endpoint, ID).then(() => {
            props.loading(false)
            loadData()
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const columns = [
        {
            field: 'ID',
            headerName: '#',
            flex: true,
        },
        {
            field: 'IDCurva',
            headerName: '#',
            flex: true,
        },
        {
            field: 'DataReferencia',
            headerName: 'DataReferencia',
            flex: 1,
            editable: true,
            type: 'date',
            valueGetter: ({ value }) => value && dayjs(value).toDate(),
            valueFormatter: params => dayjs(params.value).format(DATE_FORMAT),
            valueParser: (newValue) => dayjs(newValue).format(DATE_FORMAT)
        },
        {
            field: 'Beta1_Inicial',
            headerName: 'Beta1_Inicial',
            flex: 1,
            minWidth: 100,
            editable: true
        },
        {
            field: 'Beta2_Inicial',
            headerName: 'Beta2_Inicial',
            flex: 1,
            minWidth: 100,
            editable: true
        },
        {
            field: 'Beta3_Inicial',
            headerName: 'Beta3_Inicial',
            flex: 1,
            minWidth: 100,
            editable: true
        },
        {
            field: 'Beta4_Inicial',
            headerName: 'Beta4_Inicial',
            flex: 1,
            minWidth: 100,
            editable: true
        },
        {
            field: 'Lambda1_Inicial',
            headerName: 'Lambda1_Inicial',
            flex: 1,
            minWidth: 100,
            editable: true
        },
        {
            field: 'Lambda2_Inicial',
            headerName: 'Lambda2_Inicial',
            flex: 1,
            minWidth: 100,
            editable: true
        },

        {
            field: 'CriadoEm',
            headerName: 'Data de Criação',
            flex: true,
            minWidth: 150,
            type: 'date',
            valueGetter: ({ value }) => value && dayjs(value).toDate(),
            valueFormatter: params => formatDateUtc(params.value, DATE_TIME_FORMAT_SHOW),
        },
        {
            field: 'AtualizadoEm',
            headerName: 'Data de Atualização',
            flex: true,
            minWidth: 150,
            type: 'date',
            valueGetter: ({ value }) => value && dayjs(value).toDate(),
            valueFormatter: params => formatDateUtc(params.value, DATE_TIME_FORMAT_SHOW),
        }
    ]

    return (
        <div>
            <ParameterTable
                columns={columns}
                data={data}
                setData={setData}
                createRow={createRow}
                deleteRow={deleteRow}
                updateRow={updateRow}
                loadData={loadData} />
        </div>
    )
}

ParameterCurvasNssInicial.propTypes = {
    loading: PropTypes.func,
    endpoint: PropTypes.string,
}

export default ParameterCurvasNssInicial
