import React, { useEffect } from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import { LocalizationProvider } from "@mui/x-date-pickers"
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { useState } from 'react'
import PropTypes from 'prop-types'

const DatePickerRangeSelect = (props) => {
    const [firstDate, setFirstDate] = useState(dayjs(new Date()))
    const [secondDate, setSecondDate] = useState(dayjs(new Date()))

    useEffect(() => {
        if (props.value) {
            const newValue = dayjs(props.value)
            setFirstDate(newValue)
            setSecondDate(newValue)
        }
    }, [props.value])

    const changeFirstDate = (value) => {
        setFirstDate(value)
        props.onChange(value, secondDate)
    }

    const changeSecondDate = (value) => {
        setSecondDate(value)
        props.onChange(firstDate, value)
    }

    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker', 'DatePicker']}>
                    <DatePicker
                        label={props.labelFirstDate ? props.labelFirstDate : "Data Inicial"}
                        value={firstDate}
                        format="DD/MM/YYYY"
                        onChange={changeFirstDate}
                    />
                    <DatePicker
                        label={props.labelSecondDate ? props.labelSecondDate : "Data Final"}
                        value={secondDate}
                        format="DD/MM/YYYY"
                        minDate={firstDate.add(1, 'day')}
                        onChange={changeSecondDate}
                    />
                </DemoContainer>
            </LocalizationProvider>
        </div>
    )
}

DatePickerRangeSelect.propTypes = {
    onChange: PropTypes.func,
    labelFirstDate: PropTypes.string,
    labelSecondDate: PropTypes.string,
    value: PropTypes.string
}

export default DatePickerRangeSelect