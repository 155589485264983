import { Skeleton, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material"
import React from "react"

const SkeletonLine = () => {
    const linhasEsqueleto = Array.from({ length: 1 }, (_, index) => index)

    return (
        <TableContainer>
            <Table>
                <TableBody>
                    {linhasEsqueleto.map((linha) => (
                        <TableRow key={linha}>
                            <TableCell>
                                <Skeleton variant="text" />
                            </TableCell>
                            <TableCell>
                                <Skeleton variant="text" />
                            </TableCell>
                            <TableCell>
                                <Skeleton variant="text" />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )

}

export default SkeletonLine