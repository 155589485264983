import { Grid, Paper } from "@mui/material"
import React, { useEffect, useState } from "react"
import LayoutPage from "src/components/page-layout/layout-page"
import ClientService from "src/services/client-service"
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import ModalDialogPage from "src/components/modal-dialog"
import FabButton from "src/components/fab-button"
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import RegisterClientPage from "./register-client"
import ConfirmDialog from "src/components/confirm-dialog"
import { localeBRDataGridText } from "src/utils/data-grid-local-text"
import { DataGrid } from "@mui/x-data-grid"
import ButtonLockUnlock from "src/components/button-lock-unlock"
import MoreVertButton from "src/components/more-vert-button"
import AlertMessage from "src/components/alert-message"

const ClientPage = () => {
    const clientService = new ClientService()
    const [openRegisterClient, setOpenRegisterClient] = useState(false)
    const [loading, setLoading] = useState(false)
    const [rowState, setRowState] = useState([])
    const [selectedClient, setSelectedClient] = useState(null)
    const [alertMessageState, setAlertMessageState] = React.useState({
        open: false,
        message: "",
        type: ""
    })
    const [dialogDeleteClient, setDialogDeleteClient] = useState({
        open: false,
        title: "",
        client: null
    })

    const actionButton = {
        icon: <AddRoundedIcon />,
        onClick: () => {
            setOpenRegisterClient(true)
        },
        color: "primary",
    }

    useEffect(() => {
        loadClients()
    }, [])

    const loadClients = () => {
        setLoading(true)

        clientService.getClients().then((response) => {
            const { body } = response
            const { results } = body
            const rows = results.map((obj, index) => ({ ...obj, id: index }))

            setRowState(rows)
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    }

    const reloadClients = () => {
        setRowState([])
        setSelectedClient(null)
        loadClients()
    }

    const openEditClient = (client) => {
        setSelectedClient(client)
        setOpenRegisterClient(true)
    }

    const openDialogDeleteClient = (client) => {
        const { Nome } = client
        setDialogDeleteClient({ client: client, title: `Deseja realmente excluir o cliente ${Nome}?`, open: true })
    }

    const options = [
        {
            icon: <EditRoundedIcon />,
            text: "Editar",
            function: openEditClient
        },
        {
            icon: <DeleteRoundedIcon color="error" />,
            text: "Excluir",
            function: openDialogDeleteClient,
        },
    ]

    const deleteClient = () => {
        const { client } = dialogDeleteClient
        const { ID, Nome } = client

        setLoading(true)
        clientService.deleteClient(ID).then(() => {
            setLoading(false)
            openAlert(`Cliente ${Nome} removido!`, "warning")
            reloadClients()
        }).catch(() => {
            openAlert(`Erro ao tentar remover o cliente ${Nome}. Tente novamente!`, "error")
            setLoading(false)
        })
    }

    const closeAlert = () => {
        setAlertMessageState({ ...alertMessageState, open: false })
    }

    const openAlert = (message, type) => {
        setAlertMessageState({
            ...alertMessageState,
            open: true,
            message: message,
            type: type
        })
    }

    const lockUnlockClient = (row) => {
        const active = !row.Bloqueado
        setLoading(true)
        clientService.lockUnlockUser(row.ID, active).then(() => {
            setLoading(false)
            openAlert(`Operação realizada com sucesso`, "success")
        }).catch(() => {
            setLoading(false)
            openAlert(`Não foi possível realizar a operação. Tente novamente!`, "error")
        })
    }

    const columns = [
        {
            field: 'ID',
            headerName: '#',
            maxWidth: 100
        },
        {
            field: 'Nome',
            headerName: 'Nome',
            flex: true,
            minWidth: 250,
        },
        {
            field: 'CPFCNPJ',
            headerName: 'CPF / CNPJ',
            flex: 1,
            minWidth: 250,
        },
        {
            field: 'Bloqueado',
            headerName: 'Ativo',
            flex: true,
            renderCell: (params) => <ButtonLockUnlock status={!params.row.Bloqueado} onClick={() => lockUnlockClient(params.row)} />,
            minWidth: 100,
        },
        {
            field: 'CriadoEm',
            headerName: 'Opções',
            flex: true,
            renderCell: (params) => <MoreVertButton options={options} item={params.row} />,
            minWidth: 100,
        }
    ]

    return (
        <LayoutPage
            loading={loading}
            title="Clientes">
            <AlertMessage open={alertMessageState.open} message={alertMessageState.message} type={alertMessageState.type} close={closeAlert} />
            <div>
                <Grid container direction="row" justifyContent="center" spacing={2}>
                    <Grid item xl={12} md={12} xs={12}>
                        <Paper>
                            <DataGrid
                                autoHeight
                                getRowHeight={() => 'auto'}
                                rows={rowState}
                                columns={columns}
                                pagination
                                pageSize={10}
                                disableRowSelectionOnClick
                                sortingOrder={['asc', 'desc']}
                                localeText={localeBRDataGridText}
                            />
                        </Paper>
                    </Grid>
                </Grid>

                <ModalDialogPage
                    open={openRegisterClient}
                    title={selectedClient == null ? "Novo Cliente" : "Editar Cliente"}
                    close={() => {
                        setOpenRegisterClient(false)
                        setSelectedClient(null)
                    }}
                    button={<FabButton action={actionButton} />}>
                    <RegisterClientPage
                        clientSelected={selectedClient}
                        onSuccess={() => {
                            setOpenRegisterClient(false)
                            reloadClients()
                            openAlert(`Operação realizada com sucesso`, "success")
                        }} />
                </ModalDialogPage>

                <ConfirmDialog
                    open={dialogDeleteClient.open}
                    title={dialogDeleteClient.title}
                    onConfirm={() => deleteClient()}
                    onClose={() => setDialogDeleteClient({ title: "", open: false, user: null })}>
                    <span>Essa ação não pode ser desfeita.</span>
                </ConfirmDialog>


            </div>

        </LayoutPage>
    )

}


export default ClientPage