import { Box, Grid } from "@mui/material"
import React, { useState } from "react"
import LayoutPage from "src/components/page-layout/layout-page"
import SelectValue from "src/components/select-value"
import { parameterCalcEndpoint, parameterCalcTableList } from "./parameter-calc-data"
import ParameterCalcIRTable from "./tables/parameter-calc-ir-table"
import ParameterFaixaDurationTable from "./tables/parameter-calc-faixa-duration-table"
import ParameterGrupoAtivoTable from "./tables/parameter-calc-grupo-ativo-table"
import ParameterGrupoIndicadorTable from "./tables/parameter-calc-grupo-indicador-table"
import ParameterSetorialTable from "./tables/parameter-calc-setorial-table"
import ParameterIDCurvasTable from "./tables/parameter-calc-id-curvas"
import ParameterMultiplicadorLiquidezTable from "./tables/parameter-calc-multiplicador-liquidez-table"
import ParameterRegrasDurasOutliersTable from "./tables/parameter-calc-regras-duras-outliers-table"
import ParameterRegrasDurasVolatilidadeTable from "./tables/parameter-calc-regras-duras-volatilidade-table"
import ParameterAliasIndicadoresTable from "./tables/parameter-calc-alias-indicadores-table"
import ParameterCriteriosLiquidezTable from "./tables/parameter-calc-criterios-liquidez-table"
import ParameterScoreRatingTable from "./tables/parameter-calc-score-rating-table"
import ParameterCurvasNssInical from "./tables/parameter-calc-curvas-inss-inical-table"


const ParameterCalcPage = () => {

    const [tableSelected, setTableSelected] = useState("")
    const [loading, setLoading] = useState(false)
    const [endpointSelected, setEndpointSelected] = useState("")
   

    const setTable = (value) => {

        setTableSelected(value)

        if (value === "") return
        const { endpoint } = parameterCalcEndpoint.filter((item) => item.value === value)[0]

        setEndpointSelected(endpoint)
    }

    return <LayoutPage title="Parâmetros" loading={loading}>
        <div>
            <Box sx={{ mx: 2, width: 'auto' }}>
                <Grid container alignItems="flex-start" justifyContent="flex-start" spacing={3}>
                    <Grid item sm={12} md={6} xl={4} xs={12}>
                        <SelectValue
                            label={"Tabela"}
                            value={tableSelected}
                            id="select-app"
                            changeValue={setTable}
                            options={parameterCalcTableList} />
                    </Grid>
                    <Grid item sm={12} md={12} xl={12} xs={12}>
                        {(() => {
                            const componentMap = {
                                [parameterCalcTableList[0].value]: <ParameterCalcIRTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterCalcTableList[1].value]: <ParameterFaixaDurationTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterCalcTableList[2].value]: <ParameterGrupoAtivoTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterCalcTableList[3].value]: <ParameterGrupoIndicadorTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterCalcTableList[4].value]: <ParameterIDCurvasTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterCalcTableList[5].value]: <ParameterMultiplicadorLiquidezTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterCalcTableList[6].value]: <ParameterRegrasDurasOutliersTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterCalcTableList[7].value]: <ParameterScoreRatingTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterCalcTableList[8].value]: <ParameterSetorialTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterCalcTableList[9].value]: <ParameterRegrasDurasVolatilidadeTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterCalcTableList[10].value]: <ParameterAliasIndicadoresTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterCalcTableList[11].value]: <ParameterCriteriosLiquidezTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterCalcTableList[12].value]: <ParameterCurvasNssInical endpoint={endpointSelected} loading={setLoading} />,
                                
                            }

                            const selectedComponent = componentMap[tableSelected]

                            return selectedComponent || null
                        })()}
                    </Grid>


                </Grid>
            </Box>


        </div>
    </LayoutPage>
}

export default ParameterCalcPage