import { Alert, AlertTitle, Button, Grid, IconButton, InputAdornment, TextField, Tooltip } from "@mui/material"
import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import LoginLayout from "./layout"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import AuthService from "src/services/auth-service"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

const RegisterPasswordPage = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const authService = new AuthService()

    const [createPasswordState, setCreatePasswordState] = React.useState({
        password: "",
        confirmPassword: ""
    })
    const [loading, setLoading] = React.useState(false)
    const [showPassword, setShowPassword] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [registerState, setRegisterState] = React.useState(false)
    const [alertMessageState, setAlertMessageState] = React.useState({
        open: false,
        message: "",
        type: ""
    })

    const togglePasswordHide = () => setShowPassword(!showPassword)
    const backLogin = () => navigate("/")

    const openAlert = (message, type) => {
        setAlertMessageState({
            open: true,
            message: message,
            type: type
        })

        setTimeout(() => {
            setAlertMessageState({ ...alertMessageState, open: false })
        }, 5000)
    }

    const submitNewPassword = async () => {
        if (createPasswordState.password === "" || createPasswordState.password !== createPasswordState.confirmPassword) {
            setError(true)
            return
        }

        const uid = params.get("uid")
        const token = params.get("token")

        setLoading(true)
        try {
            await authService.registerNewPassword(uid, token, createPasswordState.password)
            setLoading(false)
            setRegisterState(true)
        } catch (err) {
            setRegisterState(false)
            setLoading(false)
            setError(true)

            if (err.response.body != null) {
                const { new_password, token } = err.response.body
            
                if (new_password !== undefined) {
                    openAlert(new_password.join(".", " "), "error")
                }

                if (token !== undefined) {
                    openAlert(token, "error")
                }

            }
        }
    }

    const passwordInfoText = (
        <ul>
            <li>Senha precisa ter no mínimo 8 caracteres</li>
            <li>Senha não pode conter palavras simples ou sequência numérica (admin, 123456, fulano)</li>
            <li>Senha não pode conter informações do usuário</li>
        </ul>
    )

    const pageRegisterPasswordContent = (
        <Grid container alignContent="center" justifyContent="center">
            <Grid item xs={12}>
                <TextField
                    margin="normal"
                    fullWidth
                    value={createPasswordState.pass}
                    variant="standard"
                    name="password"
                    label="senha"
                    onChange={(event) => {
                        setCreatePasswordState({ ...createPasswordState, password: event.target.value })
                    }}
                    error={error}
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    autoComplete="password"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password"
                                    edge="start"
                                    onClick={togglePasswordHide}
                                >
                                    {showPassword ? (
                                        <VisibilityIcon />
                                    ) : (
                                        <VisibilityOffIcon />
                                    )}
                                </IconButton>
                                <Tooltip title={passwordInfoText} color="primary">
                                    <IconButton>
                                        <InfoOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                            </InputAdornment>
                        )
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    margin="normal"
                    fullWidth
                    value={createPasswordState.confirmPassword}
                    variant="standard"
                    name="confirmPassword"
                    error={error}
                    label="confirme a senha"
                    helperText="os campos precisam ser iguais"
                    onChange={(event) => {
                        setCreatePasswordState({ ...createPasswordState, confirmPassword: event.target.value })
                    }}
                    type={showPassword ? 'text' : 'password'}
                    id="confirmPassword"
                    autoComplete="retype-password"
                />
            </Grid>

            <Grid item xs={12}>
                <Button
                    type="submit"
                    fullWidth
                    size="large"
                    variant="contained"
                    onClick={submitNewPassword}
                    sx={{ mt: 3, mb: 2 }}
                >
                    cadastrar nova senha
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Button onClick={backLogin} fullWidth>voltar</Button>
            </Grid>
        </Grid>
    )

    const passwordRegisteredContent = (
        <Grid container alignContent="center" justifyContent="center">
            <Grid item xs={12}>
                <Alert severity="success">
                    <AlertTitle>Senha cadastrada!</AlertTitle>
                    Senha alterada com sucesso, para acessar utilize a <strong>nova senha</strong>.
                </Alert>
            </Grid>

            <Grid item xs={12}>
                <Button
                    type="submit"
                    fullWidth
                    size="large"
                    variant="contained"
                    onClick={backLogin}
                    sx={{ mt: 3, mb: 2 }}
                >
                    Acessar com a nova senha
                </Button>
            </Grid>
        </Grid>
    )

    return (
        <LoginLayout title="Recuperação de Senha" loading={loading} alert={alertMessageState}>
            {registerState ? passwordRegisteredContent : pageRegisterPasswordContent}
        </LoginLayout>

    )
}

export default RegisterPasswordPage
