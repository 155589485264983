import { GROUPS_PATH, PERMISSIONS_PATH, REPORT_ERROR_PATH, REQUEST_TRIAL_PATH } from "src/utils/api-routes"
import { CURRENT_PAGE_STORAGE_KEY } from "src/utils/constants"
import { ServiceRequest } from "src/utils/service-request"
import StorageService from "./storage-service"
import { TokenService } from "./token-service"

class AccessService {

    constructor() {
        this.request = new ServiceRequest()
        this.storage = new StorageService()
        this.tokenService = new TokenService()
    }

    async getGrupos() {
        return await this.request.get(`${GROUPS_PATH}?limit=1000&offset=0`).then((response) => {
            const { body } = response
            return body
        })
    }

    async deleteGrupo(Nome) {
        return await this.request.delete(`${GROUPS_PATH}/${Nome}`)
    }

    async getPermissoes() {
        return await this.request.get(`${PERMISSIONS_PATH}?limit=1000&offset=0`).then((response) => {
            const { body } = response
            return body
        })
    }


    async createGrupo(name, permissoes) {

        const body = {
            Nome: name,
            Permissoes: permissoes
        }

        return await this.request.post(`${GROUPS_PATH}`, body)
    }

    async updateGrupo(id, name, permissoes) {
        const body = {
            Nome: name,
            Permissoes: permissoes
        }

        return await this.request.patch(`${GROUPS_PATH}/${id}`, body)
    }

    async reportError(data) {
        const page = this.storage.getItemLocalStorage(CURRENT_PAGE_STORAGE_KEY)
        const { user } = this.tokenService.decodeToken()
        const { username } = user
        
        const body = {
            data: { ...data, page: page, user: username }
        }
        
        return this.request.post(REPORT_ERROR_PATH, body)
    }

    async requestTrial(data) {
        const page = this.storage.getItemLocalStorage(CURRENT_PAGE_STORAGE_KEY)
        const { user } = this.tokenService.decodeToken()
        const { username } = user
        
        const body = {
            data: { ...data, page: page, user: username }
        }
        
        return this.request.post(REQUEST_TRIAL_PATH, body)
    }
}

export default AccessService