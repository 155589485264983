import React, { useEffect } from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import { LocalizationProvider } from "@mui/x-date-pickers"
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { DATE_FORMAT } from 'src/utils/constants'

const DatePickerSelectDate = (props) => {
    const [date, setDate] = useState(dayjs(new Date()))

    useEffect(() => {
        if (props.value !== null) {
            const newValue = dayjs(props.value)
            setDate(newValue)
        }
    }, [props.value])

    const changeDate = (value) => {
        setDate(value)
        props.onChange(value.format(DATE_FORMAT))
    }

    const enableMyDays = (date) => {
        if (props.enableDateList.length === 0 || props.enableDateList === null) {
            return false
        }

        const dateString = date.toISOString().split('T')[0]
        return !props.enableDateList.includes(dateString)
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
                <DatePicker
                    label={props.label ? props.label : "Data"}
                    value={date}
                    format="DD/MM/YYYY"
                    shouldDisableDate={enableMyDays}
                    onChange={changeDate}
                />
            </DemoContainer>
        </LocalizationProvider>
    )
}

DatePickerSelectDate.propTypes = {
    onChange: PropTypes.func,
    label: PropTypes.string,
    value: PropTypes.string,
    enableDateList: PropTypes.array
}

export default DatePickerSelectDate