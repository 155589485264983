import { Grid, Paper } from "@mui/material"
import React, { useEffect, useState } from "react"
import LayoutPage from "src/components/page-layout/layout-page"
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import UserService from "src/services/user-service"
import ModalDialogPage from "src/components/modal-dialog"
import RegisterUserPage from "./register-user"
import FabButton from "src/components/fab-button"
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import Fingerprint from '@mui/icons-material/Fingerprint'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import MoreVertButton from "src/components/more-vert-button"
import ButtonLockUnlock from "src/components/button-lock-unlock"
import ConfirmDialog from "src/components/confirm-dialog"
import AuthService from "src/services/auth-service"
import { DataGrid } from "@mui/x-data-grid"
import { localeBRDataGridText } from "src/utils/data-grid-local-text"
import AlertMessage from "src/components/alert-message"


const UserPage = () => {
    const [openNewUser, setOpenNewUser] = useState(false)
    const userService = new UserService()
    const authService = new AuthService()
    const [loading, setLoading] = useState(false)
    const [rowState, setRowState] = useState([])
    const [selectedUser, setSelectedUser] = useState(null)
    const [dialogDeleteUser, setDialogDeleteuser] = useState({
        open: false,
        title: "",
        user: null
    })
    const [dialogResetUserPassword, setDialogResetUserPassword] = useState({
        open: false,
        title: "",
        user: null,
        username: ""
    })
    const actionButton = {
        icon: <AddRoundedIcon />,
        onClick: () => {
            setOpenNewUser(true)
        },
        color: "primary",
    }

    const [alertMessageState, setAlertMessageState] = React.useState({
        open: false,
        message: "",
        type: ""
    })

    useEffect(() => {
        loadUsers(0)
    }, [])

    const closeAlert = () => {
        setAlertMessageState({ ...alertMessageState, open: false })
    }

    const reloadUsers = () => {
        setRowState([])
        setSelectedUser(null)
        loadUsers(0)
    }

    const openAlert = (message, type) => {
        setAlertMessageState({
            ...alertMessageState,
            open: true,
            message: message,
            type: type
        })
    }

    const loadUsers = () => {
        setLoading(true)
        userService.getUsers().then((response) => {
            const { body } = response
            const { results } = body
            const rows = results.map((obj, index) => ({ ...obj, id: index }))

            setRowState(rows)
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    }

    const deleteUser = () => {
        const { user } = dialogDeleteUser
        const { ID, username } = user

        setLoading(true)
        userService.deleteUser(ID).then(() => {
            setLoading(false)
            openAlert(`Usuário ${username} removido!`, "warning")
            reloadUsers()
        }).catch(() => {
            openAlert(`Erro ao tentar remover o usuário ${username}. Tente novamente!`, "error")
            setLoading(false)
        })
    }

    const resetUserPassword = () => {
        setLoading(true)
        const { username } = dialogResetUserPassword.user
        authService.resetPassword(username)
            .then(() => {
                setLoading(false)
                openAlert(`Email enviado para ${username}.`, "success")
            }).catch(() => {
                openAlert(`Houve um erro ao enviar o email para ${username}. Tente novamente!`, "error")
                setLoading(false)
            })
    }

    const openDialogResetUserPassword = (user) => {
        const { NomeCompleto, username } = user
        setDialogResetUserPassword({ username: username, user: user, title: `Deseja enviar o email de nova senha para o usuário ${NomeCompleto}?`, open: true })
    }

    const openDialogDeleteUser = (user) => {
        const { NomeCompleto } = user
        setDialogDeleteuser({ user: user, title: `Deseja realmente excluir o usuário ${NomeCompleto}?`, open: true })
    }

    const openEdituser = (user) => {
        setSelectedUser(user)
        setOpenNewUser(true)
    }

    const options = [
        {
            icon: <Fingerprint />,
            text: "Resetar Senha",
            function: openDialogResetUserPassword
        },
        {
            icon: <EditRoundedIcon />,
            text: "Editar",
            function: openEdituser
        },
        {
            icon: <DeleteRoundedIcon color="error" />,
            function: openDialogDeleteUser,
            text: "Excluir"
        },
    ]

    const lockUnlockUser = (row) => {
        const active = !row.is_active
        setLoading(true)
        userService.blockUnblockUser(row.ID, active).then(() => {
            setLoading(false)
            openAlert(`Operação realizada com sucesso`, "success")
        }).catch(() => {
            setLoading(false)
            openAlert(`Não foi possível realizar a operação. Tente novamente!`, "error")
        })
    }

    const columns = [
        {
            field: 'ID',
            headerName: '#',
            maxWidth: 100
        },
        {
            field: 'NomeCompleto',
            headerName: 'Nome Completo',
            flex: true,
            minWidth: 250,
        },
        {
            field: 'username',
            headerName: 'Email',
            flex: 1,
            minWidth: 300,
        },
        {
            field: 'Telefone',
            headerName: 'Contato',
            flex: 1,
            minWidth: 100,
        },
        {
            field: 'NomeCliente',
            headerName: 'Cliente',
            flex: 1,
            minWidth: 250,
        },
        {
            field: 'PrimeiroAcesso',
            headerName: 'Primeiro Acesso',
            flex: 1,
            renderCell: (params) => params.row.PrimeiroAcesso ? "Sim" : "Não",
            minWidth: 150,
        },
        {
            field: 'Ativo',
            headerName: 'Ativo',
            flex: true,
            renderCell: (params) => <ButtonLockUnlock status={params.row.is_active} onClick={() => lockUnlockUser(params.row)} />,
            minWidth: 100,
        },
        {
            field: 'CriadoEm',
            headerName: 'Opções',
            flex: true,
            renderCell: (params) => <MoreVertButton id={params.row.username} options={options} item={params.row} />,
            minWidth: 100,
        }
    ]

    return (
        <LayoutPage title="Usuários" loading={loading}>
            <AlertMessage open={alertMessageState.open} message={alertMessageState.message} type={alertMessageState.type} close={closeAlert} />
            <div>
                <Grid container direction="row" justifyContent="center" spacing={2}>
                    <Grid item xl={12} md={12} xs={12}>
                        <Paper>
                            <DataGrid
                                autoHeight
                                getRowHeight={() => 'auto'}
                                rows={rowState}
                                columns={columns}
                                pageSize={25}
                                disableRowSelectionOnClick
                                sortingOrder={['asc', 'desc']}
                                localeText={localeBRDataGridText}
                            />
                        </Paper>
                    </Grid>
                </Grid>

                <ModalDialogPage
                    open={openNewUser}
                    title={selectedUser == null ? "Novo Usuário" : "Editar Usuário"}
                    close={() => {
                        setOpenNewUser(false)
                        setSelectedUser(null)
                    }}
                    button={<FabButton action={actionButton} />}>
                    <RegisterUserPage
                        userSelected={selectedUser}
                        onSuccess={() => {
                            setOpenNewUser(false)
                            reloadUsers()
                            openAlert("Operação realizada com sucesso", "success")
                        }} />
                </ModalDialogPage>

                {/* dialog delete user */}
                <ConfirmDialog
                    open={dialogDeleteUser.open}
                    title={dialogDeleteUser.title}
                    onConfirm={() => deleteUser()}
                    onClose={() => setDialogDeleteuser({ title: "", open: false, user: null })}
                >
                    <span>Essa ação não pode ser desfeita.</span>
                </ConfirmDialog>

                {/* dialog reset password */}
                <ConfirmDialog
                    open={dialogResetUserPassword.open}
                    title={dialogResetUserPassword.title}
                    onConfirm={() => resetUserPassword()}
                    onClose={() => setDialogResetUserPassword({ title: "", open: false, user: null, username: "" })}
                >
                    <span>{`Será enviado um email para ${dialogResetUserPassword.username} contendo as informações necessárias para cadastrar uma nova senha`}</span>
                </ConfirmDialog>
            </div>
        </LayoutPage >
    )

}


export default UserPage