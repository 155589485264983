import { Alert, AlertTitle, Button, Grid, TextField } from "@mui/material"
import React from "react"
import { useNavigate } from "react-router-dom"
import AuthService from "src/services/auth-service"
import LoginLayout from "./layout"


const RecoverPasswordPage = () => {
    const navigate = useNavigate()
    const [emailState, setEmailState] = React.useState("")
    const [loading, setLoading] = React.useState(false)
    const [sended, setSended] = React.useState(false)
    const authService = new AuthService()
    const [alertMessageState, setAlertMessageState] = React.useState({
        open: false,
        message: "",
        type: ""
    })

    const backLogin = () => navigate("/")

    const sendEmailToResetPassword = async () => {
        setLoading(true)
        try {
            await authService.resetPassword(emailState)
            setLoading(false)
            setSended(true)
        } catch (err) {
            setLoading(false)
            openAlert("Usuário não encontrado", "warning")
        }
    }

    const openAlert = (message, type) => {
        setAlertMessageState({
            open: true,
            message: message,
            type: type
        })

        setTimeout(() => {
            setAlertMessageState({ ...alertMessageState, open: false })
        }, 5000)
    }

    return (
        <LoginLayout title="Recuperação de Senha" loading={loading}>
            <Grid container alignContent="center" justifyContent="center">
                <Grid item xs={12}>
                    {sended
                        ? <></> :
                        <TextField
                            margin="normal"
                            fullWidth
                            id="email"
                            value={emailState}
                            label="endereço de email"
                            helperText="informe o email cadastrado na plataforma"
                            name="email"
                            variant="standard"
                            autoComplete="email"
                            autoFocus
                            onChange={(event) => {
                                setEmailState(event.target.value)
                            }}
                        />
                    }
                </Grid>

                <Grid item xs={12}>
                    {
                        sended
                            ?
                            <>
                                <Alert severity="success">
                                    <AlertTitle>Tudo certo!</AlertTitle>
                                    Enviamos um email para <strong>{emailState}</strong> com os passos necessários para recuperação de senha
                                </Alert>
                                <br />
                            </>
                            :
                            <Button
                                type="submit"
                                fullWidth
                                onClick={sendEmailToResetPassword}
                                size="large"
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                enviar
                            </Button>
                    }
                </Grid>
                <Grid item xs={12}>
                    <Button onClick={backLogin} fullWidth>voltar</Button>
                </Grid>
            </Grid>
        </LoginLayout>

    )
}

export default RecoverPasswordPage
