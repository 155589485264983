import * as React from 'react'
import { Link } from "react-router-dom"

const NotFound = () => {
    return (
        <div>
            <h2>Página não encontrada!</h2>
            <p>
                <Link to="/">Voltar para a página inicial</Link>
            </p>
        </div>
    )
}

export default NotFound