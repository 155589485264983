import React from "react"
import { FormControl } from "@mui/material"
import PropTypes from 'prop-types'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

const MultiSelectAutoComplete = (props) => {

    return (
        <FormControl variant="standard" fullWidth>
            <Autocomplete
                multiple
                id={`id-${props.id}`}
                options={props.options}
                getOptionLabel={(option) => option.label}
                limitTags={props.limitTags ? props.limitTags : 4}
                value={props.selectedValues}
                freeSolo
                defaultValue={[]}
                onChange={(event, value) => props.handleChange(value)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        helperText={props.helperText}
                        label={props.label}
                        placeholder={props.placeholder}
                    />
                )}
            />
        </FormControl>
    )
}

MultiSelectAutoComplete.propTypes = {
    options: PropTypes.array,
    defaultValue: PropTypes.array,
    selectedValues: PropTypes.array,
    handleChange: PropTypes.array,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    helperText: PropTypes.string,
    limitTags: PropTypes.number,
    id: PropTypes.string
}

export default MultiSelectAutoComplete