import { USERS_ME_PATH, USERS_PATH } from "src/utils/api-routes"
import { ServiceRequest } from "src/utils/service-request"

class UserService {

    constructor() {
        this.serviceRequest = new ServiceRequest()
    }

    async getUsers() {
        // const path = `${USERS_PATH}?limit=${limit}&offset=${offset}` // fix on backend parameters
        const path = `${USERS_PATH}?limit=1000&offset=0`
        return await this.serviceRequest.get(path)
    }

    async getuser(userId) {
        return await this.serviceRequest.get(`${USERS_PATH}/${userId}`)
    }

    async deleteUser(userId) {
        const path = `${USERS_PATH}/${userId}`
        return await this.serviceRequest.delete(path)
    }

    async blockUnblockUser(userId, block) {
        const path = `${USERS_PATH}/${userId}`
        const body = { is_active: block }

        return await this.serviceRequest.patch(path, body)
    }

    async createUser(user) {
        console.log(user)
        const body = {
            email: user.email,
            NomeCompleto: user.name,
            username: user.email,
            Telefone: user.phone.replace(/\D/g, ''),
            IDCliente: user.client.id,
            is_staff: user.admin,
            is_superuser: false
        }


        return await this.serviceRequest.post(USERS_PATH, body)
    }

    async updateUser(userId, user) {
        const body = {
            email: user.email,
            username: user.email,
            NomeCompleto: user.name,
            Telefone: user.phone.replace(/\D/g, ''),
            IDCliente: user.client.id,
            is_staff: user.admin,
        }


        return await this.serviceRequest.patch(`${USERS_PATH}/${userId}`, body)
    }

    async getLoggedUser() {
        return await this.serviceRequest.get(`${USERS_ME_PATH}`)
    }

    async updateLoggedUser(name, phone) {
        const body = {
            NomeCompleto: name,
            Telefone: phone
        }

        await this.serviceRequest.patch(`${USERS_ME_PATH}`, body)
    }

}

export default UserService