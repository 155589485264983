import React, { useEffect, useState } from "react"
import { formatDateDataGrid } from "src/utils/format-date"
import PropTypes from 'prop-types'
import DatalakeService from "src/services/datalake-service"
//import dayjs from "dayjs"
//import { DATE_FORMAT } from "src/utils/constants"
import ParameterPaginationTable from "src/components/table/parameter-pagination-table"

const ParameterDatalakePrecosEventosFinanceirosTable = (props) => {
    const dataLakeService = new DatalakeService()
    const [data, setData] = useState([])
    const [pagination, setPagination] = useState({ count: 0, next: null, previous: null })

    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        props.loading(true)
        dataLakeService.loadPaginationEndpoint(props.endpoint).then((response) => {
            props.loading(false)

            const { body } = response
            const { results, count, next, previous } = body
            const list = results.map((obj, index) => ({ ...obj, id: index }))
            setPagination({ count: count, next: next, previous: previous })
            setData(list)
        }).catch(err => {
            console.error("Error loading data:", err)
            props.loading(false)
        })
    }

    const createRow = async () => {
        const body = {
            "CETIP": null,
            "DataReferencia": null,
            "DataEvento": null,
            "DataLiquidacao": null,
            "AmortizacaoOrdinariaPercentual": null,
            "AmortizacaoExtraordinariaPercentual": null,
            "IncorporacaoJurosPercentual": null,
            "ProjecaoIndicePreco": null,
            "CorrecaoInflacaoAnual": null,
            "DataAniversario": null, 
            "VN": null,
            "VNA_PUPAR": null,
            "VNA_PUMTM": null,
            "AtualizacaoMonetariaPUPAR": null,
            "AtualizacaoMonetariaPUMTM": null,
            "AmortizacaoOrdinaria": null,
            "AmortizacaoExtraordinaria": null,
            "Juros": null,
            "IncorporacaoJuros": null,
            "Pagamento": null,
            "TaxaVF": null,
            "TaxaVP": null, 
            "LOGERRO": null,
            "Premio": null
        }

        props.loading(true)
        return dataLakeService.createTableEndpoint(props.endpoint, body).then(() => {
            props.loading(false)
            loadData(0, 25)
        }).catch((err) => {
            console.error("Error creating row:", err)
            props.loading(false)
            throw err
        })
    }

    const updateRow = async (newRow) => {
        const { ID, CETIP, DataReferencia, DataEvento, DataLiquidacao, AmortizacaoOrdinariaPercentual, AmortizacaoExtraordinariaPercentual, IncorporacaoJurosPercentual, ProjecaoIndicePreco, CorrecaoInflacaoAnual, DataAniversario, VN, VNA_PUPAR, VNA_PUMTM, AtualizacaoMonetariaPUPAR, AtualizacaoMonetariaPUMTM, AmortizacaoOrdinaria, AmortizacaoExtraordinaria, Juros, IncorporacaoJuros, Pagamento, TaxaVF, TaxaVP, LOGERRO, Premio
        } = newRow

        const body = {
            "CETIP": CETIP,
            "DataReferencia": DataReferencia,
            "DataEvento": DataEvento,
            "DataLiquidacao": DataLiquidacao,
            "AmortizacaoOrdinariaPercentual": AmortizacaoOrdinariaPercentual,
            "AmortizacaoExtraordinariaPercentual": AmortizacaoExtraordinariaPercentual,
            "IncorporacaoJurosPercentual": IncorporacaoJurosPercentual,
            "ProjecaoIndicePreco": ProjecaoIndicePreco, 
            "CorrecaoInflacaoAnual": CorrecaoInflacaoAnual,
            "DataAniversario": DataAniversario,
            "VN": VN,
            "VNA_PUPAR": VNA_PUPAR,
            "VNA_PUMTM": VNA_PUMTM,
            "AtualizacaoMonetariaPUPAR": AtualizacaoMonetariaPUPAR,
            "AtualizacaoMonetariaPUMTM": AtualizacaoMonetariaPUMTM,
            "AmortizacaoOrdinaria": AmortizacaoOrdinaria,
            "AmortizacaoExtraordinaria": AmortizacaoExtraordinaria,
            "Juros": Juros,
            "IncorporacaoJuros": IncorporacaoJuros,
            "Pagamento": Pagamento,
            "TaxaVF": TaxaVF,
            "TaxaVP": TaxaVP,
            "LOGERRO": LOGERRO,
            "Premio": Premio
        }

        props.loading(true)
        return dataLakeService.updateTableEndpoint(props.endpoint, ID, body).then(() => {
            props.loading(false)
        }).catch((err) => {
            console.error("Error updating row:", err)
            props.loading(false)
            throw err
        })
    }

    const deleteRow = async (row) => {
        props.loading(true)
        const { ID } = row
        return dataLakeService.deleteTableEndpoint(props.endpoint, ID).then(() => {
            props.loading(false)
            const listRemoved = data.filter((item) => item.ID !== ID)
            setData(listRemoved)
        }).catch((err) => {
            console.error("Error deleting row:", err)
            props.loading(false)
            throw err
        })
    }

    const onFilter = async (filterList, limit, offset) => {
        const query = filterList.map(obj => `${encodeURIComponent(obj.field)}=${encodeURIComponent(obj.value)}`).join('&')

        props.loading(true)
        dataLakeService.loadPaginationEndpoint(props.endpoint, query, limit, offset).then((response) => {
            props.loading(false)
            const { body } = response
            const { results, count, next, previous } = body
            const list = results.map((obj) => ({ ...obj, id: obj.ID }))
            setPagination({ count: count, next: next, previous: previous })
            setData(list)
        }).catch(err => {
            console.error("Error filtering data:", err)
            props.loading(false)
        })
    }

    const changePage = async (endpoint) => {
        props.loading(true)
        dataLakeService.paginate(endpoint).then((response) => {
            props.loading(false)
            const { body } = response
            const { results, count, previous, next } = body
            const list = results.map((obj) => ({ ...obj, id: obj.ID }))

            setPagination({ count: count, next: next, previous: previous })
            setData([])
            setData(list)
        }).catch(err => {
            console.error("Error changing page:", err)
            props.loading(false)
        })
    }

    const columns = [
        {
            field: 'ID',
            headerName: '#',
            disableColumnMenu: true,
            disableFilter: true,
            flex: true,
            minWidth: 80,
        },
        {
            field: 'CETIP',
            headerName: 'CETIP',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'DataReferencia',
            headerName: 'DataReferencia',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'date',
            disableColumnMenu: true,
            editable: true,
        },
        {
            field: 'DataEvento',
            headerName: 'DataEvento',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'date',
            disableColumnMenu: true,
            editable: true,
        },
        {
            field: 'DataLiquidacao',
            headerName: 'DataLiquidacao',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'date',
            disableColumnMenu: true,
            editable: true,
        },
        {
            field: 'AmortizacaoOrdinariaPercentual',
            headerName: 'AmortizacaoOrdinariaPercentual',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'AmortizacaoExtraordinariaPercentual',
            headerName: 'AmortizacaoExtraordinariaPercentual',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'IncorporacaoJurosPercentual',
            headerName: 'IncorporacaoJurosPercentual',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'ProjecaoIndicePreco',
            headerName: 'ProjecaoIndicePreco',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'CorrecaoInflacaoAnual',
            headerName: 'CorrecaoInflacaoAnual',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'DataAniversario',
            headerName: 'DataAniversario',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'date',
            disableColumnMenu: true,
            editable: true,
        },
        {
            field: 'VN',
            headerName: 'VN',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'VNA_PUPAR',
            headerName: 'VNA_PUPAR',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'VNA_PUMTM',
            headerName: 'VNA_PUMTM',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'AtualizacaoMonetariaPUPAR',
            headerName: 'AtualizacaoMonetariaPUPAR',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'AtualizacaoMonetariaPUMTM',
            headerName: 'AtualizacaoMonetariaPUMTM',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'AmortizacaoOrdinaria',
            headerName: 'AmortizacaoOrdinaria',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'AmortizacaoExtraordinaria',
            headerName: 'AmortizacaoExtraordinaria',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Juros',
            headerName: 'Juros',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'IncorporacaoJuros',
            headerName: 'IncorporacaoJuros',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Pagamento',
            headerName: 'Pagamento',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'TaxaVF',
            headerName: 'TaxaVF',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'TaxaVP',
            headerName: 'TaxaVP',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'CriadoEm',
            headerName: 'Data de Criação',
            flex: true,
            minWidth: 150,
            disableColumnMenu: true,
            disableFilter: true,
            renderCell: (params) => formatDateDataGrid(params)
        },
        {
            field: 'AtualizadoEm',
            headerName: 'Data de Atualização',
            flex: true,
            minWidth: 150,
            disableColumnMenu: true,
            disableFilter: true,
            renderCell: (params) => formatDateDataGrid(params)
        },
        {
            field: 'LOGERRO',
            headerName: 'LOGERRO',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Premio',
            headerName: 'Premio',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        }
    ]

    return (
        <div>
            <ParameterPaginationTable
                columns={columns}
                data={data}
                setData={setData}
                createRow={createRow}
                deleteRow={deleteRow}
                updateRow={updateRow}
                filterFunction={onFilter}
                pagination={pagination}
                onChangePage={changePage}
                customFilter={true} />
        </div>
    )
}

ParameterDatalakePrecosEventosFinanceirosTable.propTypes = {
    loading: PropTypes.func,
    endpoint: PropTypes.string,
}

export default ParameterDatalakePrecosEventosFinanceirosTable
