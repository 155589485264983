import { Button, Grid, Paper } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import DatePickerSelect from "src/components/date/date-picker-select"
import LayoutPage from "src/components/page-layout/layout-page"
import CurveService from "src/services/curve-service"
import { DATE_FORMAT, DATE_FORMAT_SHOW } from "src/utils/constants"
import { localeBRDataGridText } from "src/utils/data-grid-local-text"
import { formatStringToFloatDecimalCase } from "src/utils/format-decimal"


const CurveTablePage = () => {
    const curveService = new CurveService()
    const [loading, setLoading] = useState(false)
    const [dateSelected, setDateSelected] = useState(null)
    const [curveList, setCurveList] = useState([])
    const [formatedDate, setFormatedDate] = useState("")

    useEffect(() => {
        const date = dayjs()
        const dateFormatted = date.format(DATE_FORMAT)
        setDateSelected(dateFormatted)
        loadDate()
    }, [])

    const exportFile = () => {
        curveService.exportDataToCsv(dateSelected, formatedDate)
    }

    const loadDate = () => {
        setLoading(true)
        curveService.getCurvesDTS().then((response) => {
            const { body } = response
            const { data_referencia } = body
            setDateSelected(data_referencia)
            loadCurves(data_referencia)
            setFormatedDate(dayjs(data_referencia).format(DATE_FORMAT_SHOW))
        }).catch(() => {
            setLoading(false)
        })
    }

    const loadCurves = (date) => {
        setDateSelected(date)
        setLoading(true)
        curveService.getCurves(date)
            .then((response) => {
                const { body } = response
                const { data } = body
                const rows = data.map((obj, index) => ({ ...obj, id: index }))
                setCurveList(rows)
                setLoading(false)
            }).catch(() => {
                setLoading(false)
            })
    }

    const onChangeDatePickerDate = (value) => {
        setFormatedDate(value.format(DATE_FORMAT_SHOW))
        loadCurves(value.format(DATE_FORMAT))
    }

    const renderCellWithTooltip = (params) => {
        const { value } = params

        return (
            <div title={value}>
                {value}
            </div>
        )
    }

    const formatValue = (params, minimum, maximum) => {
        const { value } = params

        return formatStringToFloatDecimalCase(value, minimum, maximum)
    }

    const columns = [
        {
            field: 'id',
            headerName: '#',
            maxWidth: 100
        },
        {
            field: 'Descricao',
            headerName: 'Descrição',
            flex: true,
            minWidth: 250,
            renderCell: renderCellWithTooltip,
        },
        {
            field: 'Rating',
            headerName: 'Rating',
            flex: true,
        },
        {
            field: 'DataFormatada',
            headerName: 'Data Referência',
            flex: 1,
            type: 'date',
            valueFormatter: () => dayjs(dateSelected).format(DATE_FORMAT_SHOW),
            minWidth: 150,
        },
        {
            field: 'DU',
            headerName: 'DU',
            flex: 1,
            renderCell: formatValue,
            minWidth: 80,
        },
        {
            field: 'TIR',
            headerName: 'TIR',
            flex: 1,
            renderCell: (value) => formatValue(value, 3, 3),
            minWidth: 80,
        },
        {
            field: 'VolatilidadeTIR',
            headerName: 'Vol TIR',
            flex: 1,
            renderCell: (value) => formatValue(value, 4, 4),
            minWidth: 80,
        },
        {
            field: 'Spread',
            headerName: 'Spread',
            flex: 1,
            minWidth: 120,
            renderCell: (value) => formatValue(value, 3, 3),
        },
        {
            field: 'VolatilidadeSpread',
            headerName: 'Vol Spread',
            flex: 1,
            renderCell: (value) => formatValue(value, 4, 4),
            minWidth: 80,
        },
        {
            field: 'DTS',
            headerName: 'DTS',
            flex: 1,
            renderCell: formatValue,
            minWidth: 80,
        }
    ]

    return (
        <LayoutPage
            loading={loading}
            title="Tabela de Curvas">
            <div>
                <Grid container direction="row" alignItems={"center"} justifyContent="flex-end" spacing={3}>
                    <Grid item>
                        <DatePickerSelect label="Data de Exibição" value={dateSelected} onChange={onChangeDatePickerDate} />
                    </Grid>
                    <Grid item>
                        <Button variant="contained" onClick={exportFile}>
                            Exportar para CSV
                        </Button>
                    </Grid>
                </Grid>
                <br />
                <Grid container direction="row" justifyContent="center" spacing={2}>
                    <Grid item xl={12} md={12} xs={12}>
                        <Paper>
                            <DataGrid
                                autoHeight
                                rows={curveList}
                                columns={columns}
                                pageSize={25}
                                disableRowSelectionOnClick
                                sortingOrder={['asc', 'desc']}
                                localeText={localeBRDataGridText}
                            />
                        </Paper>

                    </Grid>
                </Grid>

            </div>


        </LayoutPage>
    )
}

export default CurveTablePage