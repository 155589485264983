import { Button, FormControl, InputLabel, MenuItem, Select, Grid, Tooltip, Chip, Typography, Paper} from "@mui/material"
import React, { useState, useEffect } from "react"
import AlertMessage from "src/components/alert-message"
import DatePickerSelect from "src/components/date/date-picker-select"
import DateTimePickerSelectDate from "src/components/date/date-time-picker-select-date"
import LayoutPage from "src/components/page-layout/layout-page"
import { DATE_FORMAT, DATE_FORMAT_ISO8601, DATE_TIME_FORMAT_SHOW } from "src/utils/constants"
import { IntegrationService } from "src/services/integration-service"
import dayjs from "dayjs"
import ProgressBar from "src/components/progress-bar"
import { formatDecimalCase } from "src/utils/format-decimal"
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded'
import SkeletonTable from "src/components/skeleton-table"
import { DataGrid } from "@mui/x-data-grid"
import { localeBRDataGridText } from "src/utils/data-grid-local-text"


const IntegrationWorkflowPage = () => {
    const [alertMessageState, setAlertMessageState] = React.useState({
        open: false,
        message: "",
        type: ""
    })
    
    const [dateSelected, setDateSelected] = useState(dayjs().subtract(7, 'day').set('hour', 19).set('minute', 15).set('second', 0).set('millisecond', 670))
    const [dateSelectedAiflow, setDateSelectedAirflow] = useState(dayjs().subtract(7, 'day').set('hour', 19).set('minute', 15).set('second', 0).set('millisecond', 670))
    const [dateSelectedDate, setDateSelectedDate] = useState(dayjs()) // default data atual
    const [dateSelectedFinal, setDateSelectedFinal] = useState(dayjs().set('hour', 19).set('minute', 15).set('second', 0).set('millisecond', 670))
    const [dateSelectedFinalAirflow, setDateSelectedFinalAirflow] = useState(dayjs().set('hour', 19).set('minute', 15).set('second', 0).set('millisecond', 670))
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState(null)
    const [loadTable, setLoadTable] = useState(false)
    const [routineList, setRoutineList] = useState([])

    const integrationService = new IntegrationService()

    const alterarValor = (event) => {
        const selectedValue = event.target.value
        setName(selectedValue)
  
    }        

    const closeAlert = () => {
        setAlertMessageState({ ...alertMessageState, open: false })

    }


    useEffect(() => {
        loadLastRoutines()
    }, [])

    const extractMetadata = (obj) => {
        const { metadata } = obj
        try {
            const valores = metadata.split(',')
            let data_referencia = valores.find(elemento => elemento.includes("data_referencia"))
            let qtde_dias = valores.find(elemento => elemento.includes("qtde_dias"))

            data_referencia !== undefined ? data_referencia = data_referencia.slice(-11).slice(0, -1) : null
            qtde_dias !== undefined ? qtde_dias = qtde_dias.slice(-3).slice(0, -1) : 0

            return { data_referencia: data_referencia, qtde_dias: qtde_dias }
        } catch (err) {
            return null
        }
    }

    const loadLastRoutines = () => {
        setLoadTable(true)
        integrationService.getRoutinesWorkflowInfo().then((response) => {
            const { body } = response
            const { results } = body
            const rows = results.map((obj, index) => {
                const metadata = extractMetadata(obj)

                return {
                    ...obj,
                    id: index,
                    data_referencia: metadata === null ? null : metadata.data_referencia,
                    qtde_dias: metadata === null ? 90 : metadata.qtde_dias,
                }
            })

            setRoutineList(rows)
            setLoadTable(false)
        }).catch((err) => {
            console.log(err)
            setLoadTable(false)
        })
    }
    const inicialRotina = () => {
        console.log(name)
        if (name === null) {
            openAlert('Escolha uma rotina', 'warning')
            return
        }
        setLoading(true)
        const indexadores = ["IPCA", "IGPM"]
        switch (name) {
            case 'B3AtivosReg':
                integrationService.startarB3AtivosReg(dateSelectedDate).then(() => {
                    setLoading(false)
                    openAlert('Processo iniciado com sucesso!', 'success')
                }).catch(() => {
                    setLoading(false)
                    openAlert('Erro no processo!', 'error')
                })
                break

            case 'B3NegAnalitico':
                integrationService.startarRotinaB3NegAnalitico(dateSelectedDate).then(() => {
                    setLoading(false)
                    openAlert('Processo iniciado com sucesso!', 'success')
                }).catch(() => {
                    setLoading(false)
                    openAlert('Erro no processo!', 'error')
                })
                break

            case 'B3NegConsolidado':
                integrationService.startarRotinaB3NegConsolidado(dateSelectedDate).then(() => {
                    setLoading(false)
                    openAlert('Processo iniciado com sucesso!', 'success')
                }).catch(() => {
                    setLoading(false)
                    openAlert('Erro no processo!', 'error')
                })
                break

            case 'BacenCadastro': {
                const formattedDate = dateSelectedDate.format('YYYY-MM-DD')
                integrationService.startarBacenCadastro(formattedDate).then(() => {
                    setLoading(false)
                    openAlert('Processo iniciado com sucesso!', 'success')
                }).catch(() => {
                    setLoading(false)
                    openAlert('Erro no processo!', 'error')
                })
                break
            }

            case 'CVMFatosRelevantes':
                integrationService.startarCVMFatosRelevantes(dateSelectedDate).then(() => {
                    setLoading(false)
                    openAlert('Processo iniciado com sucesso!', 'success')
                }).catch(() => {
                    setLoading(false)
                    openAlert('Erro no processo!', 'error')
                })
                break

            case 'EstoqueRegistrado':
                integrationService.startarRotinaEstoqueRegistrado(dateSelectedDate).then(() => {
                    setLoading(false)
                    openAlert('Processo iniciado com sucesso!', 'success')
                }).catch(() => {
                    setLoading(false)
                    openAlert('Erro no processo!', 'error')
                })
                break

            case 'MarketData':
                integrationService.startarRotinaMarkeData(dateSelected, dateSelectedFinal).then(() => {
                    setLoading(false)
                    openAlert('Processo iniciado', 'success')
                }).catch(() => {
                    setLoading(false)
                    openAlert('Erro no processo!', 'error')
                })
                break

            case 'MarketData_airflow':
                integrationService.startarRotinaMarkeDataAirflow(dateSelectedAiflow, dateSelectedFinalAirflow).then(() => {
                    setLoading(false)
                    openAlert('Processo iniciado', 'success')
                }).catch(() => {
                    setLoading(false)
                    openAlert('Erro no processo!', 'error')
                })
                break

            case 'PreviaInflacao':
                integrationService.startarRotinaPreviaInflacao(indexadores, dateSelectedDate).then(() => {
                    setLoading(false)
                    openAlert('Processo iniciado com sucesso!', 'success')
                }).catch(() => {
                    setLoading(false)
                    openAlert('Erro no processo!', 'error')
                })
                break

            case 'PreviaInflacaoAnbima':
                integrationService.startarPreviaInflacaoAnbima(dateSelectedDate).then(() => {
                    setLoading(false)
                    openAlert('Processo iniciado com sucesso!', 'success')
                }).catch(() => {
                    setLoading(false)
                    openAlert('Erro no processo!', 'error')
                })
                break

            case 'RiskFree':
                integrationService.startarRiskFree(dateSelectedDate).then(() => {
                    setLoading(false)
                    openAlert('Processo iniciado com sucesso!', 'success')
                }).catch(() => {
                    setLoading(false)
                    openAlert('Erro no processo!', 'error')
                })
                break

            case 'TaxasRecebiveisMercado':
                integrationService.startarTaxasRecebiveisMercado(dateSelectedDate).then(() => {
                    setLoading(false)
                    openAlert('Processo iniciado com sucesso!', 'success')
                }).catch(() => {
                    setLoading(false)
                    openAlert('Erro no processo!', 'error')
                })
                break

            /* case 'RiskFreeWorkflow':
                integrationService.startarRiskFreeWorkflow(dateSelectedDate).then(() => {
                    setLoading(false)
                    openAlert('Processo iniciado com sucesso!', 'success')
                }).catch(() => {
                    setLoading(false)
                    openAlert('Erro no processo!', 'error')
                })
                break */
        }
    }

    const onChangeDatePickerDate = (value) => {
        setDateSelected(value)
    }

    const onChangeDatePickerDateFinal = (value) => {
        setDateSelectedFinal(value)
    }

    const onChangeDatePickerDateTime = (value) => {
        const formattedDate = value.format(DATE_FORMAT)
        setDateSelectedDate(formattedDate)
    }

    const onChangeDatePickerDate_airflow = (value) => {
        setDateSelectedAirflow(value)
    }

    const onChangeDatePickerDateFinal_aiflow = (value) => {
        setDateSelectedFinalAirflow(value)

        const dateInicial = dayjs(value).subtract(7, 'day')
        setDateSelectedAirflow(dateInicial)

        console.log("Data Inicial (7 dias antes):", dateInicial.format(DATE_FORMAT_ISO8601))
    }

    const openAlert = (message, type) => {
        setAlertMessageState({
            ...alertMessageState,
            open: true,
            message: message,
            type: type
        })
    }


    const verifyTableStatus = (params) => {
        const { value, row } = params
        const { text, color } = integrationService.verifyTableStatusWorkflow(value)
        const { total_registros, total_registros_processados } = row

        return <Tooltip title={`processados ${total_registros_processados} de ${total_registros}`}>
            <Chip label={text} variant="contained" color={color} />
        </Tooltip>
    }

    const progressBar = (params) => {
        const { row, value } = params
        const { total_registros } = row
        const valueBar = (value / total_registros) * 100
        let formatedValue = formatDecimalCase(valueBar)

        if (value < 0.1) {
            formatedValue = 0
        }
        console.log(formatedValue)
        return <ProgressBar value={Number(formatedValue)} />
    }

    const formatDateUtc = (params, format) => {
        const { value } = params

        if (params.value === null) return "N/A"

        return dayjs(value).utc().format(format)
    }

    const columns = [
        {
            field: 'rotina',
            headerName: 'Rotina',
            minWidth: 250,
        },

        {
            field: 'rotina_grupo',
            headerName: 'Grupo',
            minWidth: 250,
        },
        // {
        //     field: 'data_referencia',
        //     headerName: 'Data Referência',
        //     minWidth: 150,
        //     type: 'date',
        //     valueFormatter: params => formatDateUtc(params, DATE_FORMAT),
        // },
        // {
        //     field: 'qtde_dias',
        //     headerName: 'Quantidade de Dias',
        //     minWidth: 150,
        // },

        {
            field: 'status',
            headerName: 'Status',
            minWidth: 200,
            renderCell: verifyTableStatus
        },
        {
            field: 'total_registros',
            headerName: 'Total de Registros',
            flex: 1,
            minWidth: 150,
           
        },
        {
            field: 'Progresso',
            headerName: 'Progresso',
            minWidth: 150,
            renderCell: (params) => progressBar(params)
            
        },
        {
            field: 'criado_por',
            headerName: 'Usuário',
            minWidth: 150,
        },
        {
            field: 'criado_em',
            headerName: 'Data de Criação',
            flex: true,
            minWidth: 200,
            type: 'date',
            valueFormatter: params => formatDateUtc(params, DATE_TIME_FORMAT_SHOW),
        },
        {
            field: 'atualizado_em',
            headerName: 'Data de Atualização',
            flex: true,
            minWidth: 200,
            type: 'date',
            valueFormatter: params => formatDateUtc(params, DATE_TIME_FORMAT_SHOW),
        },
    ]



    return (
        <LayoutPage title="Rotinas" loading={loading} >
            <AlertMessage open={alertMessageState.open} message={alertMessageState.message} type={alertMessageState.type} close={closeAlert} />

            <Grid container alignItems="flex-start" justifyContent="flex-start" spacing={3}>
                <Grid item sm={12} md={6} xl={4} xs={12}>
                    Importação manual das rotinas!
                </Grid>
            </Grid>
            <br />
            <Grid container alignItems="flex-start" justifyContent="flex-start" spacing={3}>
                <Grid item xs={12} md={6} sm={12} xl={4}>
                    <Grid container direction={"column"} alignItems={"center"} justifyContent={"center"}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Rotinas</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={name}
                                label="Rotinas"
                                onChange={alterarValor}
                            >
                                <MenuItem value="">Escolha uma rotina</MenuItem>
                                <MenuItem value="B3AtivosReg" disabled>B3AtivosReg</MenuItem>
                                <MenuItem value="B3NegAnalitico" disabled>B3NegAnalitico</MenuItem>
                                <MenuItem value="B3NegConsolidado" disabled>B3NegConsolidado</MenuItem>
                                <MenuItem value="BacenCadastro" disabled>BacenCadastro</MenuItem>
                                <MenuItem value="CVMFatosRelevantes" disabled>CVMFatosRelevantes</MenuItem>
                                <MenuItem value="EstoqueRegistrado" disabled>EstoqueRegistrado</MenuItem>
                                <MenuItem value="MarketData" disabled>MarketData</MenuItem>
                                <MenuItem value="MarketData_airflow">MarketData_airflow</MenuItem>
                                <MenuItem value="PreviaInflacao" disabled>Previa de Inflação</MenuItem>
                                <MenuItem value="PreviaInflacaoAnbima" disabled>PreviaInflacaoAnbima</MenuItem>
                                <MenuItem value="RiskFree" disabled>RiskFree</MenuItem>
                                <MenuItem value="TaxasRecebiveisMercado" disabled>TaxasRecebiveisMercado</MenuItem>
                                {/* <MenuItem value="RiskFreeWorkflow">RiskFreeWorkflow</MenuItem> */}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <br />
            <Grid container alignItems="flex-start" justifyContent="flex-start" spacing={-65} style={{ marginTop: '25px' }}>
                {name === 'MarketData' && (
                    <>
                        <Grid item sm={12} md={6} xl={4} xs={12}>
                            <DateTimePickerSelectDate
                                label="Data de Exibição"
                                onChange={onChangeDatePickerDate}
                                value={dateSelected}
                            />
                        </Grid>
                        <Grid item sm={12} md={6} xl={4} xs={12}>
                            <DateTimePickerSelectDate
                                label="Data de Exibição Final"
                                onChange={onChangeDatePickerDateFinal}
                                value={dateSelectedFinal}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
            <br />
            <Grid container alignItems="flex-start" justifyContent="flex-start" spacing={-65} style={{ marginTop: '-25px' }}>
                {name === 'MarketData_airflow' && (
                    <>
                        <Grid item sm={12} md={6} xl={4} xs={12}>
                            <DateTimePickerSelectDate
                                label="Data de Exibição"
                                onChange={onChangeDatePickerDate_airflow}
                                value={dateSelectedAiflow}
                            />
                        </Grid>
                        <Grid item sm={12} md={6} xl={4} xs={12}>
                            <DateTimePickerSelectDate
                                label="Data de Exibição Final"
                                onChange={onChangeDatePickerDateFinal_aiflow}
                                value={dateSelectedFinalAirflow}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
            <br />
            <Grid container alignItems="flex-start" justifyContent="flex-start" spacing={3}>
                {(name === 'PreviaInflacao' || name === 'B3NegConsolidado' || name === 'B3AtivosReg' || name === 'EstoqueRegistrado' || name === 'B3NegAnalitico' || name === 'CVMFatosRelevantes' || name === 'RiskFree' || name === 'PreviaInflacaoAnbima' || name === 'BacenTaxasJuros' || name === 'TaxasRecebiveisMercado' || name === 'RiskFreeWorkflow' || name === 'BacenCadastro') && (
                    <Grid item sm={12} md={6} xl={4} xs={12}>
                        <DatePickerSelect
                            label="Data de Exibição"
                            onChange={onChangeDatePickerDateTime}
                            value={dateSelectedDate}
                        />
                    </Grid>
                )}
            </Grid>

            <br />
            <br />
            <Grid container alignItems="flex-start" justifyContent="flex-start" spacing={3}>
                <Grid item sm={12} md={6} xl={4} xs={12}>
                    <Button variant="contained" onClick={inicialRotina}>Iniciar Rotina</Button>
                </Grid>
            </Grid>
            <br />
                <br />
                <Grid container direction="row" justifyContent="space-between" spacing={2}>
                    <Grid item>
                        <Typography variant={"h5"}>Últimas Rotinas</Typography>
                    </Grid>
                    <Grid item>
                        <Button variant="outlined" onClick={() => loadLastRoutines()}><RefreshRoundedIcon /></Button>
                    </Grid>
                </Grid>
                <br />
                <Grid container direction="row" justifyContent="left" spacing={2}>
                    <Grid item xl={12} md={12} xs={12}>
                        {loadTable ? <SkeletonTable /> :
                            <Paper>
                                <DataGrid
                                    autoHeight
                                    getRowHeight={() => 45}
                                    rows={routineList}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 10,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[10, 25, 50, 100]}
                                    disableRowSelectionOnClick
                                    sortingOrder={['asc', 'desc']}
                                    localeText={localeBRDataGridText}
                                />
                            </Paper>}
                    </Grid>
                </Grid>


            
        </LayoutPage>
    )
}
export default IntegrationWorkflowPage
