
import React, { Fragment, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import AuthService from "src/services/auth-service"
import { INDEX_PAGE } from "src/utils/routes"

const LogoutPage = () => {
    const navigate = useNavigate()
    const authService = new AuthService()

    useEffect(() => { 
        authService.logout()
        navigate(INDEX_PAGE)
    }, [])

    return (
        <Fragment />
    )
}

export default LogoutPage
