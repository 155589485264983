import styled from "@emotion/styled"
import { alpha, FormControlLabel, Switch } from "@mui/material"
import React from "react"
import PropTypes from 'prop-types'

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 20,
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                    theme.palette.getContrastText(theme.palette.primary.main),
                )}" d="M12 13c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6-5h-1V6c0-2.76-2.24-5-5-5-2.28 0-4.27 1.54-4.84 3.75-.14.54.18 1.08.72 1.22.53.14 1.08-.18 1.22-.72C9.44 3.93 10.63 3 12 3c1.65 0 3 1.35 3 3v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm0 11c0 .55-.45 1-1 1H7c-.55 0-1-.45-1-1v-8c0-.55.45-1 1-1h10c.55 0 1 .45 1 1v8z" /></svg>')`,
            },
            '& + .MuiSwitch-track': {
                opacity: .9,
                backgroundColor: alpha(theme.palette.primary.main, .6),
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.gray.main,
        width: 32,
        height: 32,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url(
                'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M 18 8 h -1 V 6 c 0 -2.76 -2.24 -5 -5 -5 S 7 3.24 7 6 v 2 H 6 c -1.1 0 -2 0.9 -2 2 v 10 c 0 1.1 0.9 2 2 2 h 12 c 1.1 0 2 -0.9 2 -2 V 10 c 0 -1.1 -0.9 -2 -2 -2 Z m -6 9 c -1.1 0 -2 -0.9 -2 -2 s 0.9 -2 2 -2 s 2 0.9 2 2 s -0.9 2 -2 2 Z M 9 8 V 6 c 0 -1.66 1.34 -3 3 -3 s 3 1.34 3 3 v 2 H 9 Z"/></svg>')`,

        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: alpha(theme.palette.gray.secondary, 1),
        borderRadius: 20 / 2,
    },
}))

const ButtonLockUnlock = (props) => {
    return (
        <FormControlLabel
            control={<MaterialUISwitch defaultChecked={props.status} onClick={props.onClick} />}
        />
    )

}

ButtonLockUnlock.propTypes = {
    status: PropTypes.bool,
    onClick: PropTypes.func
}

export default ButtonLockUnlock