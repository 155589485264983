import { Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Paper, Tab, Tabs, Tooltip } from "@mui/material"
import React from "react"
import ImageCarousel from "src/components/image-carousel"
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdfRounded'
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded'
import LayoutPage from "src/components/page-layout/layout-page"
import { MethodologyService } from "src/services/methodology-service"


const MethodologyPage = () => {
    const methodologyService = new MethodologyService()
    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const sections = [
        { images: ['./slide/Curvas1.JPG', './slide/Curvas2.JPG', './slide/Curvas3.JPG', './slide/Curvas4.JPG', './slide/Curvas5.JPG', './slide/Curvas6.JPG'] },
        { images: ['./slide/GR1.JPG', './slide/GR2.JPG', './slide/GR3.JPG', './slide/GR4.JPG', './slide/GR5.JPG'] },
        { images: ['./slide/Precos1.JPG', './slide/Precos2.JPG', './slide/Precos3.JPG', './slide/Precos4.JPG', './slide/Precos5.JPG', './slide/Precos6.JPG', './slide/Precos7.JPG', './slide/Precos8.JPG'] },
    ]

    const docFolder = "./docs"
    const docs = [
        'MN.01_Manual de Metolodogia de Apreçamento.pdf', 'PL.06_Política de Apreçamento.pdf', 'PL.07_Política de Continuidade de Negócios.pdf', 'PL.08_Política de ANS.pdf', 'PL.09_Política de Sustentabilidade.pdf'
    ]

    const downloadDoc = (doc) => {
        methodologyService.downloadPdf(docFolder, doc)
    }

    return <LayoutPage title="Metodologia" loading={false}>
        <div>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center">
                <Grid item sm={12} md={12} xl={8}>
                    <Paper square>
                        <Tabs
                            value={value}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={handleChange}
                            aria-label="disabled tabs example"
                        >
                            <Tab label="Curvas" />
                            <Tab label="Recebíveis" />
                            <Tab label="Preços" />
                            <Tab label="Documentação" />
                        </Tabs>
                    </Paper>
                </Grid>
            </Grid>
            <br />
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center">
                <Grid item sm={12} md={12} xl={8}>
                    {sections.map((item, index) => (
                        <div key={index}>
                            {value === index ? <ImageCarousel key={index} images={item.images} /> : null}
                        </div>
                    ))}
                </Grid>
            </Grid>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center">
                {value === 3 ?
                    <Grid item sm={12} md={12} xl={8}>
                        <Grid container direction={"row"}>
                            <Grid item sm={12} md={12} xl={6}>
                                <List dense>
                                    {docs.map((doc, index) => (
                                        <div key={index}>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <PictureAsPdfIcon sx={{ color: 'red' }} />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    secondary={"Arquivo PDF"}
                                                    primary={
                                                        <a
                                                            href={`./docs/${doc}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            style={{ textDecoration: 'underline', color: "#000" }}
                                                        >
                                                            {doc}
                                                        </a>}
                                                />
                                                <ListItemSecondaryAction>
                                                    <Tooltip title="Realizar o Download">
                                                        <IconButton edge="end" aria-label="Realizar o Download" onClick={() => downloadDoc(doc)}>
                                                            <DownloadRoundedIcon color="primary" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            <Divider />
                                        </div>
                                    ))}
                                </List>

                            </Grid>
                        </Grid>
                    </Grid> : null
                }
            </Grid>
        </div>

    </LayoutPage>
}

export default MethodologyPage