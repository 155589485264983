import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import React from "react"
import PropTypes from 'prop-types'

const ConfirmDialog = (props) => {

    const closeDialog = () => {
        props.onClose()
    }

    const confirmDialog = () => {
        props.onConfirm()
        closeDialog()
    }

    return (
        <Dialog
            open={props.open}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {props.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button id="button-no" onClick={closeDialog}>
                    {props.closeText ? props.closeText : "Náo"}
                </Button>
                <Button id="button-yes" onClick={confirmDialog} variant="contained" autoFocus>
                    {props.confirmText ? props.confirmText : "Sim"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ConfirmDialog.propTypes = {
    children: PropTypes.element,
    title: PropTypes.string,
    closeText: PropTypes.string,
    confirmText: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func
}

export default ConfirmDialog