import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import PropTypes from 'prop-types'
import { AppBar, Button, Grid, Toolbar, Typography } from '@mui/material'
import Loading from '../../components/loading'
import theme from 'src/utils/theme'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

const ModalRegisterGrupo = (props) => {

    return <div>
        {props.button}
        <Dialog
            fullScreen
            open={props.open}
            onClose={() => props.close()}>
            <Loading show={props.loading} />
            <AppBar theme={theme} sx={{ position: 'relative' }}>
                <Toolbar color="primary">
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => props.close()}
                        aria-label="close"
                    >
                        <CloseRoundedIcon />
                    </IconButton>

                    <Grid container direction="row" alignItems={"center"} justifyContent="space-between" spacing={1}>
                        <Grid item xs={12} xl={6} md={6} sx={{ textAlign: "left" }}>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                {`${props.title}`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" onClick={() => props.save()}>
                                {props.saveButtonText}
                            </Button>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>

            <div style={{ padding: 30 }}>
                {props.children}
            </div>
        </Dialog>
    </div>
}

ModalRegisterGrupo.propTypes = {
    children: PropTypes.element,
    button: PropTypes.element,
    open: PropTypes.bool,
    loading: PropTypes.bool,
    close: PropTypes.func,
    save: PropTypes.func,
    title: PropTypes.string,
    saveButtonText: PropTypes.string
}


export default ModalRegisterGrupo