import { ServiceRequest } from "src/utils/service-request"
import { CETIP_EMISSAO_PATH, DATALAKE_EVENTOS_CREDITO_DEVEDOR_PATH, DATALAKE_EVENTOS_CREDITO_EMISSAO_PATH, DATALAKE_RATING_DEVEDOR_PATH, DATALAKE_RATING_EMISSAO_PATH, DATALAKE_SPREADS_NEGOCIADOS, ULTIMO_EVENTO_CREDITO_DEVEDOR_PATH, ULTIMO_EVENTO_CREDITO_EMISSAO_PATH, ULTIMO_RATING_DEVEDOR_PATH, ULTIMO_RATING_EMISSAO_PATH, ULTIMO_SPREADS_NEGOCIADOS_PATH } from "src/utils/api-routes"

class EmissaoService {
    constructor() {
        this.serviceRequest = new ServiceRequest()
    }

    async listCetipIsinEmissao(cetipIsin) {
        return this.serviceRequest.get(`${CETIP_EMISSAO_PATH}?cetip_isin=${cetipIsin.toUpperCase()}`)
    }

    async getUltimoRatingEmissao(cetipIsin) {
        return this.serviceRequest.get(`${ULTIMO_RATING_EMISSAO_PATH}?cetip=${cetipIsin.toUpperCase()}`)
    }

    async getUltimoRatingDevedor(cpfCnpj) {
        return this.serviceRequest.get(`${ULTIMO_RATING_DEVEDOR_PATH}?cpf_cnpj=${cpfCnpj}`)
    }

    async getUltimoEventoCreditoEmissao(cetipIsin) {
        return this.serviceRequest.get(`${ULTIMO_EVENTO_CREDITO_EMISSAO_PATH}?cetip=${cetipIsin.toUpperCase()}`)
    }

    async getUltimoEventoCreditoDevedor(cpfCnpj) {
        return this.serviceRequest.get(`${ULTIMO_EVENTO_CREDITO_DEVEDOR_PATH}?cpf_cnpj=${cpfCnpj}`)
    }

    async getUltimoSpreadNegociados(cetip) {
        return this.serviceRequest.get(`${ULTIMO_SPREADS_NEGOCIADOS_PATH}?cetip=${cetip.toUpperCase()}`)
    }

    async ratingEmissao(method, value) {
        switch (method) {
            case "get":
                return this.serviceRequest.get(`${DATALAKE_RATING_EMISSAO_PATH}${value}`)
            case "post":
                return this.serviceRequest.post(`${DATALAKE_RATING_EMISSAO_PATH}`, value)
            case "delete":
                return this.serviceRequest.delete(`${DATALAKE_RATING_EMISSAO_PATH}/${value}`)
            default:
                throw new Error("Invalid method")
        }
    }

    async ratingDevedor(method, value) {
        switch (method) {
            case "get":
                return this.serviceRequest.get(`${DATALAKE_RATING_DEVEDOR_PATH}${value}`)
            case "post":
                return this.serviceRequest.post(`${DATALAKE_RATING_DEVEDOR_PATH}`, value)
            case "delete":
                return this.serviceRequest.delete(`${DATALAKE_RATING_DEVEDOR_PATH}/${value}`)
            default:
                throw new Error("Invalid method")
        }
    }
    async eventoCreditoEmissao(method, value) {
        switch (method) {
            case "get":
                return this.serviceRequest.get(`${DATALAKE_EVENTOS_CREDITO_EMISSAO_PATH}${value}`)
            case "post":
                return this.serviceRequest.post(`${DATALAKE_EVENTOS_CREDITO_EMISSAO_PATH}`, value)
            case "delete":
                return this.serviceRequest.delete(`${DATALAKE_EVENTOS_CREDITO_EMISSAO_PATH}/${value}`)
            default:
                throw new Error("Invalid method")
        }
    } 
    
    async eventoCreditoDevedor(method, value) {
        switch (method) {
            case "get":
                return this.serviceRequest.get(`${DATALAKE_EVENTOS_CREDITO_DEVEDOR_PATH}${value}`)
            case "post":
                return this.serviceRequest.post(`${DATALAKE_EVENTOS_CREDITO_DEVEDOR_PATH}`, value)
            case "delete":
                return this.serviceRequest.delete(`${DATALAKE_EVENTOS_CREDITO_DEVEDOR_PATH}/${value}`)
            default:
                throw new Error("Invalid method")
        }
    }

    async ultimoNegocios(method, value) {
        switch (method) {
            case "get":
                return this.serviceRequest.get(`${DATALAKE_SPREADS_NEGOCIADOS}${value}`)
            case "post":
                return this.serviceRequest.post(`${DATALAKE_SPREADS_NEGOCIADOS}`, value)
            case "delete":
                return this.serviceRequest.delete(`${DATALAKE_SPREADS_NEGOCIADOS}/${value}`)
            default:
                throw new Error("Invalid method")
        }
    }

}

export default EmissaoService