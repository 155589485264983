import { JWT_STORAGE_KEY, PERMISSIONS_STORAGE_KEY } from "src/utils/constants"
import { ServiceRequest } from "src/utils/service-request"
import StorageService from "./storage-service"

export class TokenService {
    constructor() {
        this.storage = new StorageService()
        this.request = new ServiceRequest()
    }

    decodeToken() {
        try {
            const token = this.storage.getItemLocalStorage(JWT_STORAGE_KEY).split(" ")[1]
            const base64Url = token.split('.')[1]
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
            const decoded = JSON.parse(atob(base64))
            return decoded
        } catch (error) {
            console.error('Error decoding token:', error.message)
            return null
        }
    }

    verifyUserIsAdmin() {
        const token = this.decodeToken()

        if (token === null) {
            return false
        }

        const { user } = token
        const { Administrador } = user

        return Administrador
    }

    verifyPermission(menuId) {
        const storageValue = this.storage.getItemLocalStorage(PERMISSIONS_STORAGE_KEY)
        const permissions = JSON.parse(storageValue)

        if (permissions.indexOf(menuId) !== -1) {
            return true
        }

        return false
    }

}