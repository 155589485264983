import React from 'react'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, TextField } from '@mui/material'
import { formatDecimalCaseReplaceDot } from 'src/utils/format-decimal'

const RangeValueField = (props) => {
    const [first, setFirst] = useState("")
    const [second, setSecond] = useState("")

    const changeFirst = (event) => {
        const value = event.target.value
        setFirst(value)
        props.onChange(formatDecimalCaseReplaceDot(value), formatDecimalCaseReplaceDot(second))
    }

    const changeSecond = (event) => {
        const value = event.target.value
        setSecond(value)
        props.onChange(formatDecimalCaseReplaceDot(first), formatDecimalCaseReplaceDot(value))
    }

    return (
        <div>
            <Grid container direction={"row"} alignContent={"flex-end"} justifyContent={"flex-end"} spacing={2}>
                <Grid item xs={6} sm={6} md={6}>
                    <TextField
                        label={props.labelFirst}
                        focused
                        fullWidth
                        type={"text"}
                        value={first}
                        onChange={changeFirst}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <TextField
                        label={props.labelSecond}
                        focused
                        fullWidth
                        type={"text"}
                        value={second}
                        onChange={changeSecond}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

RangeValueField.propTypes = {
    onChange: PropTypes.func,
    labelFirst: PropTypes.string,
    labelSecond: PropTypes.string,
    value: PropTypes.string
}

export default RangeValueField