import React from "react"
import PropTypes from 'prop-types'
import { Backdrop, CircularProgress } from "@mui/material"

const Loading = (props) => {
    return <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={props.show}
    >
        <CircularProgress color="inherit" />
    </Backdrop>
}

Loading.propTypes = {
    show: PropTypes.bool
}

export default Loading