import { Typography, Box, Tabs, Tab } from "@mui/material"
import React, { useState } from "react"
import LayoutPage from "src/components/page-layout/layout-page"
import BacenTaxaMediaPFParameters from "./parameterTables/bacenTaxaMediaPFParameters"
import BacenTaxaMediaPJParameters from "./parameterTables/bacenTaxaMediaPJParameters"
import BacenInadimplenciaParameters from "./parameterTables/bacenInadimplenciaParameters"
import BacenPDDAdicionalParameters from "./parameterTables/bacenPDDAdicionalParameters"
import BacenSRCParameters from "./parameterTables/bacenSRCParameters"
import BacenTaxasJurosParameters from "./parameterTables/bacenTaxasJurosParameters"
import RazaoRatingParameters from "./parameterTables/razaoRatingParameters"
import BVModeloParameters from "./parameterTables/bvModeloParameters"
import PDRatingParameters from "./parameterTables/pdRatingParameters"
import TipoRecebivelParameters from "./parameterTables/tipoRecebivelParameters"
import TipoGarantiaParameters from "./parameterTables/tipoGarantiaParameters"
import ScoreRatingParameters from "./parameterTables/scoreRatingParameters"
import ScoreBureauRatingParameters from "./parameterTables/scoreBureauRatingParameters"
import AlertMessage from "src/components/alert-message"

const ParametersPage = () => {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0)

    const handleChange = (_event, newValue) => {
        setSelectedTabIndex(newValue)
    }

    // ERROR HANDLING

    const [alertMessageState, setAlertMessageState] = useState({
        open: false,
        message: "",
        type: ""
    })

    const openAlert = (message, type) => {
        setAlertMessageState({
            open: true,
            message: message,
            type: type
        })

        setTimeout(() => {
            setAlertMessageState({ ...alertMessageState, open: false })
        }, 5000)
    }


    return (
        <LayoutPage title="PARAMETROS">
            <AlertMessage open={alertMessageState.open} message={alertMessageState.message} type={alertMessageState.type} close={() => {}} />
            <Typography variant="h4">Parâmetros</Typography>

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={selectedTabIndex} onChange={handleChange} aria-label="tabs">
                    <Tab label="Bacen Taxa Media PF" key="1" />
                    <Tab label="Bacen Taxa Media PJ" key="2" />
                    <Tab label="Razao Rating" key="3" />
                    <Tab label="BV Modelo" key="4" />
                    <Tab label="PD Rating" key="5" />
                    <Tab label="Tipo Recebivel" key="6" />
                    <Tab label="Tipo Garantia" key="7" />
                    <Tab label="Score Rating" key="8" />
                    <Tab label="Score Bureau Rating" key="9" />
                    <Tab label="Bacen Inadimplencia" key="10" />
                    <Tab label='Bacen PDD Adicional' key="11"/>
                    <Tab label='Bacen SRC' key="12"/>
                    <Tab label='Bacen Taxas Juros' key="13"/>
                </Tabs>
            </Box>
            
            <BacenTaxaMediaPFParameters value={0} index={selectedTabIndex} openAlert={openAlert} />
            <BacenTaxaMediaPJParameters value={1} index={selectedTabIndex} openAlert={openAlert} />
            <RazaoRatingParameters value={2} index={selectedTabIndex} openAlert={openAlert} />
            <BVModeloParameters value={3} index={selectedTabIndex} openAlert={openAlert} />
            <PDRatingParameters value={4} index={selectedTabIndex} openAlert={openAlert} />
            <TipoRecebivelParameters value={5} index={selectedTabIndex} openAlert={openAlert} />
            <TipoGarantiaParameters value={6} index={selectedTabIndex} openAlert={openAlert} />
            <ScoreRatingParameters value={7} index={selectedTabIndex} openAlert={openAlert} />
            <ScoreBureauRatingParameters value={8} index={selectedTabIndex} openAlert={openAlert} />
            <BacenInadimplenciaParameters value={9} index={selectedTabIndex} openAlert={openAlert}/>
            <BacenPDDAdicionalParameters value={10} index={selectedTabIndex} openAlert={openAlert}/>
            <BacenSRCParameters value={11} index={selectedTabIndex} openAlert={openAlert}/>
            <BacenTaxasJurosParameters value={12} index={selectedTabIndex} openAlert={openAlert}/>

        </LayoutPage>
    )
}

export default ParametersPage
