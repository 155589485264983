import { Grid, Paper } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import React, { useEffect, useState } from "react"
import AlertMessage from "src/components/alert-message"
import MoreVertButton from "src/components/more-vert-button"
import LayoutPage from "src/components/page-layout/layout-page"
import AccessService from "src/services/access-service"
import { localeBRDataGridText } from "src/utils/data-grid-local-text"
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import ConfirmDialog from "src/components/confirm-dialog"
import FabButton from "src/components/fab-button"
import RegisterGrupoPage from "./RegisterGrupoPage"
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import ModalRegisterGrupo from "src/pages/access/ModalRegisterGrupo"


const AccessPage = () => {
    const accessService = new AccessService()
    const [grupos, setGrupos] = useState([])
    const [openRegisterGrupo, setOpenRegisterGrupo] = useState(false)
    const [selectedGrupo, setSelectedGrupo] = useState(null)
    const [idGrupoSelected, setIdGrupoSelected] = useState(null)
    const [loading, setLoading] = useState(false)
    const [alertMessageState, setAlertMessageState] = React.useState({
        open: false,
        message: "",
        type: ""
    })
    const [dialogDeleteGrupo, setDialogDeleteGrupo] = useState({
        open: false,
        title: "",
        grupo: null
    })
    const [permissionsSelected, setPermissionSelected] = useState([])
    const [nomeGrupo, setNomeGrupo] = useState("")

    useEffect(() => {
        loadGrupos()
    }, [])

    const loadGrupos = () => {
        accessService.getGrupos().then((response) => {
            const { results } = response
            const rows = results.map((obj, index) => ({ ...obj, id: index }))
            setGrupos(rows)
        }).catch(() => {
            openAlert("Não foi possível carregar os grupos, tente novamente.", "error")
        })
    }

    const reloadGrupos = () => {
        setGrupos([])
        setSelectedGrupo(null)
        setIdGrupoSelected(null)
        loadGrupos()
    }

    const deleteGrupo = () => {
        const { grupo } = dialogDeleteGrupo
        const { Nome } = grupo

        setLoading(true)
        accessService.deleteGrupo(Nome).then(() => {
            setLoading(false)
            openAlert(`Grupo ${Nome} removido!`, "warning")
            reloadGrupos()
        }).catch(() => {
            openAlert(`Erro ao tentar remover o grupo ${Nome}. Tente novamente!`, "error")
            setLoading(false)
        })
    }

    const openEditClient = (grupo) => {
        const { Nome, Permissoes } = grupo
        setSelectedGrupo(grupo)
        setNomeGrupo(Nome)
        setPermissionSelected(Permissoes)
        setIdGrupoSelected(Nome)

        setOpenRegisterGrupo(true)
    }

    const openDialogDeleteClient = (grupo) => {
        const { Nome } = grupo
        setDialogDeleteGrupo({ grupo: grupo, title: `Deseja realmente excluir o grupo ${Nome}?`, open: true })
    }

    const closeAlert = () => {
        setAlertMessageState({ ...alertMessageState, open: false })
    }

    const openAlert = (message, type) => {
        setAlertMessageState({
            ...alertMessageState,
            open: true,
            message: message,
            type: type
        })
    }

    const saveGrupo = () => {
        setOpenRegisterGrupo(false)
        setLoading(true)
        accessService.createGrupo(nomeGrupo, permissionsSelected).then(() => {
            setLoading(false)
            openAlert("Grupo criado com sucesso", "success")
            reloadGrupos()
        }).catch(() => {
            setLoading(false)
            openAlert("Erro ao criar o grupo, tente novamente", "error")
        })
    }

    const updateGrupo = () => {
        setOpenRegisterGrupo(false)
        setLoading(true)
        accessService.updateGrupo(idGrupoSelected, nomeGrupo, permissionsSelected).then(() => {
            setLoading(false)
            openAlert("Grupo atualizado com sucesso", "success")
            reloadGrupos()
        }).catch(() => {
            setLoading(false)
            openAlert("Erro ao atualizar o grupo, tente novamente", "error")
        })
    }

    const actionButton = {
        icon: <AddRoundedIcon />,
        onClick: () => {
            setOpenRegisterGrupo(true)
        },
        color: "primary",
    }

    const options = [
        {
            icon: <EditRoundedIcon />,
            text: "Editar",
            function: openEditClient
        },
        {
            icon: <DeleteRoundedIcon color="error" />,
            text: "Excluir",
            function: openDialogDeleteClient,
        },
    ]

    const columns = [
        {
            field: 'id',
            headerName: '#',
            flex: true,
            maxWidth: 80
        },
        {
            field: 'Nome',
            headerName: 'Nome do Grupo',
            flex: true,
        },
        {
            field: '',
            headerName: 'Opções',
            flex: true,
            renderCell: (params) => <MoreVertButton options={options} item={params.row} />,
            minWidth: 100,
        }
    ]


    return <LayoutPage title="Grupos de Acesso" loading={loading}>
        <div>
            <AlertMessage open={alertMessageState.open} message={alertMessageState.message} type={alertMessageState.type} close={closeAlert} />
            <Grid container direction="row" justifyContent="center" spacing={2}>
                <Grid item xl={12} md={12} xs={12}>
                    <Paper>
                        <DataGrid
                            autoHeight
                            getRowHeight={() => 'auto'}
                            rows={grupos}
                            columns={columns}
                            pagination
                            pageSize={10}
                            disableRowSelectionOnClick
                            sortingOrder={['asc', 'desc']}
                            localeText={localeBRDataGridText}
                        />
                    </Paper>
                </Grid>
            </Grid>

            <ModalRegisterGrupo
                open={openRegisterGrupo}
                title={selectedGrupo == null ? "Novo Grupo" : "Editar Grupo"}
                close={() => {
                    setSelectedGrupo(null)
                    setNomeGrupo("")
                    setIdGrupoSelected(null)
                    setPermissionSelected([])
                    setOpenRegisterGrupo(false)
                }}
                save={selectedGrupo == null ? saveGrupo : updateGrupo}
                saveButtonText={"Salvar"}
                button={<FabButton action={actionButton} />}>
                <RegisterGrupoPage
                    callbackItems={(value) => { setPermissionSelected(value) }}
                    callbackName={(value) => setNomeGrupo(value)}
                    name={nomeGrupo}
                    permissoes={permissionsSelected}
                    readOnlyName={selectedGrupo !== null ? true : false}
                    grupoSelected={selectedGrupo}
                    onSuccess={() => {
                        setOpenRegisterGrupo(false)
                        reloadGrupos()
                        openAlert(`Operação realizada com sucesso`, "success")
                    }} />
            </ModalRegisterGrupo>

            <ConfirmDialog
                open={dialogDeleteGrupo.open}
                title={dialogDeleteGrupo.title}
                onConfirm={() => deleteGrupo()}
                onClose={() => setDialogDeleteGrupo({ title: "", open: false, user: null })}>
                <span>Essa ação não pode ser desfeita.</span>
            </ConfirmDialog>

        </div>
    </LayoutPage>
}

export default AccessPage