import { Grid, Typography } from '@mui/material'
import React, { useState, useEffect, useRef } from 'react'
import LayoutPage from 'src/components/page-layout/layout-page'
import Loading from 'src/components/loading'

const PriceChangePage = () => {
    const [loading, setLoading] = useState(true)
    const [cookieSet, setCookieSet] = useState(false)
    const iframeRef = useRef(null)

    const handleIframeMessage = (event) => {
        if (event.data === 'iframeLoaded') {
            setLoading(false)
        } else if (event.data === 'iframeReloading') {
            setLoading(true)
        }
    }

    useEffect(() => {
        window.addEventListener('message', handleIframeMessage)
        return () => {
            window.removeEventListener('message', handleIframeMessage)
        }
    }, [])

    useEffect(() => {
        const jwt = localStorage.getItem('jwt')
        if (jwt) {
            document.cookie = `c2p_jwt=${jwt}; domain=.c2ps.com.br; path=/`
            // document.cookie = `c2p_jwt=${jwt}; domain=.c2ps.localhost; path=/`
        }
        setCookieSet(true)
    }, [])

    return (
        <LayoutPage title="Títulos Privados">
            <>
                <Loading show={loading} />
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h4">Títulos Privados</Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item sx={{ width: '100%' }}>
                        {cookieSet && (
                            <iframe 
                                ref={iframeRef}
                                src={`https://ftrader.c2ps.com.br/datalake/ftrader/CadastroTitulo.aspx`}
                                width="100%" 
                                style={{ border: 'none', height: '100vh' }}
                            />
                        )}
                    </Grid>
                </Grid>
            </>
        </LayoutPage>
    )
}

export default PriceChangePage
