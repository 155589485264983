import React from "react"
import { Button } from "@mui/material"
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import PropTypes from 'prop-types'

function ConfirmDelete(props) {
    const { open, cancel, confirm } = props

    return (
        <Dialog
            open={open}
            onClose={cancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Você tem certeza de que deseja deletar este item?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={confirm}>
                Sim
            </Button>
            <Button onClick={cancel} autoFocus>
                Não
            </Button>
            </DialogActions>
        </Dialog>

    )
}

export default ConfirmDelete

ConfirmDelete.propTypes = {
    open: PropTypes.bool,
    cancel: PropTypes.func,
    confirm: PropTypes.func
}
