import React, { useState } from "react"
import PropTypes from 'prop-types'
import { AppBar, Button, Dialog, FormControlLabel, Grid, IconButton, Switch, Toolbar, Typography } from "@mui/material"
import LineChartComponent from "./line-chart"
import theme from "src/utils/theme"
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone'
import InsertPhotoTwoToneIcon from '@mui/icons-material/InsertPhotoTwoTone'
import { ExportFile } from "src/utils/export-file"
import DateIntervalButtonGroup from "../date/date-interval-button-group"
import { DATE_FORMAT_SHOW } from "src/utils/constants"
import Loading from "../loading"

const LineChartRangeDateModalComponent = (props) => {
    const [checked, setChecked] = useState(false)
    const exportFile = new ExportFile()
    const [chartTitle, setChartTitle] = useState("")
    const [showDatesSelected, setShowDateSelected] = useState({
        firstDate: "",
        endDate: ""
    })

    const generateChartImage = () => {
        setChartTitle(`${props.title}`)

        setTimeout(() => {
            const element = document.getElementById('chartCapture')
            exportFile.toImage(element, `${props.title}`)
            setChartTitle("")
        }, 100)
    }

    const generateCsvFile = () => {
        exportFile.toCSV(props.csvData, `${props.title}`, ";")
    }

    const showDate = (value) => {
        console.log(chartTitle)
        const { firstDate, endDate } = value

        setShowDateSelected({
            firstDate: firstDate ? firstDate.format(DATE_FORMAT_SHOW) : null,
            endDate: endDate.format(DATE_FORMAT_SHOW),
        })
    }

    const generateChartData = async () => {
        // await props.loadChartData(value)
    }

    const handleChange = (event) => {
        if (event.target.value) {
            setChecked(event.target.checked)
        } else {
            setChecked(event.target.checked)
        }
    }

    return <Dialog
        fullScreen
        open={props.open}
        onClose={() => props.onClose()}>
        <Loading show={props.loading} />
        <AppBar theme={theme} sx={{ position: 'relative' }}>
            <Toolbar color="primary">
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => props.onClose()}
                    aria-label="close"
                >
                    <CloseRoundedIcon />
                </IconButton>
                <Grid container direction="row" alignItems={"center"} justifyContent="flex-start" spacing={1}>
                    <Grid item xs={12} xl={6} md={6} sx={{ textAlign: "left" }}>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {`${props.title}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} xl={6} md={6} sx={{ textAlign: "right" }}>
                        <Button color="primary" variant="contained" onClick={generateChartImage}>
                            <InsertPhotoTwoToneIcon />
                            &nbsp; Gerar Imagem
                        </Button>
                        <Button color="primary" variant="contained" onClick={generateCsvFile}>
                            <DescriptionTwoToneIcon />
                            &nbsp; Gerar CSV
                        </Button>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>

        <div style={{ padding: 30 }}>
            <Grid container direction="column" alignItems={"flex-end"} justifyContent="flex-end" spacing={2}>
                <Grid item>
                    <DateIntervalButtonGroup callback={(value) => {
                        showDate(value)
                        generateChartData(value)
                        props.loadChartData(value)
                    }} />
                </Grid>
                <Grid item>
                    <Typography variant="body2">{`${showDatesSelected.firstDate !== null ? `${showDatesSelected.firstDate} à` : 'Exibindo até'} ${showDatesSelected.endDate}`}</Typography>
                </Grid>
            </Grid>
            <br />
            <Grid container direction="row" alignItems={"start"} justifyContent="flex-start" spacing={0}>
                <Grid item sm={12} md={12} xl={12} xs={12}>
                    <div>
                        {!checked ? <LineChartComponent
                            id="chartCapture"
                            tension={0.0}
                            disableXAxis={props.disableXAxis}
                            title={chartTitle}
                            xTitle={props.chartData.xTitle}
                            yTitle={props.chartData.yTitle}
                            xValue={props.chartData.xValue}
                            values={props.chartData.values} /> : null
                        }
                    </div>
                </Grid>
                <Grid item sm={12} md={12} xl={12} xs={12}>
                    {checked ? <LineChartComponent
                        disableXAxis={props.disableXAxis}
                        id="chartCaptureTwo"
                        title={chartTitle}
                        tension={0.0}
                        xTitle={props.chartTwoData.xTitle}
                        yTitle={props.chartTwoData.yTitle}
                        xValue={props.chartTwoData.xValue}
                        values={props.chartTwoData.values} /> : null
                    }
                </Grid>
            </Grid>

            {props.chartTwoData ?
                <Grid container direction="row" alignItems={"center"} justifyContent="center" spacing={0}>
                    <Grid item>
                        Taxa MTM &nbsp;&nbsp;
                        <FormControlLabel
                            control={<Switch
                                checked={checked}
                                onChange={handleChange} />}
                            label="Taxa TIR"
                        />
                    </Grid>
                </Grid>
                : null}

        </div>
    </Dialog>
}

LineChartRangeDateModalComponent.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    disableXAxis: PropTypes.bool,
    title: PropTypes.string,
    loadChartData: PropTypes.func,
    loading: PropTypes.bool,
    chartData: PropTypes.object,
    chartTwoData: PropTypes.object,
    csvData: PropTypes.array
}

export default LineChartRangeDateModalComponent