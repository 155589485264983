import React from "react"
import { ListItemButton, ListItemIcon, ListItemText, Tooltip } from "@mui/material"
import theme from "src/utils/theme"
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from "react-router-dom"

const iconButton = {
    color: theme.palette.primary.inverse
}

const MenuListItem = (props) => {
    const location = useLocation()
    const navigate = useNavigate()

    return (
        <Tooltip title={props.item.title} arrow placement="right">
            <ListItemButton selected={location.pathname === props.item.route} onClick={() => navigate(props.item.route)}>
                <ListItemIcon sx={iconButton}>{props.item.icon}</ListItemIcon>
                <ListItemText sx={iconButton} primary={props.item.title} />
            </ListItemButton>
        </Tooltip>
    )
}

MenuListItem.propTypes = {
    item: PropTypes.shape({
        route: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        icon: PropTypes.element.isRequired,
    }),

}


export default MenuListItem