import { Button, Grid, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import LayoutPage from "src/components/page-layout/layout-page"
import theme from "src/utils/theme"
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded'
import { useLocation } from "react-router-dom"
import AlertMessage from "src/components/alert-message"
import AccessService from "src/services/access-service"

const WithoutPermissionPage = () => {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [alertMessageState, setAlertMessageState] = React.useState({
        open: false,
        message: "",
        type: "info"
    })
    const location = useLocation()
    const accessService = new AccessService()

    useEffect(() => {
        if (location.state != null) {
            setData(location.state)
        }

    }, [])

    const reportError = () => {
        setLoading(true)
        accessService.reportError(data).then(() => {
            setLoading(false)
            openAlert("Problema Reportado! Em breve entraremos em contato.", "success")
        })

    }

    const requestTrial = () => {
        setLoading(true)
        accessService.requestTrial(data).then(() => {
            setLoading(false)
            openAlert("Solicitação Enviada Com Sucesso! Em breve entraremos em contato.", "success")
        })

    }

    const closeAlert = () => {
        setAlertMessageState({ ...alertMessageState, open: false })
    }

    const openAlert = (message, type) => {
        setAlertMessageState({
            ...alertMessageState,
            open: true,
            message: message,
            type: type
        })
    }

    return <LayoutPage title="C2P" loading={loading}>
        <AlertMessage open={alertMessageState.open} message={alertMessageState.message} type={alertMessageState.type} close={closeAlert} />
        <Grid container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{
                marginTop: 20
            }}
        >
            <Grid item sm={12} md={12} xl={12}>
                <SecurityRoundedIcon sx={{ fontSize: 120, color: theme.palette.gray.main }} />
            </Grid>
            <Grid item sm={12} md={12} xl={12}>
                <Typography sx={{
                    fontSize: '30px',
                    fontWeight: 'bold',
                    textAlign: "center",
                    color: theme.palette.primary.main,
                }}>
                    Você ainda não tem acesso a essa página.
                </Typography>
                <Typography sx={{
                    fontSize: '30px',
                    fontWeight: 'bold',
                    textAlign: "center",
                    color: theme.palette.primary.main,
                }}>
                    Solicite trial do serviço. Caso já tenha contratado reporte esse erro.
                </Typography>
            </Grid>
        </Grid>
        <br />
        <Grid container direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}>
            <Grid item >
                <Button variant="contained" onClick={reportError}>Reportar Erro</Button>
            </Grid>
            <Grid item >
                <Button variant="contained" onClick={requestTrial}>Solicitar Trial</Button>
            </Grid>
        </Grid>

    </LayoutPage>
}

export default WithoutPermissionPage