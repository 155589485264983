import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import InputNumberMask from "src/components/input-number-mask"
import Loading from "src/components/loading"
import ClientService from "src/services/client-service"
import { CNPJ_MASK, COMPANY_TYPE, CPF_MASK, PERSON_TYPE } from "src/utils/constants"
import PropTypes from 'prop-types'
import MultiSelect from "src/components/mult-select"
import AccessService from "src/services/access-service"

const RegisterClientPage = (props) => {
    const clientService = new ClientService()
    const accessService = new AccessService()
    const [client, setClient] = useState({
        name: "",
        ssn: "",
        type: COMPANY_TYPE,
        grupos: []
    })
    const [options, setOptions] = useState([])
    const [documentMask, setDocumentMask] = useState(CPF_MASK)
    const [load, setLoad] = useState(false)
    const [validateInput, setValidateInput] = useState({
        name: false,
        ssn: false
    })
    const [grupos, setGrupos] = useState([])

    const handleSelectChange = (event) => {
        setGrupos(event.target.value)
    }

    useEffect(() => {
        loadAccessGrupos()
    }, [])

    const loadAccessGrupos = () => {
        setLoad(true)
        accessService.getGrupos().then((response) => {
            setLoad(false)
            const { results } = response
            const createdOptions = results.map((item) => ({ value: item.Nome, label: item.Nome }))
            setOptions(createdOptions)
            loadClient()
        })
    }

    const loadClient = () => {
        if (props.clientSelected == null) {
            return
        }

        const { ID } = props.clientSelected
        setLoad(true)
        clientService.getClient(ID).then((response) => {
            setLoad(false)
            const { body } = response
            const { Nome, CPFCNPJ, Grupos } = body

            setDocumentMask(CNPJ_MASK)
            let type = COMPANY_TYPE
            if (CPFCNPJ.length < 12) {
                setDocumentMask(CPF_MASK)
                type = PERSON_TYPE
            }

            setClient({ name: Nome, ssn: CPFCNPJ, type: type })
            setGrupos(Grupos)
        })
    }

    const changeType = (event) => {
        const type = event.target.value
        setClient({ ...client, type: type })

        if (type == PERSON_TYPE) {
            setDocumentMask(CPF_MASK)
        }

        if (type == COMPANY_TYPE) {
            setDocumentMask(CNPJ_MASK)
        }
    }

    const validate = () => {
        if (client.name === "") {
            setValidateInput({ ...validateInput, name: true })
            return false
        }

        if (client.ssn === "") {
            setValidateInput({ ...validateInput, ssn: true })
            return false
        }

        return true
    }

    const registerClient = () => {
        if (validate()) {
            setLoad(true)
            clientService.createClient(client, grupos).then(() => {
                setLoad(false)
                props.onSuccess()
            }).catch(() => {
                setLoad(false)
            })
        }
    }

    const updateClient = () => {
        if (validate()) {
            setLoad(true)
            clientService.updateClient(props.clientSelected.ID, client, grupos).then(() => {
                setLoad(false)
                props.onSuccess()
            }).catch(() => {
                setLoad(false)
            })
        }
    }

    return (
        <div>
            <Loading show={load} />
            <Grid container spacing={3}>
                <Grid item sm={12} md={12} xl={12} xs={12}>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        error={validateInput.name}
                        fullWidth
                        value={client.name}
                        onChange={(event) => setClient({ ...client, name: event.target.value })}
                        placeholder="Informe o nome do cliente"
                        label="Nome do Cliente"
                        variant="standard" />
                </Grid>
            </Grid>
            <br />

            <Grid container spacing={3}>
                <Grid item sm={12} md={12} xl={4} xs={12}>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel id="type-register">Tipo do Cadastro</InputLabel>
                        <Select
                            labelId="type-register"
                            id="type-register-id"
                            value={client.type}
                            onChange={changeType}
                            label="type"
                        >
                            <MenuItem value={PERSON_TYPE}>Pessoa Física</MenuItem>
                            <MenuItem value={COMPANY_TYPE}>Pessoa Jurídica</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={12} md={12} xl={8} xs={12}>
                    <InputNumberMask
                        value={client.ssn}
                        mask={documentMask}
                        error={validateInput.ssn}
                        label="Número do Documento"
                        onChange={(value) => setClient({ ...client, ssn: value })} />
                </Grid>
                <Grid item sm={12} md={12} xl={12} xs={12}>
                    <MultiSelect
                        title={"Grupos de Acesso"}
                        options={options}
                        selectedValues={grupos}
                        handleChange={handleSelectChange}
                    />
                </Grid>
            </Grid>

            <br />
            <br />
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={3}>
                <Grid item>
                    <Button variant="contained"
                        onClick={props.clientSelected ? updateClient : registerClient}>
                        {props.clientSelected !== null ? "Atualizar" : "Cadastrar"}
                    </Button>
                </Grid>
            </Grid>

        </div>
    )
}

RegisterClientPage.propTypes = {
    onSuccess: PropTypes.func,
    clientSelected: PropTypes.object
}


export default RegisterClientPage