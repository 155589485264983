import React, { useEffect, useState } from "react"
import { formatDateDataGrid } from "src/utils/format-date"
import PropTypes from 'prop-types'
import ParameterPaginationTable from "src/components/table/parameter-pagination-table"
import DatalakeService from "src/services/datalake-service"
import dayjs from "dayjs"
import { DATE_FORMAT } from "src/utils/constants"

const ParameterDatalakePrecosAndadosTable = (props) => {
    const dataLakeService = new DatalakeService()
    const [data, setData] = useState([])
    const [pagination, setPagination] = useState({ count: 0, next: null, previous: null })

    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        props.loading(true)
        dataLakeService.loadPaginationEndpoint(props.endpoint).then((response) => {
            props.loading(false)

            const { body } = response
            const { results, count, next, previous } = body
            const list = results.map((obj, index) => ({ ...obj, id: index }))
            setPagination({ count: count, next: next, previous: previous })
            setData(list)
        })
    }

    const createRow = async () => {
        const body = {
            CETIP: 'N/A',
            DataReferencia: dayjs().format(DATE_FORMAT),
            DataAnterior: dayjs().format(DATE_FORMAT),
            AndarPUPAR: false,
            AndarPU_MTM: false,
            RepetirPUPAR: false,
            RepetirPU_MTM: false,
            TIR_Accrual_Atual: 0,
            TIR_Accrual_Anterior: 0,
            TIR_MTM_Atual: 0,
            TIR_MTM_Anterior: 0,
            PercentualPUPAR_Andado: 0,
            PercentualPUPAR_Anterior: 0,
            DurationMTM_Andado: 0,
            DurationMTM_Anterior: 0,
            PUPAR_Andado: 0,
            PUPAR_Anterior: 0,
            PU_MTM_Andado: 0,
            PU_MTM_Anterior: 0,
            LOGERRO: 'N/A'
        }

        props.loading(true)
        return dataLakeService.createTableEndpoint(props.endpoint, body).then(() => {
            props.loading(false)
            loadData()
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const updateRow = async (newRow) => {
        const { ID, CETIP, DataReferencia, DataAnterior, AndarPUPAR, AndarPU_MTM, RepetirPUPAR, RepetirPU_MTM,
            TIR_Accrual_Atual, TIR_Accrual_Anterior, TIR_MTM_Atual, TIR_MTM_Anterior, PercentualPUPAR_Andado,
            PercentualPUPAR_Anterior, DurationMTM_Andado, DurationMTM_Anterior, PUPAR_Andado, PUPAR_Anterior,
            PU_MTM_Andado, PU_MTM_Anterior, LOGERRO } = newRow

        const body = {
            CETIP: CETIP,
            DataReferencia: DataReferencia,
            DataAnterior: DataAnterior,
            AndarPUPAR: AndarPUPAR,
            AndarPU_MTM: AndarPU_MTM,
            RepetirPUPAR: RepetirPUPAR,
            RepetirPU_MTM: RepetirPU_MTM,
            TIR_Accrual_Atual: TIR_Accrual_Atual,
            TIR_Accrual_Anterior: TIR_Accrual_Anterior,
            TIR_MTM_Atual: TIR_MTM_Atual,
            TIR_MTM_Anterior: TIR_MTM_Anterior,
            PercentualPUPAR_Andado: PercentualPUPAR_Andado,
            PercentualPUPAR_Anterior: PercentualPUPAR_Anterior,
            DurationMTM_Andado: DurationMTM_Andado,
            DurationMTM_Anterior: DurationMTM_Anterior,
            PUPAR_Andado: PUPAR_Andado,
            PUPAR_Anterior: PUPAR_Anterior,
            PU_MTM_Andado: PU_MTM_Andado,
            PU_MTM_Anterior: PU_MTM_Anterior,
            LOGERRO: LOGERRO
        }

        props.loading(true)
        return dataLakeService.updateTableEndpoint(props.endpoint, ID, body).then(() => {
            props.loading(false)
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const deleteRow = async (row) => {
        props.loading(true)
        const { ID } = row
        return dataLakeService.deleteTableEndpoint(props.endpoint, ID).then(() => {
            props.loading(false)
            const listRemoved = data.filter((item) => item.ID !== ID)
            setData(listRemoved)
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const onFilter = async (filterList, limit, offset) => {
        const query = filterList.map(obj => `${encodeURIComponent(obj.field)}=${encodeURIComponent(obj.value)}`).join('&')

        props.loading(true)
        dataLakeService.loadPaginationEndpoint(props.endpoint, query, limit, offset).then((response) => {
            props.loading(false)
            const { body } = response
            const { results, count, next, previous } = body
            const list = results.map((obj) => ({ ...obj, id: obj.ID }))
            setPagination({ count: count, next: next, previous: previous })
            setData(list)
        })
    }

    const changePage = async (endpoint) => {
        props.loading(true)
        dataLakeService.paginate(endpoint).then((response) => {
            props.loading(false)
            const { body } = response
            const { results, count, previous, next } = body
            const list = results.map((obj) => ({ ...obj, id: obj.ID }))

            setPagination({ count: count, next: next, previous: previous })
            setData([])
            setData(list)
        })
    }

    const columns = [
        {
            field: 'ID',
            headerName: '#',
            disableColumnMenu: true,
            disableFilter: true,
            flex: true,
            minWidth: 80,
        },
        {
            field: 'CETIP',
            headerName: 'CETIP',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'DataReferencia',
            headerName: 'DataReferencia',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'date',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'DataAnterior',
            headerName: 'DataAnterior',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'date',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'AndarPUPAR',
            headerName: 'AndarPUPAR',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'AndarPU_MTM',
            headerName: 'AndarPU_MTM',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'RepetirPUPAR',
            headerName: 'RepetirPUPAR',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'RepetirPU_MTM',
            headerName: 'RepetirPU_MTM',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'TIR_Accrual_Atual',
            headerName: 'TIR_Accrual_Atual',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'TIR_Accrual_Anterior',
            headerName: 'TIR_Accrual_Anterior',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'TIR_MTM_Atual',
            headerName: 'TIR_MTM_Atual',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'TIR_MTM_Anterior',
            headerName: 'TIR_MTM_Anterior',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'PercentualPUPAR_Andado',
            headerName: 'PercentualPUPAR_Andado',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'PercentualPUPAR_Anterior',
            headerName: 'PercentualPUPAR_Anterior',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'DurationMTM_Andado',
            headerName: 'DurationMTM_Andado',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'DurationMTM_Anterior',
            headerName: 'DurationMTM_Anterior',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'PUPAR_Andado',
            headerName: 'PUPAR_Andado',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'PUPAR_Anterior',
            headerName: 'PUPAR_Anterior',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'PU_MTM_Andado',
            headerName: 'PU_MTM_Andado',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'PU_MTM_Anterior',
            headerName: 'PU_MTM_Anterior',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'LOGERRO',
            headerName: 'LOGERRO',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'CriadoEm',
            headerName: 'Data de Criação',
            flex: true,
            minWidth: 150,
            disableColumnMenu: true,
            disableFilter: true,
            renderCell: (params) => formatDateDataGrid(params)
        },
        {
            field: 'AtualizadoEm',
            headerName: 'Data de Atualização',
            flex: true,
            minWidth: 150,
            disableColumnMenu: true,
            disableFilter: true,
            renderCell: (params) => formatDateDataGrid(params)
        }
    ]

    return (
        <div>
            <ParameterPaginationTable
                columns={columns}
                data={data}
                setData={setData}
                createRow={createRow}
                deleteRow={deleteRow}
                updateRow={updateRow}
                filterFunction={onFilter}
                pagination={pagination}
                onChangePage={changePage}
                customFilter={true} />
        </div>
    )
}

ParameterDatalakePrecosAndadosTable.propTypes = {
    loading: PropTypes.func,
    endpoint: PropTypes.string,
}

export default ParameterDatalakePrecosAndadosTable
