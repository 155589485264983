import React from "react"
import { useState } from "react"
import PropTypes from 'prop-types'
import { TextField } from "@mui/material"

const EditableNumberInput = ({ fieldValue, handleChange, editable=true }) => {
    const [isEditing, setIsEditing] = useState((fieldValue.length === 0))
    const [inputValue, setInputValue] = useState(fieldValue)
    const availableCharacters = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', ',', '.']

    const handleEditKeyUp = (event) => {
        if (inputValue === '') return
        if (event.keyCode !== 13) return
        handleChange(inputValue)
        setIsEditing(false)
    }

    const handleBlur = () => {
        if (inputValue === '') return
        handleChange(inputValue)
        setIsEditing(false)
    }

    const handleOnChange = (event) => {
        const characterArraySet = [...new Set(event.target.value.split(''))]
        if(!characterArraySet.every(character => availableCharacters.includes(character))) return
        if(event.target.value.length > 3) {
            const characterArray = [
                ...event.target.value
                .split('')
                .filter(character=>![',', '.']
                .includes(character))
            ]
            const dotAmout = Math.floor((characterArray.length-3)/3)
            characterArray.splice(-2, 0, ',')
            let dotIndex = -2
            for(let i = 0; i < dotAmout; i++) {
                dotIndex -= 4
                characterArray.splice(dotIndex, 0, '.')
            } 
            setInputValue(characterArray.join(''))
        } else {
            setInputValue(event.target.value)
        }
    }

    return (
        (isEditing && editable) ? <TextField autoComplete={false} inputProps={{
            style: {
                height: "10px",
                width: "250px", 
                fontSize: "0.875rem",
            },
        }}
            variant="standard"
            onKeyUp={handleEditKeyUp}
            onBlur={handleBlur}
            onChange={handleOnChange} value={inputValue} /> : <div onClick={() => setIsEditing(true)}>{inputValue}</div>
    )
}

export default EditableNumberInput

EditableNumberInput.propTypes = {
    id: PropTypes.any,
    fieldValue: PropTypes.any,
    handleChange: PropTypes.func,
    editable: PropTypes.bool,
}
