import * as React from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import CssBaseline from '@mui/material/CssBaseline'
import PropTypes from 'prop-types'
import SideMenuList from './side-menu-list'
import theme from 'src/utils/theme'

const drawerWidth = theme.layout.drawerWidth

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    backgroundColor: theme.palette.primary.main
})

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
    backgroundColor: theme.palette.primary.main
})

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
)

const AppBarSideMenu = () => {
    const [open, setOpen] = React.useState(false)

    const openMenu = () => {
        setOpen(true)
    }


    const closeMenu = () => {
        setOpen(false)
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            <Drawer variant="permanent" open={open}>
                <SideMenuList openSideMenu={openMenu} closeSideMenu={closeMenu} />
            </Drawer>

            {/* Page Content */}
            {/* <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <main>
                    {props.children}
                </main>
            </Box> */}

        </Box>
    )
}

AppBarSideMenu.propTypes = {
    children: PropTypes.element,
    title: PropTypes.string,
}

export default AppBarSideMenu