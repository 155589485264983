import { Box, Grid, Tab, Tabs } from "@mui/material"
import React, { useState } from "react"
import LayoutPage from "src/components/page-layout/layout-page"

const SupportPage = () => {
    const tabs = [
        { id: 0, description: "Serviços" },
        { id: 1, description: "Cadastro de Ativos" },
    ]

    const [selectedTab, setSelectedTab] = useState(0)

    const handleChange = (_event, newValue) => {
        setSelectedTab(newValue)
    }

    const renderContent = () => {
        switch (selectedTab) {
            case 0:
                return (
                    <iframe
                        width='100%'
                        height='100%'
                        style={{ border: 0 }}
                        src='https://app.pipefy.com/public/form/OPu0NEo2?embedded=true'
                        title={tabs[selectedTab].description}
                    ></iframe>
                )
            case 1:
                return (
                    <iframe
                        width='100%'
                        height='100%'
                        style={{ border: 0 }}
                        src='https://app.pipefy.com/public/form/n6JK-RkG?embedded=true'
                        title="Cadastro"
                    ></iframe>
                )
            default:
                return null
        }
    }

    return (
        <LayoutPage title="Suporte">
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    height: "100vh",
                    display: "flex",
                    flexDirection: "column"
                }}>
                <Tabs value={selectedTab} onChange={handleChange} aria-label="tabs">
                    {tabs.map((tab) => (
                        <Tab label={tab.description} key={tab.id} />
                    ))}
                </Tabs>

                <Box
                    sx={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                    }}>
                    <Grid
                        container
                        direction="row"
                        alignContent="flex-start"
                        justifyContent="flex-start"
                        spacing={0}
                        sx={{
                            padding: 2,
                            height: "100%", // altura de 100% da Box
                        }}>
                        <Grid item xs={12} sm={12} md={6} xl={4} sx={{ height: "100%" }}>
                            {renderContent()}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </LayoutPage>
    )
}

export default SupportPage
