import { Button, Grid, IconButton, InputAdornment, TextField } from "@mui/material"
import React from "react"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import LoginLayout from "./layout"
import { useNavigate } from "react-router-dom"
import AuthService from "src/services/auth-service"
import { RECOVER_PASSWORD_PAGE } from "src/utils/routes"

const LoginPage = () => {
    const navigate = useNavigate()
    const authService = new AuthService()

    const [loading, setLoading] = React.useState(false)
    const [loginState, setLoginState] = React.useState({
        login: "",
        password: ""
    })
    const [showPassword, setShowPassword] = React.useState(false)
    const [alertMessageState, setAlertMessageState] = React.useState({
        open: false,
        message: "",
        type: ""
    })

    const togglePasswordHide = () => setShowPassword(!showPassword)
    const goToRecoverPassword = () => navigate(RECOVER_PASSWORD_PAGE)

    const submitLogin = async () => {
        if (loginState.login === "" || loginState.password === "") {
            openAlert("Usuário ou senha inválidos", "warning")
            return
        }

        setLoading(true)
        authService.login(loginState.login, loginState.password).then(() => {
            setLoading(false)
            navigate('/home')
        }).catch(() => {
            setLoading(false)
            openAlert("Usuário ou senha inválidos", "warning")
        })
    }

    const openAlert = (message, type) => {
        setAlertMessageState({
            open: true,
            message: message,
            type: type
        })

        setTimeout(() => {
            setAlertMessageState({ ...alertMessageState, open: false })
        }, 5000)
    }

    return (
        <LoginLayout title="Login" loading={loading} alert={alertMessageState}>
            <Grid container alignContent="center" justifyContent="center">
                <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        fullWidth
                        id="email"
                        value={loginState.login}
                        label="endereço de email"
                        name="email"
                        variant="standard"
                        autoComplete="email"
                        onChange={(event) => {
                            setLoginState({ ...loginState, login: event.target.value })
                        }}
                        autoFocus
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        fullWidth
                        value={loginState.password}
                        variant="standard"
                        name="password"
                        label="senha"
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                event.preventDefault()
                                submitLogin()
                            }
                        }}
                        onChange={(event) => {
                            setLoginState({ ...loginState, password: event.target.value })
                        }}
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        autoComplete="current-password"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password"
                                        edge="start"
                                        onClick={togglePasswordHide}
                                    >
                                        {showPassword ? (
                                            <VisibilityIcon />
                                        ) : (
                                            <VisibilityOffIcon />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container
                        direction="row"
                        justifyContent="flex-end">
                        <Grid item justifyContent="flex-end">
                            <Button
                                onClick={goToRecoverPassword}
                                sx={{ textTransform: 'none' }}>esqueci minha senha</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        id="login-button"
                        type="submit"
                        fullWidth
                        size="large"
                        onClick={submitLogin}
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        acessar
                    </Button>
                </Grid>
            </Grid>
        </LoginLayout>

    )
}

export default LoginPage
