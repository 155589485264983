import { Grid, LinearProgress } from "@mui/material"
import React, { } from "react"
import PropTypes from 'prop-types'

const ProgressBar = (props) => {

    return <Grid container alignItems={"center"} justifyContent={"center"}>
        <Grid item xs={12} sm={12} md={12}>
            <div style={{ position: 'relative' }}>
                <LinearProgress
                    variant="determinate"
                    value={props.value}
                    sx={{
                        height: 20,
                        borderRadius: 2,
                        '& .MuiLinearProgress-bar': {
                            fontSize: 12
                        },
                    }}
                />
                <div style={{ position: 'absolute', width: '100%', textAlign: 'center', top: '50%', transform: 'translateY(-50%)', color: 'white' }}>
                    {props.value} %
                </div>
            </div>
        </Grid>
    </Grid>
}

ProgressBar.propTypes = {
    value: PropTypes.number
}


export default ProgressBar