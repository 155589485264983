import React from "react"
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import AppRegistrationRoundedIcon from '@mui/icons-material/AppRegistrationRounded'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded'
import Inventory2RoundedIcon from '@mui/icons-material/Inventory2Rounded'
import TimelineRoundedIcon from '@mui/icons-material/TimelineRounded'
import RequestQuoteRoundedIcon from '@mui/icons-material/RequestQuoteRounded'
import StorageRoundedIcon from '@mui/icons-material/StorageRounded'
import FunctionsRoundedIcon from '@mui/icons-material/FunctionsRounded'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded'
import ApiRoundedIcon from '@mui/icons-material/ApiRounded'
import AssignmentIndRoundedIcon from '@mui/icons-material/AssignmentIndRounded'
import TipsAndUpdatesRoundedIcon from '@mui/icons-material/TipsAndUpdatesRounded'
import VerifiedUserRoundedIcon from '@mui/icons-material/VerifiedUserRounded'
import CalculateIcon from '@mui/icons-material/Calculate'
import ListAltIcon from '@mui/icons-material/ListAlt'
import AnalyticsIcon from '@mui/icons-material/Analytics'
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded'
import MenuListItemCollapse from "./menu-list-item-collapse"
import MenuListItem from "./menu-list-item"
import PriceChangeIcon from '@mui/icons-material/PriceChange'

import {
    CLIENT_PAGE,
    LOGOUT_PAGE,
    USER_PAGE,
    HOME_PAGE,
    CURVES_PAGE,
    CURVES_TIR_PAGE,
    CURVES_SPREAD_PAGE,
    CURVES_SETORIAL_PAGE,
    CURVES_SUBSETORIAL_PAGE,
    CURVES_DTS_PAGE,
    PRICES_PAGE,
    PROFILE_PAGE,
    PRICES_PU_PAGE,
    IMPORT_PAGE,
    PRICES_TIR_PAGE,
    PRICES_PUPAR_PAGE,
    PRICE_CHANGE_PAGE,
    CONCESSIONS_PAGE,
    MARKET_RATES_PAGE,
    WALLETS_PAGE,
    ACCESS_PAGE,
    PARAMETERS_PAGE,
    METHODOLOGY_PAGE,
    ROUTINES_HOME_PAGE,
    INTEGRATION_DATA_PAGE,
    DELETE_DATA_PAGE,
    PRICES_UPDATE_SPREADS,
    API_INTEGRATION_PAGE,
    PARAMETERS_CALC_PAGE,
    SUPPORT_PAGE,
    PARAMETERS_DATALAKE_PAGE,
    PRODUCT_PAGE,
    PRICES_CHARACTERISTICS,
    SIMULADOR_SUBORDINACAO_PAGE,
    EMISSAO_PAGE


} from "src/utils/routes"
import PropTypes from 'prop-types'

const SideMenuList = (props) => {

    const homeLink = {
        title: "Home",
        route: HOME_PAGE,
        isCollapse: false,
        icon: <HomeRoundedIcon />,
    }

    const accessLinks = {
        title: "Acesso",
        route: "",
        id: "menu_acesso",
        admin: true,
        open: React.useState(false),
        icon: <AppRegistrationRoundedIcon />,
        subLinks: [
            {
                title: "Clientes",
                id: "menu_acesso_clientes",
                route: CLIENT_PAGE,
                admin: true,
                icon: <AccountCircleRoundedIcon />,
            },
            {
                title: "Usuários",
                id: "menu_acesso_usuarios",
                route: USER_PAGE,
                admin: true,
                icon: <ManageAccountsRoundedIcon />,
            },
            {
                title: "Produtos",
                route: PRODUCT_PAGE,
                id: "menu_acesso_produtos",
                admin: true,
                icon: <Inventory2RoundedIcon />,
            },
            {
                title: "Grupos",
                route: ACCESS_PAGE,
                id: "menu_acesso_grupos",
                admin: true,
                icon: <SecurityRoundedIcon />,
            },
            {
                title: "Trilhas de Auditoria",
                route: "#",
                id: "menu_acesso_trilhas",
                admin: true,
                icon: <VerifiedUserRoundedIcon />,
            }
        ]
    }

    const calcLinks = {
        title: "Credit Calc",
        route: "",
        id: "menu_calc",
        admin: false,
        icon: <FunctionsRoundedIcon />,
        subLinks: [
            {
                title: "Parâmetros",
                route: PARAMETERS_CALC_PAGE,
                admin: false,
                id: "menu_calc_parametros",
                icon: <FunctionsRoundedIcon />,
            },
            {
                title: "Tratamento Spreads",
                route: "#",
                admin: false,
                id: "menu_calc_tratamento",
                icon: <FunctionsRoundedIcon />,
            },
            {
                title: "Contingência",
                route: ROUTINES_HOME_PAGE,
                admin: false,
                id: "menu_calc_contigencia",
                icon: <FunctionsRoundedIcon />,
            },
            
            {
                title: "Processos",
                route: "#",
                admin: false,
                id: "menu_calc_processos",
                icon: <FunctionsRoundedIcon />,
            }
        ]
    }

    const curveLinks = {
        title: "Curvas",
        route: "",
        isCollapse: true,
        id: "menu_curvas",
        admin: false,
        valueName: "curve",
        icon: <TimelineRoundedIcon />,
        subLinks: [
            {
                title: "Tabela de Curvas",
                route: CURVES_PAGE,
                admin: false,
                id: "menu_curvas_tabela",
                icon: <TimelineRoundedIcon />,
            },
            {
                title: "Curvas ETTJC", //mudado 16/07
                route: CURVES_TIR_PAGE,
                admin: false,
                id: "menu_curvas_tir",
                icon: <TimelineRoundedIcon />,
            },
            {
                title: "Curvas de Spread",
                route: CURVES_SPREAD_PAGE,
                id: "menu_curvas_spread",
                admin: false,
                icon: <TimelineRoundedIcon />,
            },
            {
                title: "Curvas Setoriais",
                route: CURVES_SETORIAL_PAGE,
                admin: false,
                id: "menu_curvas_setoriais",
                icon: <TimelineRoundedIcon />,
            },
            {
                title: "Curvas Subsetoriais",
                route: CURVES_SUBSETORIAL_PAGE,
                admin: false,
                id: "menu_curvas_subsetoriais",
                icon: <TimelineRoundedIcon />,
            },
            {
                title: "Curvas de DTS",
                route: CURVES_DTS_PAGE,
                id: "menu_curvas_dts",
                admin: false,
                icon: <TimelineRoundedIcon />,
            }
        ]
    }

    const calculatorLinks = {
        title: "Calculadora",
        route: "",
        id: "menu_calculadora",
        admin: false,
        icon: <PriceChangeIcon />,
        subLinks: [
            {
                title: "Títulos Privados",
                route: PRICE_CHANGE_PAGE,
                id: "menu_calculadora_price_change",
                admin: false,
                icon: <PriceChangeIcon />,
            }
        ]
    }

    const priceLinks = {
        title: "Preços",
        route: "",
        id: "menu_precos",
        admin: false,
        icon: <RequestQuoteRoundedIcon />,
        subLinks: [
            {
                title: "Tabela de PU/TIR",
                route: PRICES_PAGE,
                id: "menu_precos_tabela",
                admin: false,
                icon: <RequestQuoteRoundedIcon />,
            },
            {
                title: "Características",
                route: PRICES_CHARACTERISTICS,
                id: "menu_precos_caracteristicas",
                admin: false,
                icon: <RequestQuoteRoundedIcon />,
            },
    
            {
                title: "Atualização de Spreads",
                route: PRICES_UPDATE_SPREADS,
                id: "menu_precos_atualizacao_spreads",
                admin: false,
                icon: <RequestQuoteRoundedIcon />,
            },
            {
                title: "Gráficos de PU",
                route: PRICES_PU_PAGE,
                id: "menu_precos_grafico_pu",
                admin: false,
                icon: <RequestQuoteRoundedIcon />,
            },
            {
                title: "Gráficos de TIR",
                route: PRICES_TIR_PAGE,
                admin: false,
                id: "menu_precos_grafico_tir",
                icon: <RequestQuoteRoundedIcon />,
            },
            {
                title: "Gráficos de PUPAR",
                route: PRICES_PUPAR_PAGE,
                admin: false,
                id: "menu_precos_grafico_pupar",
                icon: <RequestQuoteRoundedIcon />,
            }
        ]
    }

    const lakeLinks = {
        title: "Data Lake",
        route: "",
        id: "menu_data_lake",
        admin: false,
        icon: <StorageRoundedIcon />,
        subLinks: [
            {
                title: "Emissão",
                route: EMISSAO_PAGE,
                admin: false,
                id: "menu_data_lake_emissao",
                icon: <StorageRoundedIcon />,
            },
            {
                title: "Consulta de Dados",
                route: PARAMETERS_DATALAKE_PAGE,
                admin: false,
                id: "menu_data_lake_consulta_dados",
                icon: <StorageRoundedIcon />,
            },
            {
                title: "Integração Dados Externos",
                route: INTEGRATION_DATA_PAGE,
                admin: false,
                id: "menu_data_lake_market_data",
                icon: <StorageRoundedIcon />,
            },
           /*  {
                title: "CETIP/ANBIMA",
                route: "#",
                admin: false,
                id: "menu_data_lake_cetip_anbima",
                icon: <StorageRoundedIcon />,
            }, */
            {
                title: "Importação de Dados",
                route: IMPORT_PAGE,
                admin: false,
                id: "menu_data_lake_importacao_dados",
                icon: <StorageRoundedIcon />,
            },
           /*  {
                title: "Rotinas",
                route: ROUTINES_PAGE,
                admin: false,
                id: "menu_data_lake_rotinas",
                icon: <StorageRoundedIcon />,
            }, */
            {
                title: "Exclusão de Dados",
                route: DELETE_DATA_PAGE,
                admin: false,
                id: "menu_data_lake_exclusao_dados",
                icon: <StorageRoundedIcon />,
            }
        ]
    }

    const configLinks =
    {
        title: "Configurações",
        route: "#",
        id: null,
        isCollapse: true,
        valueName: "settings",
        admin: false,
        icon: <SettingsRoundedIcon />,
        subLinks: [
            {
                title: "Perfil do Usuário",
                route: PROFILE_PAGE,
                id: null,
                admin: false,
                icon: <AssignmentIndRoundedIcon />,
            },
            {
                title: "Suporte",
                route: SUPPORT_PAGE,
                id: "menu_config_suporte",
                admin: false,
                icon: <SupportAgentRoundedIcon />,
            },
            {
                title: "Metodologia",
                route: METHODOLOGY_PAGE,
                admin: false,
                id: "menu_config_metodologia",
                icon: <TipsAndUpdatesRoundedIcon />,
            },
            {
                title: "API Integração",
                route: API_INTEGRATION_PAGE,
                admin: false,
                id: "menu_config_api",
                icon: <ApiRoundedIcon />,
            },
            {
                title: "Sair",
                route: LOGOUT_PAGE,
                id: null,
                admin: false,
                icon: <LogoutRoundedIcon />,
            }
        ]
    }

    const receivableManagementLinks =
    {
        title: "GR",
        route: "",
        id: "menu_gr",
        admin: false,
        icon: <CalculateIcon />,
        subLinks: [
            {
                title: "Concessões",
                route: CONCESSIONS_PAGE,
                admin: false,
                id: "menu_gr_operacoes",
                icon: <ListAltIcon />,
            },
            {
                title: "Carteiras",
                route: WALLETS_PAGE,
                admin: false,
                id: "menu_gr_carteiras",
                icon: <AnalyticsIcon />,
            },
            {
                title: "Simulador Subordinação",
                route: SIMULADOR_SUBORDINACAO_PAGE,
                admin: false,
                id: "menu_gr_simulador_subordinacao",
                icon: <AnalyticsIcon />,
            },
            {
                title: "Taxas de Mercado",
                route: MARKET_RATES_PAGE,
                admin: false,
                id: "menu_gr_taxas",
                icon: <AnalyticsIcon />,
            },
            {
                title: "Parametros",
                route: PARAMETERS_PAGE,
                admin: true,
                id: "menu_gr_parametros",
                icon: <AnalyticsIcon />,
            }
        ]
    }

    return (
        <div>
            <MenuListItem item={homeLink} />
            <MenuListItemCollapse sideMenu="acesso" item={accessLinks} openSideMenu={props.openSideMenu} closeSideMenu={props.closeSideMenu} />
            <MenuListItemCollapse item={calcLinks} openSideMenu={props.openSideMenu} closeSideMenu={props.closeSideMenu} />
            <MenuListItemCollapse item={receivableManagementLinks} openSideMenu={props.openSideMenu} closeSideMenu={props.closeSideMenu} />
            <MenuListItemCollapse item={curveLinks} openSideMenu={props.openSideMenu} closeSideMenu={props.closeSideMenu} />
            <MenuListItemCollapse item={priceLinks} openSideMenu={props.openSideMenu} closeSideMenu={props.closeSideMenu} />
            <MenuListItemCollapse item={calculatorLinks} openSideMenu={props.openSideMenu} closeSideMenu={props.closeSideMenu} />
            <MenuListItemCollapse item={lakeLinks} openSideMenu={props.openSideMenu} closeSideMenu={props.closeSideMenu} />
            <MenuListItemCollapse sideMenu="config" item={configLinks} openSideMenu={props.openSideMenu} closeSideMenu={props.closeSideMenu} />
        </div>
    )
}

SideMenuList.propTypes = {
    openSideMenu: PropTypes.func,
    closeSideMenu: PropTypes.func,
}

export default SideMenuList