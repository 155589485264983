import { Grid, Paper, Tab, Tabs, TextField } from "@mui/material"
import { Box } from "@mui/system"
import { DataGrid } from "@mui/x-data-grid"
import React, { useEffect, useState } from "react"
import Loading from "src/components/loading"
import AccessService from "src/services/access-service"
import { localeBRDataGridText } from "src/utils/data-grid-local-text"
import PropTypes from 'prop-types'
import ButtonLockUnlock from "src/components/button-lock-unlock"


const RegisterGrupoPage = (props) => {
    const accessService = new AccessService()
    const [loading, setLoading] = useState(false)
    const [permissoesApi, setPermissoesApi] = useState([])
    const [permissoesFront, setPermissoesFront] = useState([])
    const [value, setValue] = useState(0)


    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    useEffect(() => {
        loadPermissoes()
    }, [])

    const loadPermissoes = () => {
        setLoading(true)
        accessService.getPermissoes().then((response) => {
            const { results } = response
            const rows = results.map((obj, index) => ({ ...obj, id: index }))
            const api = rows.filter((obj) => obj.Tipo == "api")
            const front = rows.filter((obj) => obj.Tipo == "frontend")

            setPermissoesApi(api)
            setPermissoesFront(front)
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    }

    const addRemovePermission = (row, type) => {
        let list = props.permissoes
        if (!type) {
            list.push(row.Flag)
            props.callbackItems(list)
        }

        if (type) {
            const removed = list.filter((item) => item !== row.Flag)
            props.callbackItems(removed)
        }
    }

    const isSelected = (flag) => {
        const value = props.permissoes.includes(flag)
        return value
    }

    const columnsApi = [
        {
            field: 'id',
            headerName: '#',
            minWidth: 80,
            renderCell: (params) => <ButtonLockUnlock status={isSelected(params.row.Flag)} onClick={() => addRemovePermission(params.row, isSelected(params.row.Flag))} />
        },
        {
            field: 'Flag',
            headerName: 'Permissão',
            minWidth: 600
        },
        {
            field: 'Detalhes',
            headerName: 'Descrição Sobre a Permissão',
            flex: 1,
        },
    ]

    const columnsFront = [
        {
            field: 'id',
            headerName: '#',
            minWidth: 80,
            renderCell: (params) => <ButtonLockUnlock status={isSelected(params.row.Flag)} onClick={() => addRemovePermission(params.row, isSelected(params.row.Flag))} />
        },
        {
            field: 'Flag',
            headerName: 'Permissão',
            flex: true,
            minWidth: 600
        },
        {
            field: 'Detalhes',
            headerName: 'Descrição Sobre a Permissão',
            flex: true,
        },
    ]

    return <div>
        <Loading show={loading} />
        <Grid container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}>
            <Grid item xl={6} md={12} sm={12}>
                <TextField
                    value={props.name}
                    InputProps={{
                        readOnly: props.readOnlyName,
                    }}
                    onChange={(event) => {
                        props.callbackName(event.target.value)
                    }} fullWidth variant="standard" focused label="Nome do Grupo" />
            </Grid>
        </Grid>
        <br />
        <Grid container>
            <Grid item xl={12} md={12} sm={12}>
                <Box sx={{ width: '100%' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="disabled tabs example">
                        <Tab label="Permissões de API" />
                        <Tab label="Permissões de Frontend" />
                    </Tabs>
                </Box>
                <br />
                <Box sx={{ display: value !== 0 ? 'none' : 'block' }}>
                    <Paper>
                        <DataGrid
                            autoHeight
                            getRowHeight={() => 'auto'}
                            rows={permissoesApi}
                            columns={columnsApi}
                            sortingOrder={['asc', 'desc']}
                            localeText={localeBRDataGridText}
                        />
                    </Paper>
                </Box>
                <Box sx={{ display: value !== 1 ? 'none' : 'block' }}>
                    <Paper>
                        <DataGrid
                            autoHeight
                            getRowHeight={() => 'auto'}
                            rows={permissoesFront}
                            columns={columnsFront}
                            sortingOrder={['asc', 'desc']}
                            localeText={localeBRDataGridText}
                        />
                    </Paper>
                </Box>
            </Grid>

        </Grid>

    </div>
}

RegisterGrupoPage.propTypes = {
    callbackName: PropTypes.func,
    callbackItems: PropTypes.func,
    name: PropTypes.string,
    permissoes: PropTypes.array,
    readOnlyName: PropTypes.bool
}

export default RegisterGrupoPage