import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import { Button, Grid, Paper, TableCell, TableRow } from "@mui/material"
import DataTable from "../data-table"
import { Line } from "react-chartjs-2"
import { Chart as ChartJS } from "chart.js/auto" // eslint-disable-line no-unused-vars
import { v4 as uuidv4 } from 'uuid'
import SquareRoundedIcon from '@mui/icons-material/SquareRounded'
import theme from "src/utils/theme"
import zoomPlugin from 'chartjs-plugin-zoom' //eslint-disable-line no-unused-vars

const MultiAxisChartComponent = (props) => {
    const [xAxis, setXAxis] = useState({
        name: "",
        values: "",
    })
    const [dataset, setDataset] = useState([])
    const [removedDataset, setRemovedDataSet] = useState([])
    const [legend, setLegend] = useState([])
    const [itemsId, setItemsId] = useState([])

    useEffect(() => {
        generateData()
        ChartJS.register(zoomPlugin)
    }, [props])

    const generateData = () => {
        console.log(props)
        setLegend([])

        setXAxis({
            name: props.xTitle ? props.xTitle : "",
            values: props.xValue
        })

        const newData = []
        const newItemsId = []

        props.values.forEach((group) => {
            const newLegend = []
            group.items.forEach((item) => {
                const secondAxis = item.id === "second-axis" ? item : null

                let yAxisId = "y"
                if (secondAxis !== null) {
                    yAxisId = "y1"
                }

                const color = generateColor()
                const id = uuidv4()
                const newValue = generateDateset(group.grupo, color, item.values, item.item, id, yAxisId)
                const newLegendValue = generateLegend(color, item.item, id)

                newData.push(newValue)
                newLegend.push(newLegendValue)
                newItemsId.push(id)
            })

            setLegend([])
            setLegend(prevLegend => [...prevLegend, { group: group.grupo, items: newLegend }])

        })

        setItemsId(newItemsId)
        setDataset(newData)
    }

    const generateLegend = (color, item, id) => {
        return {
            id: id,
            color: color,
            item: item,
            variant: "outlined"
        }
    }

    const generateColor = () => {
        const corHex = Math.floor(Math.random() * 16777215).toString(16)
        return `#${corHex}`
    }

    const generateDateset = (group, color, values, label, id, yAxisId) => {
        return {
            id: id,
            label: `${group} ${label}`,
            data: values,
            fill: false,
            tension: props.tension ? props.tension : 0.0,
            backgroundColor: color,
            borderColor: color,
            borderWidth: 2,
            pointRadius: 3,
            yAxisID: yAxisId
        }
    }

    const options = {
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: props.title,
            },
            legend: {
                display: false, // Oculta a legenda padrão
            },
            zoom: {
                zoom: {
                    wheel: {
                        enabled: true,
                    },
                    pinch: {
                        enabled: true
                    },
                    drag: {
                        enabled: true
                    },
                    mode: 'xy',
                    speed: 0.1
                }
            }
        },
        transitions: {
            zoom: {
                animation: {
                    duration: 1000,
                    easing: 'easeOutCubic'
                }
            }
        },
        scales: {
            x: {
                display: true,
                title: {
                    display: true,
                    text: xAxis.name,
                    font: {
                        weight: 'bold',
                    },
                },
            },
            y: {
                beginAtZero: false,
                display: true,
                title: {
                    display: true,
                    text: props.yTitle ? props.yTitle : "",
                    font: {
                        weight: 'bold',
                    },
                },
            },
            y1: {
                beginAtZero: false,
                display: true,
                position: 'right',
                title: {
                    display: true,
                    text: props.yRightTitle ? props.yRightTitle : "",
                    font: {
                        weight: 'bold',
                    },
                },
            },
        },
    }

    const disableEnableLine = (itemId) => {
        // selected item adding in array with removed dataset
        const item = dataset.filter((item) => item.id == itemId)

        if (item.length != 0) {
            console.log("indo remover o item")
            const datasetRemove = dataset.filter((item) => item.id !== itemId)
            const removedItem = itemsId.filter((obj) => obj !== itemId)

            setDataset(datasetRemove)
            setRemovedDataSet([...removedDataset, item[0]])
            setItemsId(removedItem)
        } else {
            const removedItem = removedDataset.filter((item) => item.id == itemId)
            const removed = removedDataset.filter((item) => item.id != itemId)

            setDataset([...dataset, removedItem[0]])
            setRemovedDataSet(removed)
            setItemsId([...itemsId, itemId])
        }
    }

    const tableBody = legend.map((row, index) =>
        <TableRow key={`table-${index}`} index={index}>
            <TableCell>{row.group}</TableCell>
            {row.items.map((item, indexItem) =>
                <TableCell key={`table-${indexItem}`}>
                    <Button
                        sx={{ color: "#000", borderColor: theme.palette.primary.main }}
                        id={item.id}
                        variant={itemsId.some((obj) => obj === item.id) ? "outlined" : ""}
                        onClick={() => {
                            disableEnableLine(item.id)
                        }}
                        startIcon={<SquareRoundedIcon sx={{ color: item.color, backgroundColor: item.color }} />}>
                        {item.item}
                    </Button>
                </TableCell>)}
        </TableRow>)

    return <Paper>
        <div id={props.id} className="chartjs-size-monitor">
            <Grid container>
                <Grid item sm={12} md={12} xl={12} xs={12}>
                    <DataTable
                        component={null}
                        head={[]}
                        body={tableBody}
                    />
                </Grid>
            </Grid>

            <Line
                height={props.height ? props.height : 70}
                data={{
                    labels: xAxis.values,
                    datasets: dataset
                }} options={options} />
        </div>
    </Paper>

}

MultiAxisChartComponent.propTypes = {
    id: PropTypes.string,
    xTitle: PropTypes.string,
    xValue: PropTypes.array,
    yTitle: PropTypes.string,
    yRightTitle: PropTypes.string,
    title: PropTypes.string,
    values: PropTypes.array,
    reloadChart: PropTypes.func,
    height: PropTypes.number,
    tension: PropTypes.number
}

export default MultiAxisChartComponent