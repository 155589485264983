import React, { useEffect, useState } from "react"
import { formatDateDataGrid } from "src/utils/format-date"
import PropTypes from 'prop-types'
import DatalakeService from "src/services/datalake-service"
import dayjs from "dayjs"
import { DATE_FORMAT } from "src/utils/constants"
import ParameterPaginationTable from "src/components/table/parameter-pagination-table"

const ParameterDatalakeFincsEventosTable = (props) => {
    const dataLakeService = new DatalakeService()
    const [data, setData] = useState([])
    const [pagination, setPagination] = useState({ count: 0, next: null, previous: null })

    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        props.loading(true)
        dataLakeService.loadPaginationEndpoint(props.endpoint).then((response) => {
            props.loading(false)

            const { body } = response
            const { results, count, next, previous } = body
            const list = results.map((obj, index) => ({ ...obj, id: index }))
            setPagination({ count: count, next: next, previous: previous })
            setData(list)
        })
    }

    const createRow = async () => {
        const body = {
            "uuid": crypto.randomUUID(),
            "cetip": "N/A",
            "fluxo_base": 0,
            "id_ativo_base": 0,
            "id_evento": 0,
            "validade_inicial": dayjs().format(DATE_FORMAT),
            "validade_final": dayjs().format(DATE_FORMAT),
            "fluxo_inicio_rentabilidade": dayjs().format(DATE_FORMAT),
            "fluxo_valor_nominal": 0,
            "fluxo_amortizacao": 0,
            "fluxo_pagamento": 0,
            "fluxo_defasagem": 0,
            "fluxo_dia_tr_emissao": 0,
            "fluxo_prorata_emissao": 0,
            "fluxo_prorata_emissao_tipo": "N/A",
            "fluxo_projecao_indice_preco": 0,
            "fluxo_correcao_inflacao_anual": 0,
            "fluxo_mes_aniversario_inflacao_anual": 0,
            "fluxo_recalculo_pu_inflacao_projetada": 0,
            "data_evento": dayjs().format(DATE_FORMAT),
            "amortizacao_percentual": 0,
            "amortizacao_ordinaria": 0,
            "amortizacao_extraordinaria": 0,
            "correcao_percentual": 0,
            "juros_incorporacao_percentual": 0,
            "juros_tipo": "N/A",
            "juros_capitalizacao": "N/A",
            "taxa_accrual": 0,
            "repactuacao": "N/A",
            "pagamento_classificacao": "N/A",
            "configuracao_correcao": false,
            "lista_configuracao_correcao": "[]",
        }

        props.loading(true)
        return dataLakeService.createTableEndpoint(props.endpoint, body).then(() => {
            props.loading(false)
            loadData(0, 25)
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const updateRow = async (newRow) => {
        const { uuid, cetip, fluxo_base, id_ativo_base, id_evento, validade_inicial, validade_final, fluxo_inicio_rentabilidade, fluxo_valor_nominal,
            fluxo_amortizacao, fluxo_pagamento, fluxo_defasagem, fluxo_dia_tr_emissao, fluxo_prorata_emissao, fluxo_prorata_emissao_tipo, fluxo_projecao_indice_preco,
            fluxo_correcao_inflacao_anual, fluxo_mes_aniversario_inflacao_anual, fluxo_recalculo_pu_inflacao_projetada, data_evento, amortizacao_percentual,
            amortizacao_ordinaria, amortizacao_extraordinaria, correcao_percentual, juros_incorporacao_percentual, juros_tipo, juros_capitalizacao, taxa_accrual,
            repactuacao, pagamento_classificacao, configuracao_correcao, lista_configuracao_correcao
        } = newRow

        const body = {
            "cetip": cetip,
            "fluxo_base": fluxo_base,
            "id_ativo_base": id_ativo_base,
            "id_evento": id_evento,
            "validade_inicial": validade_inicial,
            "validade_final": validade_final,
            "fluxo_inicio_rentabilidade": fluxo_inicio_rentabilidade,
            "fluxo_valor_nominal": fluxo_valor_nominal,
            "fluxo_amortizacao": fluxo_amortizacao,
            "fluxo_pagamento": fluxo_pagamento,
            "fluxo_defasagem": fluxo_defasagem,
            "fluxo_dia_tr_emissao": fluxo_dia_tr_emissao,
            "fluxo_prorata_emissao": fluxo_prorata_emissao,
            "fluxo_prorata_emissao_tipo": fluxo_prorata_emissao_tipo,
            "fluxo_projecao_indice_preco": fluxo_projecao_indice_preco,
            "fluxo_correcao_inflacao_anual": fluxo_correcao_inflacao_anual,
            "fluxo_mes_aniversario_inflacao_anual": fluxo_mes_aniversario_inflacao_anual,
            "fluxo_recalculo_pu_inflacao_projetada": fluxo_recalculo_pu_inflacao_projetada,
            "data_evento": data_evento,
            "amortizacao_percentual": amortizacao_percentual,
            "amortizacao_ordinaria": amortizacao_ordinaria,
            "amortizacao_extraordinaria": amortizacao_extraordinaria,
            "correcao_percentual": correcao_percentual,
            "juros_incorporacao_percentual": juros_incorporacao_percentual,
            "juros_tipo": juros_tipo,
            "juros_capitalizacao": juros_capitalizacao,
            "taxa_accrual": taxa_accrual,
            "repactuacao": repactuacao,
            "pagamento_classificacao": pagamento_classificacao,
            "configuracao_correcao": configuracao_correcao,
            "lista_configuracao_correcao": lista_configuracao_correcao,
        }

        props.loading(true)
        return dataLakeService.updateTableEndpoint(props.endpoint, uuid, body).then(() => {
            props.loading(false)
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const deleteRow = async (row) => {
        props.loading(true)
        const { uuid } = row
        return dataLakeService.deleteTableEndpoint(props.endpoint, uuid).then(() => {
            props.loading(false)
            const listRemoved = data.filter((item) => item.uuid !== uuid)
            setData(listRemoved)
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const onFilter = async (filterList, limit, offset) => {
        const query = filterList.map(obj => `${encodeURIComponent(obj.field)}=${encodeURIComponent(obj.value)}`).join('&')

        props.loading(true)
        dataLakeService.loadPaginationEndpoint(props.endpoint, query, limit, offset).then((response) => {
            props.loading(false)
            const { body } = response
            const { results, count, next, previous } = body
            const list = results.map((obj, index) => ({ ...obj, id: index }))
            setPagination({ count: count, next: next, previous: previous })
            setData(list)
        })
    }

    const changePage = async (endpoint) => {
        props.loading(true)
        dataLakeService.paginate(endpoint).then((response) => {
            props.loading(false)
            const { body } = response
            const { results, count, previous, next } = body
            const list = results.map((obj, index) => ({ ...obj, id: index }))

            setPagination({ count: count, next: next, previous: previous })
            setData([])
            setData(list)
        })
    }

    const columns = [
        {
            field: 'uuid',
            headerName: '#',
            disableColumnMenu: true,
            disableFilter: true,
            flex: true,
            minWidth: 80,
        },
        {
            field: 'cetip',
            headerName: 'cetip',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'fluxo_base',
            headerName: 'fluxo_base',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'id_ativo_base',
            headerName: 'id_ativo_base',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'id_evento',
            headerName: 'id_evento',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'validade_inicial',
            headerName: 'validade_inicial',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'date',
            // type: 'date',
            // valueGetter: ({ value }) => value && dayjs(value).toDate(),
            // valueFormatter: params => dayjs(params.value).format(DATE_FORMAT),
            // valueParser: (newValue) => dayjs(newValue).format(DATE_FORMAT),
            disableColumnMenu: true,
            editable: true,
        },
        {
            field: 'validade_final',
            headerName: 'validade_final',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'date',
            // type: 'date',
            // valueGetter: ({ value }) => value && dayjs(value).toDate(),
            // valueFormatter: params => dayjs(params.value).format(DATE_FORMAT),
            // valueParser: (newValue) => dayjs(newValue).format(DATE_FORMAT),
            disableColumnMenu: true,
            editable: true,
        },
        {
            field: 'fluxo_inicio_rentabilidade',
            headerName: 'fluxo_inicio_rentabilidade',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'date',
            // type: 'date',
            // valueGetter: ({ value }) => value && dayjs(value).toDate(),
            // valueFormatter: params => dayjs(params.value).format(DATE_FORMAT),
            // valueParser: (newValue) => dayjs(newValue).format(DATE_FORMAT),
            disableColumnMenu: true,
            editable: true,
        },
        {
            field: 'fluxo_valor_nominal',
            headerName: 'fluxo_valor_nominal',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'fluxo_amortizacao',
            headerName: 'fluxo_amortizacao',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'fluxo_pagamento',
            headerName: 'fluxo_pagamento',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'fluxo_defasagem',
            headerName: 'fluxo_defasagem',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'fluxo_dia_tr_emissao',
            headerName: 'fluxo_dia_tr_emissao',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'fluxo_prorata_emissao',
            headerName: 'fluxo_prorata_emissao',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'fluxo_prorata_emissao_tipo',
            headerName: 'fluxo_prorata_emissao_tipo',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'fluxo_projecao_indice_preco',
            headerName: 'fluxo_projecao_indice_preco',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'fluxo_correcao_inflacao_anual',
            headerName: 'fluxo_correcao_inflacao_anual',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'fluxo_mes_aniversario_inflacao_anual',
            headerName: 'fluxo_mes_aniversario_inflacao_anual',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'fluxo_recalculo_pu_inflacao_projetada',
            headerName: 'fluxo_recalculo_pu_inflacao_projetada',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'data_evento',
            headerName: 'data_evento',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'date',
            // type: 'date',
            // valueGetter: ({ value }) => value && dayjs(value).toDate(),
            // valueFormatter: params => dayjs(params.value).format(DATE_FORMAT),
            // valueParser: (newValue) => dayjs(newValue).format(DATE_FORMAT),
            disableColumnMenu: true,
            editable: true,
        },
        {
            field: 'amortizacao_percentual',
            headerName: 'amortizacao_percentual',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'amortizacao_ordinaria',
            headerName: 'amortizacao_ordinaria',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'amortizacao_extraordinaria',
            headerName: 'amortizacao_extraordinaria',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'correcao_percentual',
            headerName: 'correcao_percentual',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'juros_incorporacao_percentual',
            headerName: 'juros_incorporacao_percentual',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'juros_tipo',
            headerName: 'juros_tipo',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'juros_capitalizacao',
            headerName: 'juros_capitalizacao',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'taxa_accrual',
            headerName: 'taxa_accrual',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'repactuacao',
            headerName: 'repactuacao',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'pagamento_classificacao',
            headerName: 'pagamento_classificacao',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'configuracao_correcao',
            headerName: 'configuracao_correcao',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'lista_configuracao_correcao',
            headerName: 'lista_configuracao_correcao',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'criado_em',
            headerName: 'Data de Criação',
            flex: true,
            minWidth: 150,
            disableColumnMenu: true,
            disableFilter: true,
            renderCell: (params) => formatDateDataGrid(params)
        },
        {
            field: 'atualizado_em',
            headerName: 'Data de Atualização',
            flex: true,
            minWidth: 150,
            disableColumnMenu: true,
            disableFilter: true,
            renderCell: (params) => formatDateDataGrid(params)
        }
    ]

    return (
        <div>
            <ParameterPaginationTable
                columns={columns}
                data={data}
                setData={setData}
                createRow={createRow}
                deleteRow={deleteRow}
                updateRow={updateRow}
                filterFunction={onFilter}
                pagination={pagination}
                onChangePage={changePage}
                customFilter={true} />
        </div>
    )
}

ParameterDatalakeFincsEventosTable.propTypes = {
    loading: PropTypes.func,
    endpoint: PropTypes.string,
}

export default ParameterDatalakeFincsEventosTable
