import React, { useEffect, useState } from "react"
import { formatDateDataGrid } from "src/utils/format-date"
import PropTypes from 'prop-types'
import ParameterPaginationTable from "src/components/table/parameter-pagination-table"
import DatalakeService from "src/services/datalake-service"
import dayjs from "dayjs"
import { DATE_FORMAT } from "src/utils/constants"

const ParameterDatalakeSpreadsNegociadosTable = (props) => {
    const dataLakeService = new DatalakeService()
    const [data, setData] = useState([])
    const [pagination, setPagination] = useState({ count: 0, next: null, previous: null })

    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        props.loading(true)
        dataLakeService.loadPaginationEndpoint(props.endpoint).then((response) => {
            props.loading(false)

            const { body } = response
            const { results, count, next, previous } = body
            const list = results.map((obj, index) => ({ ...obj, id: index }))
            setPagination({ count: count, next: next, previous: previous })
            setData(list)
        })
    }

    const createRow = async () => {
        const body = {
            "CETIP": "N/A",
            "DataReferencia": dayjs().format(DATE_FORMAT),
            "IDRiskFree": 0,
            "IDCurvaPRE": 0,
            "QtNegociada": 0,
            "CodNegocio": "N/A",
            "SituacaoNegocio": "N/A",
            "Indicador": "N/A",
            "PreviaInflacao": 0,
            "DataUltimoRating": dayjs().format(DATE_FORMAT),
            "DataRatingVigente": dayjs().format(DATE_FORMAT),
            "StatusRating": "N/A",
            "AliquotaIR": 0,
            "FatorIR": 0,
            "TaxaAccrual": 0,
            "DurationAccrual": 0,
            "PU_MTM": 0,
            "TaxaMTM": 0,
            "PUPAR": 0,
            "PercentualPUPAR": 0,
            "DurationMTM": 0,
            "TaxaRiskFree": 0,
            "TaxaCurvaRating": 0,
            "TIR_ComIR": 0,
            "TIR_SemIR": 0,
            "TIR_Original": 0,
            "SpreadCurvaRating": 0,
            "SpreadCreditoComIR": 0,
            "SpreadCreditoSemIR": 0,
            "SpreadCreditoFinal": 0,
            "SpreadRelativoComIR": 0,
            "SpreadRelativoSemIR": 0,
            "SpreadRelativoFinal": 0,
            "Fonte": "N/A",
            "LOGERRO": "N/A"
        }

        props.loading(true)
        return dataLakeService.createTableEndpoint(props.endpoint, body).then(() => {
            props.loading(false)
            loadData()
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const updateRow = async (newRow) => {
        const { ID, CETIP, DataReferencia, IDRiskFree, IDCurvaPRE, QtNegociada, CodNegocio, SituacaoNegocio, Indicador,
            PreviaInflacao, DataRatingVigente, DataUltimoRating, StatusRating, AliquotaIR, FatorIR, TaxaAccrual, DurationAccrual,
            PU_MTM, TaxaMTM, PUPAR, PercentualPUPAR, DurationMTM, TaxaRiskFree, TaxaCurvaRating, TIR_ComIR, TIR_SemIR,
            TIR_Original, SpreadCurvaRating, SpreadCreditoComIR, SpreadCreditoFinal, SpreadRelativoComIR,
            SpreadCreditoSemIR, SpreadRelativoFinal, Fonte, LOGERRO } = newRow

        const body = {
            "CETIP": CETIP,
            "DataReferencia": DataReferencia,
            "IDRiskFree": IDRiskFree,
            "IDCurvaPRE": IDCurvaPRE,
            "QtNegociada": QtNegociada,
            "CodNegocio": CodNegocio,
            "SituacaoNegocio": SituacaoNegocio,
            "Indicador": Indicador,
            "PreviaInflacao": PreviaInflacao,
            "DataUltimoRating": DataUltimoRating,
            "DataRatingVigente": DataRatingVigente,
            "StatusRating": StatusRating,
            "AliquotaIR": AliquotaIR,
            "FatorIR": FatorIR,
            "TaxaAccrual": TaxaAccrual,
            "DurationAccrual": DurationAccrual,
            "PU_MTM": PU_MTM,
            "TaxaMTM": TaxaMTM,
            "PUPAR": PUPAR,
            "PercentualPUPAR": PercentualPUPAR,
            "DurationMTM": DurationMTM,
            "TaxaRiskFree": TaxaRiskFree,
            "TaxaCurvaRating": TaxaCurvaRating,
            "TIR_ComIR": TIR_ComIR,
            "TIR_SemIR": TIR_SemIR,
            "TIR_Original": TIR_Original,
            "SpreadCurvaRating": SpreadCurvaRating,
            "SpreadCreditoComIR": SpreadCreditoComIR,
            "SpreadCreditoSemIR": SpreadCreditoSemIR,
            "SpreadCreditoFinal": SpreadCreditoFinal,
            "SpreadRelativoComIR": SpreadRelativoComIR,
            "SpreadRelativoFinal": SpreadRelativoFinal,
            "Fonte": Fonte,
            "LOGERRO": LOGERRO
        }

        props.loading(true)
        return dataLakeService.updateTableEndpoint(props.endpoint, ID, body).then(() => {
            props.loading(false)
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const deleteRow = async (row) => {
        props.loading(true)
        const { ID } = row
        return dataLakeService.deleteTableEndpoint(props.endpoint, ID).then(() => {
            props.loading(false)
            const listRemoved = data.filter((item) => item.ID !== ID)
            setData(listRemoved)
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const onFilter = async (filterList, limit, offset) => {
        const query = filterList.map(obj => `${encodeURIComponent(obj.field)}=${encodeURIComponent(obj.value)}`).join('&')

        props.loading(true)
        dataLakeService.loadPaginationEndpoint(props.endpoint, query, limit, offset).then((response) => {
            props.loading(false)
            const { body } = response
            const { results, count, next, previous } = body
            const list = results.map((obj) => ({ ...obj, id: obj.ID }))
            setPagination({ count: count, next: next, previous: previous })
            setData(list)
        })
    }

    const changePage = async (endpoint) => {
        props.loading(true)
        dataLakeService.paginate(endpoint).then((response) => {
            props.loading(false)
            const { body } = response
            const { results, count, previous, next } = body
            const list = results.map((obj) => ({ ...obj, id: obj.ID }))

            setPagination({ count: count, next: next, previous: previous })
            setData([])
            setData(list)
        })
    }

    const columns = [
        {
            field: 'ID',
            headerName: '#',
            disableColumnMenu: true,
            disableFilter: true,
            flex: true,
            minWidth: 80,
        },
        {
            field: 'CETIP',
            headerName: 'CETIP',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'DataReferencia',
            headerName: 'DataReferencia',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'date',
            disableColumnMenu: true,
            editable: true,
        },
        {
            field: 'IDRiskFree',
            headerName: 'IDRiskFree',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'IDCurvaPRE',
            headerName: 'IDCurvaPRE',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'QtNegociada',
            headerName: 'QtNegociada',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'CodNegocio',
            headerName: 'CodNegocio',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'SituacaoNegocio',
            headerName: 'SituacaoNegocio',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Indicador',
            headerName: 'Indicador',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'PreviaInflacao',
            headerName: 'PreviaInflacao',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'DataUltimoRating',
            headerName: 'DataUltimoRating',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'date',
            disableColumnMenu: true,
            editable: true,
        },
        {
            field: 'DataRatingVigente',
            headerName: 'DataRatingVigente',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'date',
            disableColumnMenu: true,
            editable: true,
        },
        {
            field: 'StatusRating',
            headerName: 'StatusRating',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },

        {
            field: 'AliquotaIR',
            headerName: 'AliquotaIR',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'FatorIR',
            headerName: 'FatorIR',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'TaxaAccrual',
            headerName: 'TaxaAccrual',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'DurationAccrual',
            headerName: 'DurationAccrual',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'PU_MTM',
            headerName: 'PU_MTM',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'TaxaMTM',
            headerName: 'TaxaMTM',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'PUPAR',
            headerName: 'PUPAR',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'PercentualPUPAR',
            headerName: 'PercentualPUPAR',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'DurationMTM',
            headerName: 'DurationMTM',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'TaxaRiskFree',
            headerName: 'TaxaRiskFree',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'TaxaCurvaRating',
            headerName: 'TaxaCurvaRating',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'TIR_ComIR',
            headerName: 'TIR_ComIR',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'TIR_SemIR',
            headerName: 'TIR_SemIR',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'TIR_Original',
            headerName: 'TIR_Original',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'SpreadCurvaRating',
            headerName: 'SpreadCurvaRating',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'SpreadCreditoComIR',
            headerName: 'SpreadCreditoComIR',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'SpreadCreditoSemIR',
            headerName: 'SpreadCreditoSemIR',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'SpreadCreditoFinal',
            headerName: 'SpreadCreditoFinal',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'SpreadRelativoComIR',
            headerName: 'SpreadRelativoComIR',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'SpreadRelativoSemIR',
            headerName: 'SpreadRelativoSemIR',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'SpreadRelativoFinal',
            headerName: 'SpreadRelativoFinal',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Fonte',
            headerName: 'Fonte',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'LOGERRO',
            headerName: 'LOGERRO',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'CriadoEm',
            headerName: 'Data de Criação',
            flex: true,
            minWidth: 150,
            disableColumnMenu: true,
            disableFilter: true,
            renderCell: (params) => formatDateDataGrid(params)
        },
        {
            field: 'AtualizadoEm',
            headerName: 'Data de Atualização',
            flex: true,
            minWidth: 150,
            disableColumnMenu: true,
            disableFilter: true,
            renderCell: (params) => formatDateDataGrid(params)
        }
    ]

    return (
        <div>
            <ParameterPaginationTable
                columns={columns}
                data={data}
                setData={setData}
                createRow={createRow}
                deleteRow={deleteRow}
                updateRow={updateRow}
                filterFunction={onFilter}
                pagination={pagination}
                onChangePage={changePage}
                customFilter={true} />
        </div>
    )
}

ParameterDatalakeSpreadsNegociadosTable.propTypes = {
    loading: PropTypes.func,
    endpoint: PropTypes.string,
}

export default ParameterDatalakeSpreadsNegociadosTable
