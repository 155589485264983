import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import CalcService from "src/services/calc-service"
import ParameterTable from "src/components/table/parameter-table"
import { DATE_TIME_FORMAT_SHOW } from "src/utils/constants"
import dayjs from "dayjs"
import { formatDateUtc } from "src/utils/date-format"

const ParameterRegrasDurasOutliersTable = (props) => {
    const calcService = new CalcService()
    const [data, setData] = useState([])

    useEffect(() => {
        loadData()
    }, [])

    
    const loadData = () => {
        props.loading(true)
        calcService.loadEndpoint(props.endpoint).then((response) => {
            props.loading(false)
            const { body } = response
            const { results } = body
            const list = results.map((obj, index) => ({ ...obj, id: index }))

            setData(list)
        })
    }

    const createRow = async () => {
        const body = {
            'GrupoSpread': "N/A",
            'DataReferencia': null,
            'Rating': "N/A",
            'DU_Min': 0,
            'DU_Max': 0,
            'LimiteInf': 0.00,
            'LimiteSup': 0.00,
        }

        props.loading(true)
        return calcService.createTableEndpoint(props.endpoint, body).then(() => {
            props.loading(false)
            loadData()
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const updateRow = async (newRow) => {
        const { ID, GrupoSpread, DataReferencia, Rating, DU_Min, DU_Max, LimiteInf, LimiteSup } = newRow

        const body = {
            'GrupoSpread': GrupoSpread,
            'DataReferencia': DataReferencia,
            'Rating': Rating,
            'DU_Min': DU_Min,
            'DU_Max': DU_Max,
            'LimiteInf': LimiteInf,
            'LimiteSup': LimiteSup,
        }

        props.loading(true)
        return calcService.updateTableEndpoint(props.endpoint, ID, body).then(() => {
            props.loading(false)
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const deleteRow = async (row) => {
        const { ID } = row
        return calcService.deleteTableEndpoint(props.endpoint, ID).then(() => {
            props.loading(false)
            loadData()
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const columns = [
        {
            field: 'ID',
            headerName: '#',
            flex: true,
        },
        {
            field: 'GrupoSpread',
            headerName: 'GrupoSpread',
            flex: 1,
            editable: true
        },
        {
            field: 'DataReferencia',
            headerName: 'DataReferencia',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'date',
            disableColumnMenu: true,
            editable: true,
        },
        {
            field: 'Rating',
            headerName: 'Rating',
            flex: 1,
            editable: true
        },
        {
            field: 'DU_Min',
            headerName: 'DU_Min',
            flex: 1,
            editable: true
        },
        {
            field: 'DU_Max',
            headerName: 'DU_Max',
            flex: 1,
            editable: true
        },
        {
            field: 'LimiteInf',
            headerName: 'LimiteInf',
            flex: 1,
            editable: true
        },
        {
            field: 'LimiteSup',
            headerName: 'LimiteSup',
            flex: 1,
            editable: true
        },
        {
            field: 'CriadoEm',
            headerName: 'Data de Criação',
            flex: true,
            minWidth: 150,
            type: 'date',
            valueGetter: ({ value }) => value && dayjs(value).toDate(),
            valueFormatter: params => formatDateUtc(params.value, DATE_TIME_FORMAT_SHOW),
        },
        {
            field: 'AtualizadoEm',
            headerName: 'Data de Atualização',
            flex: true,
            minWidth: 150,
            type: 'date',
            valueGetter: ({ value }) => value && dayjs(value).toDate(),
            valueFormatter: params => formatDateUtc(params.value, DATE_TIME_FORMAT_SHOW),
        }
    ]

    return (
        <div>
            <ParameterTable
                columns={columns}
                data={data}
                setData={setData}
                createRow={createRow}
                deleteRow={deleteRow}
                updateRow={updateRow}
                loadData={loadData} />
        </div>
    )
}

ParameterRegrasDurasOutliersTable.propTypes = {
    loading: PropTypes.func,
    endpoint: PropTypes.string,
}

export default ParameterRegrasDurasOutliersTable
