import { Paper } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import React from "react"

const TableDocumentos = () => {
    return <>
        <Paper>
            <DataGrid
                columns={[
                    { headerName: "Nome Documento", field: "Nome", flex: true, },
                    { headerName: "Abrir", field: "Abrir", flex: true, },
                    { headerName: "Download", field: "Tipo", flex: true, },
                    { headerName: "Deletar", field: "Data", flex: true, },
                ]}
                rows={[]}
            />
        </Paper>
    </>
}

export default TableDocumentos