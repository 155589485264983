import React from "react"
import { Chip, FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import PropTypes from 'prop-types'

const MultiSelect = (props) => {

    return (
        <FormControl sx={{ minWidth: 120 }} variant="standard" fullWidth>
            <InputLabel id="multiselect-label">{props.title}</InputLabel>
            <Select
                labelId="multiselect-label"
                id="multiselect"
                multiple
                value={props.selectedValues}
                onChange={props.handleChange}
                renderValue={(selected) => (
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {selected.map((value) => (
                            <Chip
                                style={{ margin: 2 }}
                                key={value}
                                label={props.options.find(option => option.value === value).label} />
                        ))}
                    </div>
                )}
            >
                {props.options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

MultiSelect.propTypes = {
    options: PropTypes.array,
    selectedValues: PropTypes.array,
    handleChange: PropTypes.array,
    title: PropTypes.string
}

export default MultiSelect