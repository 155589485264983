import { RESET_PASSWORD_CONFIRM_PATH, RESET_PASSWORD_PATH, TOKEN_PATH, USERS_ME_PATH } from "src/utils/api-routes"
import { JWT_STORAGE_KEY, LOGIN_DATE, PERMISSIONS_STORAGE_KEY, REFRESH_JWT_STORAGE_KEY } from "src/utils/constants"
import { ServiceRequest } from "src/utils/service-request"
import StorageService from "./storage-service"
import { TokenService } from "./token-service"

class AuthService {

    constructor() {
        this.request = new ServiceRequest()
        this.storage = new StorageService()
        this.tokenService = new TokenService()
    }

    async login(email, password) {
        const authorization = 'Basic ' + btoa(`${email}:${password}`)

        await this.request.post(TOKEN_PATH, null, true, authorization)
            .then(async (response) => {
                const { access, refresh } = response.body
                this.storage.saveItemLocalStorage(JWT_STORAGE_KEY, `Bearer ${access}`)
                this.storage.saveItemLocalStorage(REFRESH_JWT_STORAGE_KEY, refresh)
                this.storage.saveItemLocalStorage(LOGIN_DATE, new Date().toISOString())

                await this.request.get(`${USERS_ME_PATH}/permissoes`).then((response) => {
                    const { body } = response
                    const { frontend } = body
                    this.storage.saveItemLocalStorage(PERMISSIONS_STORAGE_KEY, JSON.stringify(frontend))
                })
            })
            .catch((err) => {
                throw new Error(err)
            })
    }

    async resetPassword(email) {
        const body = {
            "email": email
        }

        await this.request.post(RESET_PASSWORD_PATH, body)
            .then((response) => {
                return response
            })
            .catch((err) => {
                throw new Error(err)
            })
    }

    async registerNewPassword(uid, token, password) {
        const body = {
            "uid": uid,
            "token": token,
            "new_password": password
        }

        await this.request.post(RESET_PASSWORD_CONFIRM_PATH, body)
            .then((response) => {
                return response
            })
            .catch((err) => {
                throw err
            })
    }

    logout() {
        this.storage.clearAllStorage()
    }

}

export default AuthService