import React, { useState } from "react"
import PropTypes from 'prop-types'
import { Button, Grid, Paper } from "@mui/material"
import { DataGrid, GridCellModes, GridToolbarContainer } from "@mui/x-data-grid"
import ConfirmDialog from "src/components/confirm-dialog"
import { localeBRDataGridText } from "src/utils/data-grid-local-text"
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import AlertMessage from "src/components/alert-message"
import AddIcon from "@mui/icons-material/Add"
import { replaceEmptyWithNull } from "src/utils/format-json"

const ParameterTable = (props) => {
    const [cellModesModel, setCellModesModel] = useState({})
    const [rowModesModel, setRowModesModel] = useState({})
    const [dialogDelete, setDialogDelete] = useState(false)
    const [rowDelete, setRowDelete] = useState(null)
    const [alertMessageState, setAlertMessageState] = React.useState({
        open: false,
        message: "",
        type: "info"
    })

    const deleteColumn = {
        field: 'delete',
        headerName: 'Excluir',
        flex: true,
        renderCell: (params) => (
            <Button onClick={() => openDeleteRow(params)}>
                <DeleteRoundedIcon />
            </Button>
        ),
        minWidth: 100,
    }

    const columns = [...props.columns, deleteColumn]

    const closeAlert = () => {
        setAlertMessageState({ ...alertMessageState, open: false })
    }

    const openAlert = (message, type) => {
        setAlertMessageState({
            ...alertMessageState,
            open: true,
            message: message,
            type: type
        })
    }

    const deleteRow = () => {
        props.deleteRow(rowDelete).then(() => {
            openAlert("Registro Excluído", "error")
        }).catch(() => {
            openAlert("Erro ao tentar excluir o registro", "error")
        })
    }

    const processRowUpdate = (newRow, oldRow) => {
        const stringifyRow = (row) => JSON.stringify(Object.entries(row).sort())
        const areRowsEqual = stringifyRow(newRow) === stringifyRow(oldRow)
        if (areRowsEqual) return newRow

        let row = replaceEmptyWithNull(newRow)

        props.updateRow(row).then(() => {
            openAlert("Atualizado com sucesso", "success")
        }).catch((err) => {
            try {
                const json = JSON.stringify(err)
                const { response } = JSON.parse(json)
                const { body } = response
                openAlert(`Erro ao tentar atualizar - ${JSON.stringify(body)}`, "error")

            } catch (error) {
                openAlert("Erro ao tentar atualizar", "error")
            }
        })

        return row
    }

    const onProcessRowUpdateError = () => {
        openAlert("Erro ao tentar atualizar", "error")
    }

    const openDeleteRow = (params) => {
        const { row } = params
        setRowDelete(row)
        setDialogDelete(true)
    }

    const toolbarComponent = () => {
        const { createRow } = props

        if (!createRow) return null

        const createNewRow = () => {
            createRow()
                .then(() => {
                    openAlert("Registro Adicionado", "success")
                }).catch((err) => {
                     try {
                        const json = JSON.stringify(err)
                        const { response } = JSON.parse(json)
                        const { body } = response
                        openAlert(`Erro ao adicionar o registro - ${JSON.stringify(body)}`, "error")
        
                    } catch (error) {
                        openAlert("Erro ao adicionar o registro", "error")
                    }
                })
        }

        return (
            <GridToolbarContainer>
                <Button color="primary" startIcon={<AddIcon />} onClick={() => createNewRow()}>
                    Adicionar Novo
                </Button>
            </GridToolbarContainer>
        )
    }

    const handleCellEditStop = (
        params,
        event
    ) => {
        const { id, field } = params

        event.defaultMuiPrevented = true
        setCellModesModel({
            ...cellModesModel,
            [id]: { ...cellModesModel[id], [field]: { mode: GridCellModes.View } },
        })
    }

    return (
        <div>
            <AlertMessage open={alertMessageState.open} message={alertMessageState.message} type={alertMessageState.type} close={closeAlert} />

            <Grid container direction={"row"} alignContent={"center"} justifyContent={"flex-end"} spacing={2}></Grid>
            <br />
            <Paper>
                <DataGrid
                    autoHeight
                    rows={props.data}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    pageSizeOptions={[10, 25, 50, 100]}
                    disableRowSelectionOnClick
                    cellModesModel={cellModesModel}
                    sortingOrder={['asc', 'desc']}
                    localeText={localeBRDataGridText}

                    onCellEditStop={handleCellEditStop}
                    onCellModesModelChange={(model) => setCellModesModel(model)}

                    rowModesModel={rowModesModel}
                    processRowUpdate={processRowUpdate}
                    onProcessRowUpdateError={onProcessRowUpdateError}
                    handleProcessRowUpdateError={onProcessRowUpdateError}
                    slots={{
                        toolbar: toolbarComponent,
                    }}
                    slotProps={{
                        toolbar: {
                            setData: props.setData,
                            setRowModesModel: setRowModesModel
                        }
                    }}
                />
            </Paper>

            <ConfirmDialog
                open={dialogDelete}
                title={"Deseja remover esse registro?"}
                onConfirm={() => deleteRow()}
                onClose={() => setDialogDelete(false)}>
                <span>Essa ação não pode ser desfeita.</span>
            </ConfirmDialog>
        </div >
    )
}

ParameterTable.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    setData: PropTypes.func,
    loadData: PropTypes.func,
    createRow: PropTypes.func,
    updateRow: PropTypes.func,
    deleteRow: PropTypes.func
}


export default ParameterTable