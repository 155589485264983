import { Button, Grid, Paper } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import DatePickerSelect from "src/components/date/date-picker-select"
import LayoutPage from "src/components/page-layout/layout-page"
import PriceService from "src/services/price-service"
import { DATE_FORMAT, DATE_FORMAT_SHOW } from "src/utils/constants"
import { localeBRDataGridText } from "src/utils/data-grid-local-text"
import { formatStringToFloatDecimalCase } from "src/utils/format-decimal"


const PriceTablePage = () => {
    const priceService = new PriceService()
    const [loading, setLoading] = useState(false)
    const [dateSelected, setDateSelected] = useState(null)
    const [priceList, setPriceList] = useState([])

    useEffect(() => {
        const date = dayjs()
        const dateFormatted = date.format(DATE_FORMAT)
        setDateSelected(dateFormatted)
        loadLastDate()
    }, [])

    const exportFile = () => {
        priceService.exportDataToCsv(dateSelected, dateSelected)
    }

    const loadLastDate = () => {
        setLoading(true)
        priceService.loadLastDate().then((response) => {
            const { body } = response
            const { data } = body

            const date = dayjs(data).format(DATE_FORMAT)
            loadPrices(date)
        }).catch(() => { setLoading(false) })

    }

    const loadPrices = (date) => {
        setDateSelected(date)
        setLoading(true)
        priceService.getPrices(date, date)
            .then((response) => {
                setLoading(false)
                const { body } = response
                const { data } = body
                const rows = data.map((obj, index) => ({ ...obj, id: index }))
                setPriceList(rows)
            }).catch(() => {
                setLoading(false)
            })
    }

    const onChangeDatePickerDate = (value) => {
        loadPrices(value.format(DATE_FORMAT))
    }

    const renderCellWithTooltip = (params) => {
        const { value } = params

        return (
            <div title={value}>
                {value}
            </div>
        )
    }

    const formatValue = (params, minimum, maximum) => {
        const { value } = params

        return formatStringToFloatDecimalCase(value, minimum, maximum)
    }

    const columns = [
        {
            field: 'DataReferencia',
            headerName: 'Data Referência',
            minWidth: 150,
            type: 'date',
            valueGetter: () => dateSelected && dayjs(dateSelected).toDate(),
            valueFormatter: () => dayjs(dateSelected).format(DATE_FORMAT_SHOW),
        },
        {
            field: 'CETIP',
            headerName: 'CETIP',
            flex: true,
            minWidth: 250,
            renderCell: renderCellWithTooltip,
        },
        {
            field: 'tipo_ativo',
            headerName: 'Tipo',
            flex: 1,
        },
        {
            field: 'devedor',
            headerName: 'Devedor',
            flex: 1,
        },
        {
            field: 'indicador',
            headerName: 'Indicador',
            flex: 1,
        },
        {
            field: 'taxa_accrual',
            headerName: 'Taxa ACCRUAL',
            flex: 1,
            renderCell: (value) => formatValue(value),
            minWidth: 150,
        },
        {
            field: 'TaxaMTM',
            headerName: 'Taxa MTM',
            flex: 1,
            renderCell: (value) => formatValue(value),
        },
        {
            field: 'PU_Inf',
            headerName: 'PU MIN',
            flex: 1,
            renderCell: (value) => formatValue(value),
        },
        {
            field: 'PU_MTM',
            headerName: 'PU MTM',
            flex: 1,
            renderCell: (value) => formatValue(value),
        },
        {
            field: 'PU_Sup',
            headerName: 'PU MAX',
            flex: 1,
            renderCell: (value) => formatValue(value),
        },
        {
            field: 'PUPAR',
            headerName: 'PUPAR',
            flex: 1,
            renderCell: (value) => formatValue(value),
        },
        {
            field: 'PercentualPUPAR',
            headerName: '%PUPAR',
            flex: 1,
            renderCell: (value) => formatValue(value),
        },
        {
            field: 'DurationMTM',
            headerName: 'Duration DU',
            flex: 1,
            renderCell: (value) => formatValue(value),
        },
        {
            field: 'DurationAno',
            headerName: 'Duration Ano',
            flex: 1,
            renderCell: (value) => formatValue(value),
        },

    ]

    return (
        <LayoutPage
            loading={loading}
            title="Tabela de PU/TIR">
            <div>
                <Grid container direction="row" alignItems={"center"} justifyContent="flex-end" spacing={3}>
                    <Grid item>
                        <DatePickerSelect value={dateSelected} label="Data de Exibição" onChange={onChangeDatePickerDate} />
                    </Grid>
                    <Grid item>
                        <Button variant="contained" onClick={exportFile}>
                            Exportar para CSV
                        </Button>
                    </Grid>
                </Grid>
                <br />
                <Grid container direction="row" justifyContent="center" spacing={2}>
                    <Grid item xl={12} md={12} xs={12}>
                        <Paper>
                            <DataGrid
                                autoHeight
                                rows={priceList}
                                columns={columns}
                                pageSize={25}
                                disableRowSelectionOnClick
                                sortingOrder={['asc', 'desc']}
                                localeText={localeBRDataGridText}
                            />
                        </Paper>

                    </Grid>
                </Grid>

            </div>


        </LayoutPage>
    )
}

export default PriceTablePage