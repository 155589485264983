import { Box, Grid } from "@mui/material"
import React, { useState } from "react"
import LayoutPage from "src/components/page-layout/layout-page"
import SelectValue from "src/components/select-value"
import { parameterDatalakeEndpoint, parameterDatalakeTableList } from "./parameter-datalake-data"
import ParameterDatalakeContraparteTable from "./tables/parameter-datalake-contraparte-table"
import ParameterAliasNomeDevedorTable from "./tables/parameter-datalake-alias-nome-devedor-table"
import ParameterDatalakeRatingDevedorTable from "./tables/parameter-datalake-rating-devedor-table"
import ParameterDatalakePrecosProcessadosTable from "./tables/parameter-datalake-precos-processados-table"
import ParameterDatalakePrecosSpreadsIndicativosTable from "./tables/parameter-datalake-precos-spreads-indicativos-table"
import ParameterDatalakePrecosTunelTable from "./tables/parameter-datalake-precos-tunel-table"
import ParameterDatalakeRatingEmissaoTable from "./tables/parameter-datalake-rating-emissao-table"
import ParameterDatalakePrecosSpreadsRelativosTable from "./tables/parameter-datalake-precos-spreads-relativos-table"
import ParameterDatalakeCVMFatosRelevantesTable from "./tables/parameter-datalake-cvm-fatos-relevantes-table"
import ParameterDatalakeLiquidezTable from "./tables/parameter-datalake-liquidez-table"
import ParameterDatalakeB3AtivosRegTable from "./tables/parameter-datalake-b3-ativos-reg-table"
import ParameterDatalakeB3NegConsolidadoTable from "./tables/parameter-datalake-b3-neg-consolidado-table"
import ParameterDatalakeB3NegAnaliticoTable from "./tables/parameter-datalake-b3-neg-analitico-table"
import ParameterDatalakeFincsMarketDataTable from "./tables/parameter-datalake-fincs-marketdata-table "
import ParameterDatalakeFincsJurosTable from "./tables/parameter-datalake-fincs-juros-table "
import ParameterDatalakeFincsEventosTable from "./tables/parameter-datalake-fincs-eventos-table "
import ParameterDatalakeSpreadsNegociadosTable from "./tables/parameter-datalake-spreads-negociados"
import ParameterDatalakeTaxaJurosTable from "./tables/parameter-datalake-taxa-juros-table"
import ParameterDatalakeRiskFreeTable from "./tables/parameter-datalake-risk-free-table"
import ParameterDatalakeSpreadsEmissaoTable from "./tables/parameter-datalake-spreads-emissao-table"
import ParameterDatalakeSpreadsIfNegTable from "./tables/parameter-datalake-spreads-if-neg-table"
import ParameterDatalakeCurvasSpreadsIndicativosTable from "./tables/parameter-datalake-curvas-spreads-indicativos-table"
import ParameterDatalakeCalendarioInflacaoTable from "./tables/parameter-datalake-calendario-inflacao-table"
import ParameterDatalakePrecosAndadosTable from "./tables/parameter-datalake-precos-andados-table"
import ParameterDatalakeEmissaoCaracteristicasTable from "./tables/parameter-datalake-emissao-caracteristicas"
import ParameterDatalakeEmissaoLTVTable from "./tables/parameter-datalake-emissao-ltv"
import ParameterDatalakeAtualizacaoIndicesTable from "./tables/parameter-datalake-atualizacao-indices"
import ParameterDatalakeSpreadsIndicativosBrutoTable from "./tables/parameter-datalake-spreads-indicativos-bruto"
import ParameterDatalakePreviaInflacaoTable from "./tables/parameter-datalake-previa-inflacao"
import ParameterDatalakeSpreadsDevedorTable from "./tables/parameter-datalake-spreads-devedor"
import ParameterDatalakeSpreadsLiquidezTable from "./tables/parameter-datalake-spreads-liquidez"
import ParameterDatalakeSpreadsSecPulverizadoTable from "./tables/parameter-datalake-spreads-sec-pulverizado"
import ParameterDatalakeEventosCreditoDevedorTable from "./tables/parameter-datalake-eventos-credito-devedor"
import ParameterDatalakeEventosCreditoEmissaoTable from "./tables/parameter-datalake-eventos-credito-emissao"
import ParameterDatalakePrecosEventosFinanceirosTable from "./tables/parameter-datalake-precos-eventos-financeiros-table"
import ParameterDatalakePrecosInadimplentesLGDTable from "./tables/parameter-datalake-precos-inadimplentes-lgd-table"
import ParameterDatalakeBacenSegmentacaoTable from "./tables/parameter-datalake-bacen-segmentacao-table"
import ParameterDatalakeBacenCadastroConglomeradoTable from "./tables/parameter-datalake-bacen-cadastro-conglomerado-table"
import ParameterDatalakeBacenCadastroEmpresaTable from "./tables/parameter-datalake-bacen-cadastro-empresa-table"
import ParameterDatalakeCVMCadastroEmpresasTable from "./tables/parameter-datalake-cvm-cadastro-empresas-table"
import ParameterDatalakeCVMCadastroEmissorTable from "./tables/parameter-datalake-cvm-cadastro-emissor-table"
import ParameterDatalakeCVMCadastroSituacaoTable from "./tables/parameter-datalake-cvm-cadastro-situacao-table"
import ParameterRaterMMCotacoesTable from "./tables/parameter-rater-mm-cotacoes"
import ParameterRaterMMCotacoesAjustadasTable from "./tables/parameter-rater-mm-cotacoes-ajustadas"
import ParameterRaterMMPDTable from "./tables/parameter-rater-mm-pd"
import ParameterRaterMMResultadoTable from "./tables/parameter-rater-mm-resultado"
import ParameterRaterMMTickerCNPJTable from "./tables/parameter-rater-mm-ticker-cnpj"
import ParameterRaterMMVolatilidadeTickerTable from "./tables/parameter-rater-mm-volatilidade-ticker"
import ParameterRaterUserSetorialTable from "./tables/parameter-rater-user-setorial"
import ParameterRaterUserCadastroEmpresasTable from "./tables/parameter-rater-user-cadastro-empresas"



const ParameterDatalakePage = () => {

    const [tableSelected, setTableSelected] = useState("")
    const [loading, setLoading] = useState(false)
    const [endpointSelected, setEndpointSelected] = useState("")


    const setTable = (value) => {

        setTableSelected(value)

        if (value === "") return
        const { endpoint } = parameterDatalakeEndpoint.filter((item) => item.value === value)[0]
        setEndpointSelected(endpoint)
    }

    return <LayoutPage title="Parâmetros" loading={loading}>
        <div>
            <Box sx={{ mx: 2, width: 'auto' }}>
                <Grid container alignItems="flex-start" justifyContent="flex-start" spacing={3}>
                    <Grid item sm={12} md={6} xl={4} xs={12}>
                        <SelectValue
                            label={"Tabela"}
                            value={tableSelected}
                            id="select-app"
                            changeValue={setTable}
                            options={parameterDatalakeTableList} />
                    </Grid>
                    <Grid item sm={12} md={12} xl={12} xs={12}>
                        {(() => {
                            const componentMap = {
                                [parameterDatalakeTableList[0].value]: <ParameterAliasNomeDevedorTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[1].value]: <ParameterDatalakeAtualizacaoIndicesTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[2].value]: <ParameterDatalakeB3AtivosRegTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[3].value]: <ParameterDatalakeB3NegAnaliticoTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[4].value]: <ParameterDatalakeB3NegConsolidadoTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[5].value]: <ParameterDatalakeBacenSegmentacaoTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[6].value]: <ParameterDatalakeBacenCadastroConglomeradoTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[7].value]: <ParameterDatalakeBacenCadastroEmpresaTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[8].value]: <ParameterDatalakeCalendarioInflacaoTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[9].value]: <ParameterDatalakeContraparteTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[10].value]: <ParameterDatalakeCurvasSpreadsIndicativosTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[11].value]: <ParameterDatalakeCVMCadastroEmissorTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[12].value]: <ParameterDatalakeCVMCadastroEmpresasTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[13].value]: <ParameterDatalakeCVMCadastroSituacaoTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[14].value]: <ParameterDatalakeCVMFatosRelevantesTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[15].value]: <ParameterDatalakeEmissaoCaracteristicasTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[16].value]: <ParameterDatalakeEmissaoLTVTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[17].value]: <ParameterDatalakeEventosCreditoDevedorTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[18].value]: <ParameterDatalakeEventosCreditoEmissaoTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[19].value]: <ParameterDatalakeLiquidezTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[20].value]: <ParameterDatalakePrecosAndadosTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[21].value]: <ParameterDatalakePrecosEventosFinanceirosTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[22].value]: <ParameterDatalakePrecosInadimplentesLGDTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[23].value]: <ParameterDatalakePrecosProcessadosTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[24].value]: <ParameterDatalakePrecosSpreadsIndicativosTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[25].value]: <ParameterDatalakePrecosSpreadsRelativosTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[26].value]: <ParameterDatalakePrecosTunelTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[27].value]: <ParameterDatalakePreviaInflacaoTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[28].value]: <ParameterDatalakeRatingDevedorTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[29].value]: <ParameterDatalakeRatingEmissaoTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[30].value]: <ParameterDatalakeRiskFreeTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[31].value]: <ParameterDatalakeSpreadsEmissaoTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[32].value]: <ParameterDatalakeSpreadsIfNegTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[33].value]: <ParameterDatalakeSpreadsDevedorTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[34].value]: <ParameterDatalakeSpreadsIndicativosBrutoTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[35].value]: <ParameterDatalakeSpreadsLiquidezTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[36].value]: <ParameterDatalakeSpreadsNegociadosTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[37].value]: <ParameterDatalakeSpreadsSecPulverizadoTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[38].value]: <ParameterDatalakeTaxaJurosTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[39].value]: <ParameterDatalakeFincsEventosTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[40].value]: <ParameterDatalakeFincsJurosTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[41].value]: <ParameterDatalakeFincsMarketDataTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[42].value]: <ParameterRaterUserCadastroEmpresasTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[43].value]: <ParameterRaterUserSetorialTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[44].value]: <ParameterRaterMMCotacoesTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[45].value]: <ParameterRaterMMCotacoesAjustadasTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[46].value]: <ParameterRaterMMPDTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[47].value]: <ParameterRaterMMResultadoTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[48].value]: <ParameterRaterMMTickerCNPJTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterDatalakeTableList[49].value]: <ParameterRaterMMVolatilidadeTickerTable endpoint={endpointSelected} loading={setLoading} />,
                            }                            
 
                            const selectedComponent = componentMap[tableSelected]

                            return selectedComponent || null
                        })()}
                    </Grid>

                </Grid>
            </Box>


        </div>
    </LayoutPage>
}

export default ParameterDatalakePage