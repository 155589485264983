import { Box, Grid, Tab, Tabs } from '@mui/material'
import React, { useState } from 'react'
import LayoutPage from 'src/components/page-layout/layout-page'
import SimuladorSubordinacaoHistorico from './simulador-subordinacao-historico'
import SimuladorSubordinacaoSimulacao from './simulador-subordinacao-simulacao'
import AlertMessage from 'src/components/alert-message'

const SimuladorSubordinacaoPage = () => {
    const [selectedTab, setSelectedTab] = useState(0)
    const [loading, setLoading] = useState(0)
    const tabs = [
        { id: 0, description: "HISTÓRICO" },
        { id: 1, description: "SIMULAÇÃO" },
    ]
    const [alertMessageState, setAlertMessageState] = React.useState({
        open: false,
        message: "",
        type: "info"
    })

    const renderContent = () => {
        switch (selectedTab) {
            case 0:
                return <SimuladorSubordinacaoHistorico setLoading={setLoading} openAlert={openAlert} />
            case 1:
                return <SimuladorSubordinacaoSimulacao setLoading={setLoading} openAlert={openAlert} />
            default:
                null
        }
    }

    const handleChange = (_event, newValue) => {
        setSelectedTab(newValue)
    }

    const openAlert = (message, type) => {
        setAlertMessageState({
            ...alertMessageState,
            open: true,
            message: message,
            type: type
        })
    }


    return (
        <LayoutPage title="Simulador de Subordinação" loading={loading}>
            <AlertMessage open={alertMessageState.open} message={alertMessageState.message} type={alertMessageState.type} close={() => setAlertMessageState({ ...alertMessageState, open: false })} />
            <Grid container direction={"row"} alignItems={"center"}>
                <Grid item xs={12} sm={12} md={12} xl={12} sx={{ height: "100%" }}>
                    <Box
                        sx={{
                            height: "100vh",
                            display: "flex",
                            flexDirection: "column"
                        }}>
                        <Tabs value={selectedTab} onChange={handleChange} aria-label="tabs">
                            {tabs.map((tab) => (
                                <Tab label={tab.description} key={tab.id} />
                            ))}
                        </Tabs>

                        <Box
                            sx={{
                                flex: 1,
                                display: "flex",
                                flexDirection: "column",
                            }}>
                            <Grid
                                container
                                direction="row"
                                alignContent="flex-start"
                                justifyContent="flex-start"
                                spacing={0}
                                sx={{
                                    padding: 2,
                                    height: "100%", // altura de 100% da Box
                                }}>
                                <Grid item xs={12} sm={12} md={12} xl={12} sx={{ height: "100%" }}>
                                    {renderContent()}
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </LayoutPage>
    )
}

export default SimuladorSubordinacaoPage