import React from "react"
import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material"
import PropTypes from 'prop-types'

const TableGray = (props) => {
    return <>
        <TableContainer>
            <Table>
                <TableBody>
                    {
                        props.data.map((item, index) => <TableRow key={index}>
                            {item.cells.map((cell, index) => <TableCell key={index} sx={{ border: '1px solid #d6d6d6' }}>{cell}</TableCell>
                            )}
                        </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </>
}

TableGray.propTypes = {
    data: PropTypes.array
}

export default TableGray