import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment,
} from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { INDEX_PAGE } from './routes'
import AuthService from 'src/services/auth-service'
import { LAST_ACTIVITY, TIME_INACTIVE_MAX } from './constants'

const SessionTimeout = () => {
  const [events] = useState(['click', 'load', 'scroll'])
  const [second, setSecond] = useState(0)

  const navigate = useNavigate()
  const authService = new AuthService()

  let timeStamp
  let warningInactiveInterval = useRef()
  let startTimerInterval = useRef()

  // start inactive check
  let timeChecker = () => {
    startTimerInterval.current = setTimeout(() => {
      let storedTimeStamp = sessionStorage.getItem(LAST_ACTIVITY)
      warningInactive(storedTimeStamp)
    }, 60000)
  }

  // function to clear cache and logout client
  const logout = () => {
    clearInterval(warningInactiveInterval.current)
    sessionStorage.removeItem(LAST_ACTIVITY)
    authService.logout()
    window.removeEventListener(events, resetTimer)
    navigate(INDEX_PAGE)
  }

  // warning timer
  let warningInactive = (timeString) => {
    clearTimeout(startTimerInterval.current)

    warningInactiveInterval.current = setInterval(() => {
      const maxTime = TIME_INACTIVE_MAX
      const popTime = TIME_INACTIVE_MAX - 1 // time to show warning

      const diff = moment.duration(moment().diff(moment(new Date(timeString))))
      const minPast = diff.minutes()
      const leftSecond = 60 - diff.seconds()

      if (minPast === popTime) {
        setSecond(leftSecond)
      }

      if (minPast === maxTime) {
        logout()
      }
    }, 1000)
  }

  // reset interval timer
  let resetTimer = useCallback(() => {
    clearTimeout(startTimerInterval.current)
    clearInterval(warningInactiveInterval.current)

    timeStamp = moment()
    sessionStorage.setItem(LAST_ACTIVITY, timeStamp)

    timeChecker()
  }, [])

  useEffect(() => {
    events.forEach((event) => {
      window.addEventListener(event, resetTimer)
    })

    timeChecker()

    return () => {
      clearTimeout(startTimerInterval.current)
    }
  }, [resetTimer, events, timeChecker])

  console.log(second)

  return <Fragment />
}

export default SessionTimeout