import { CLIENTS_PATH, CLIENTS_PRICE_PATH, CLIENTE_CURVAS_LIST_PATH, CLIENTE_CURVAS_UPDATE_PATH } from "src/utils/api-routes"
import { ServiceRequest } from "src/utils/service-request"

class ClientService {

  constructor() {
    this.serviceRequest = new ServiceRequest()
  }

  async getClients(limit, offset) {
    return await this.serviceRequest.get(`${CLIENTS_PATH}?limit=${limit}&offset=${offset}`)
  }

  async createClient(client, grupos) {
    const { name, ssn } = client
    const body = {
      Nome: name,
      CPFCNPJ: ssn.replace(/\D/g, ''),
      Grupos: grupos
    }

    return this.serviceRequest.post(CLIENTS_PATH, body)
  }

  async updateClient(id, client, grupos) {
    const { name, ssn } = client
    const body = {
      Nome: name,
      CPFCNPJ: ssn.replace(/\D/g, ''),
      Grupos: grupos
    }

    return this.serviceRequest.patch(`${CLIENTS_PATH}/${id}`, body)
  }

  async lockUnlockUser(id, lock) {
    const body = {
      Bloqueado: lock
    }

    return this.serviceRequest.patch(`${CLIENTS_PATH}/${id}`, body)
  }

  async getClient(id) {
    return this.serviceRequest.get(`${CLIENTS_PATH}/${id}`)
  }

  async deleteClient(id) {
    return this.serviceRequest.delete(`${CLIENTS_PATH}/${id}`)
  }

  async loadClientPrices() {
    return this.serviceRequest.get(`${CLIENTS_PRICE_PATH}`)
  }

  // Buscar a lista de curvas de clientes (tela register-curve produto)
  async getClienteCurvasList() {
      return this.serviceRequest.get(`${CLIENTE_CURVAS_LIST_PATH}`)
  }
  // Enviar a lista de curvas de clientes checkados/descheckados (tela register-curve produto)
  async postUpdateClienteCurvas(payload) {
    return this.serviceRequest.post(`${CLIENTE_CURVAS_UPDATE_PATH}`, payload)
  }
}
export default ClientService