import { useNavigate } from "react-router-dom"
import { JWT_STORAGE_KEY, LOGIN_DATE, TIME_REFRESH_TOKEN } from "src/utils/constants"
import { INDEX_PAGE } from "src/utils/routes"
import { ServiceRequest } from "src/utils/service-request"
import StorageService from "./storage-service"

class SecurityService {

    constructor() {
        this.request = new ServiceRequest()
        this.storage = new StorageService()
        this.navigate = useNavigate()
    }

    logoutPassTime() {
        const dateLoginStorage = this.storage.getItemLocalStorage(LOGIN_DATE)

        if (dateLoginStorage == null) {
            this.navigate(INDEX_PAGE)
        }

        const dateLogin = new Date(Date.parse(dateLoginStorage))

        const now = new Date()
        const diffTime = now - dateLogin
        const minutesLogged = Math.round(((diffTime % 86400000) % 3600000) / 60000)

        if (minutesLogged > TIME_REFRESH_TOKEN) {
            this.navigate(INDEX_PAGE)
        }
    }

    logoutInvalidToken() {
        const token = this.storage.getItemLocalStorage(JWT_STORAGE_KEY)

        if (token === null || token === undefined || token === "") {
            this.navigate(INDEX_PAGE)
        }
    }

}

export default SecurityService