import { ROUTINE_CURVA_PATH, ROUTINE_LIQUIDEZ_PATH, ROUTINE_PRECOS_PATH, ROUTINE_SPREAD_NEGOCIADO_PATH, ROUTINE_SPREAD_RELATIVO_PATH, ROUTINE_VOLATILIDADE_PATH, ROUTINE_PRECOS_TUNEL_PATH, ROUTINE_PRECOS_ANDADOS_PATH, ROUTINE_SPREADSEMISSAO_PATH, ROUTINE_SPREADSBRUTO_PATH, ROUTINE_RISKFREE_WORKFLOW_PATH, ROUTINE_EVENTOS_FINANCEIROS_PUPAR_PATH, ROUTINE_EVENTOS_FINANCEIROS_PUMTM_PATH, PRICES_CALCULATION_CETIP_PATH } from "src/utils/api-routes"

export const routineDataWorkflowList = [
    { value: "SpreadRelativo", description: "Spread Relativo", disabled: true},
    { value: "VolatilidadeCurvas", description: "Volatilidade de Curvas", disabled: true},
    { value: "Curva", description: "Curva", disabled: true},
    { value: "Liquidez", description: "Liquidez", disabled: true},
    { value: "Precos", description: "Preços", disabled: true},
    { value: "SpreadNegociado", description: "Spread Negociado", disabled: true},
    { value: "PrecosTunel", description: "Túnel de Preços", disabled: true},
    { value: "PrecosAndados", description: "Preços Andados", disabled: true},
    { value: "SpreadEmissao", description: "Spreads Emissão", disabled: true},
    { value: "SpreadBruto", description: "Spreads Brutos", disabled: true},
    { value: "TratamentoRiskFree", description: "Tratamento Risk Free" },
    { value: "EventosFinanceirosPumtm", description: "Eventos Financeiros Pumtm", disabled: true},  
    { value: "EventosFinanceirosPupar", description: "Eventos Financeiros Pupar", disabled: true},  
    { value: "CalculationCetip", description: "Calculo Cetip", disabled: true},   
]

export const routineEndpointWorkflowList = [
    { value: "SpreadRelativo", endpoint: ROUTINE_SPREAD_RELATIVO_PATH, disabled: true},
    { value: "VolatilidadeCurvas", endpoint: ROUTINE_VOLATILIDADE_PATH, disabled: true },
    { value: "Curva", endpoint: ROUTINE_CURVA_PATH, disabled: true },
    { value: "Liquidez", endpoint: ROUTINE_LIQUIDEZ_PATH, disabled: true },
    { value: "Precos", endpoint: ROUTINE_PRECOS_PATH, disabled: true },
    { value: "SpreadNegociado", endpoint: ROUTINE_SPREAD_NEGOCIADO_PATH, disabled: true },
    { value: "PrecosTunel", endpoint: ROUTINE_PRECOS_TUNEL_PATH, disabled: true },
    { value: "PrecosAndados", endpoint: ROUTINE_PRECOS_ANDADOS_PATH, disabled: true },
    { value: "SpreadEmissao", endpoint: ROUTINE_SPREADSEMISSAO_PATH, disabled: true },
    { value: "SpreadBruto", endpoint: ROUTINE_SPREADSBRUTO_PATH, disabled: true },
    { value: "TratamentoRiskFree", endpoint: ROUTINE_RISKFREE_WORKFLOW_PATH },
    { value: "EventosFinanceirosPupar", endpoint: ROUTINE_EVENTOS_FINANCEIROS_PUPAR_PATH, disabled: true },
    { value: "EventosFinanceirosPumtm", endpoint: ROUTINE_EVENTOS_FINANCEIROS_PUMTM_PATH, disabled: true },
    { value: "CalculationCetip", endpoint: PRICES_CALCULATION_CETIP_PATH, disabled: true },
    
]