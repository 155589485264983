
export const MENU_STORAGE_KEY = "menu"
export const COLLAPSE_MENU_STORAGE_KEY = "collapse_menu"
export const JWT_STORAGE_KEY = "jwt"
export const REFRESH_JWT_STORAGE_KEY = "refresh"
export const LOGIN_DATE = "login_date"
export const LAST_ACTIVITY = "last_activity_date"
export const PERMISSIONS_STORAGE_KEY = "permissions"
export const CURRENT_PAGE_STORAGE_KEY = "current_page"

export const TIME_REFRESH_TOKEN = 360 // minutes
export const TIME_INACTIVE_MAX = 15 // minutes

export const PHONE_MASK = "(99) 9999-9999"
export const CELLPHONE_MASK = "(99) 9 9999-9999"
export const CPF_MASK = "999.999.999-99"
export const CNPJ_MASK = "99.999.999/9999-99"
export const DATE_FORMAT = "YYYY-MM-DD"
export const DATE_FORMAT_SHOW = "DD/MM/YYYY"
export const DATE_TIME_FORMAT_SHOW = "DD/MM/YYYY HH:mm:ss"
export const DATE_TIME_HOUR_MINUTE_FORMAT_SHOW = "DD/MM/YYYY HH:mm"
export const DATE_FORMAT_ISO8601 = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"

export const PERSON_TYPE = "PF"
export const COMPANY_TYPE = "PJ"

export const IMPORT_TYPE = "IMPORTACAO"
export const DELETE_TYPE = "DELECAO"
