import { Grid, Paper, IconButton, Checkbox, Snackbar, Alert } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import ClientService from 'src/services/client-service'
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded'

const RegisterCurve = () => {
     // armazena a lista de curvas, status de carregamento, mensagem de feedback e controle do Snackbar
    const [curveList, setCurveList] = useState([]) 
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')  
    const [openSnackbar, setOpenSnackbar] = useState(false)  
    // serviço que faz as requisições à API
    const clientService = new ClientService()

     // carrega as curvas dos clientes ao montar o componente
    useEffect(() => {
        loadClienteCurvas()
    }, [])

    // carrega a lista de curvas dos clientes da API
    const loadClienteCurvas = () => {
        setLoading(true)
        clientService.getClienteCurvasList()
            .then(response => {
                const data = response.body && response.body.data ? response.body.data : null

                if (data && Array.isArray(data.ID) && Array.isArray(data.Nome)) {
                    // alinha os dados para o DataGrid
                    const transformedData = data.ID.map((idCliente, index) => ({
                        id: idCliente,
                        Nome: data.Nome[index],
                        TIR: data.TIR[index],
                        Spreads: data.Spreads[index],
                        Setorial: data.Setorial[index],
                        Subsetorial: data.Subsetorial[index],
                        RiskFree: data.RiskFree[index],
                        HighGrade: data.HighGrade[index],
                        HighYield: data.HighYield[index],
                        Junk: data.Junk[index],
                        grupo_id: data.grupo_id[index],
                    }))

                     // ordena dados em ordem alfabética dos nomes dos clientes
                    transformedData.sort((a, b) => a.Nome.localeCompare(b.Nome))
                    setCurveList(transformedData)
                } else {
                    throw new Error('Formato de dados inválido da API')
                }
            })
            .catch(error => {
                console.error('Error loading curves:', error)
                setMessage('Erro ao carregar as curvas.') // mensagem de erro 
                setOpenSnackbar(true)
            })
            .finally(() => setLoading(false)) // estado de carregamento como falso 
    }

    // clique no botão de atualizar (refresh)
    const handleRefreshClick = (params) => {
        setLoading(true)
        console.log('Atualizar linha:', params.row)
    
        const payload = {
            cliente_id: params.row.id,
            tir: params.row.TIR ? 'TIR' : '',
            spreads: params.row.Spreads ? 'Spreads' : '',
            setorial: params.row.Setorial ? 'Setorial' : '',
            subsetorial: params.row.Subsetorial ? 'Subsetorial' : '',
            riskfree: params.row.RiskFree ? 'Risk Free' : '',
            highgrade: params.row.HighGrade ? 'High Grade' : '',
            highyield: params.row.HighYield ? 'High Yield' : '',
            junk: params.row.Junk ? 'Junk' : '',
            grupo_id: params.row.grupo_id,
        }
    
        clientService.postUpdateClienteCurvas(payload)
            .then(response => {
                console.log('Curvas atualizadas com sucesso:', response)
                setMessage('Curvas atualizadas com sucesso.') // mensagem de sucesso 
                setOpenSnackbar(true) // abre o snackbar pra mostrar a mensagem
                loadClienteCurvas() // recarrega a lista depois q atualiza
            })
            .catch(error => {
                console.error('Erro ao atualizar curvas:', error)
                setMessage('Não foi possível atualizar as curvas.')
                setOpenSnackbar(true)
            })
            .finally(() => setLoading(false)) 
    }

    const handleCheckboxChange = (event, params) => {
        const { field } = params
        // atualiza a lista com o novo estado dos checkboxes 
        const updatedCurveList = curveList.map((row) => {
            if (row.id === params.row.id) {
                return {
                    ...row,
                    [field]: event.target.checked ? 1 : 0,
                }
            }
            return row
        })
        setCurveList(updatedCurveList)
    }

    // renderiza um componente checkbox pra colunas booleanas
    const renderCheckbox = (params) => (
        <Checkbox 
            checked={Boolean(params.value)}  
            onChange={(event) => handleCheckboxChange(event, params)} //define tratamento de mudança checkbox
            sx={{ 
                color: '#222851', // cor da c2p
                '&.Mui-checked': { 
                    color: '#222851',
                } 
            }}
        />
    )

    // refresh do botão de atualização 
    const renderRefreshButton = (params) => (
        <IconButton onClick={() => handleRefreshClick(params)} disabled={loading}>
            <RefreshRoundedIcon sx={{ color: loading ? '#bbb' : '#222851' }} />
        </IconButton>
    )

    // fecha o snackbar 
    const handleCloseSnackbar = () => {
        setOpenSnackbar(false)
    }

    // colunas do datagrid
    const columns = [
        {
            field: 'Nome',
            headerName: 'Cliente',
            flex: true,
            minWidth: 200,
            maxWidth: 250,
        },
        {
            field: 'TIR',
            headerName: 'TIR',
            flex: true,
            minWidth: 80,
            maxWidth: 120,
            renderCell: renderCheckbox,
        },
        {
            field: 'Spreads',
            headerName: 'Spreads',
            flex: true,
            minWidth: 80,
            maxWidth: 120,
            renderCell: renderCheckbox,
        },
        {
            field: 'Setorial',
            headerName: 'Setorial',
            flex: true,
            minWidth: 80,
            maxWidth: 120,
            renderCell: renderCheckbox,
        },
        {
            field: 'Subsetorial',
            headerName: 'Subsetorial',
            flex: true,
            minWidth: 80,
            maxWidth: 120,
            renderCell: renderCheckbox,
        },
        {
            field: 'RiskFree',
            headerName: 'Risk Free',
            flex: true,
            minWidth: 80,
            maxWidth: 120,
            renderCell: renderCheckbox,
        },
        {
            field: 'HighGrade',
            headerName: 'High Grade',
            flex: true,
            minWidth: 80,
            maxWidth: 120,
            renderCell: renderCheckbox,
        },
        {
            field: 'HighYield',
            headerName: 'High Yield',
            flex: true,
            minWidth: 80,
            maxWidth: 120,
            renderCell: renderCheckbox,
        },
        {
            field: 'Junk',
            headerName: 'Junk',
            flex: true,
            minWidth: 80,
            maxWidth: 120,
            renderCell: renderCheckbox,
        },
        {   
            field: 'Atualizar',
            headerName: 'Atualizar',
            flex: true,
            minWidth: 80,
            maxWidth: 120,
            renderCell: renderRefreshButton,
            sortable: false, // não deixa filtrar e classificar pelos botões
            filterable: false,
        }
    ]

    // componente principal com o datagrid e snackbar 
    return (
        <Grid container>
            <Grid item xs={12}>
                <Paper>
                    <DataGrid
                        autoHeight
                        rows={curveList}
                        getRowId={(row) => row.id}
                        columns={columns}
                        pageSize={10}
                    />
                </Paper>
            </Grid>

            <Snackbar 
                open={openSnackbar} 
                autoHideDuration={6000} 
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}  
            >
                <Alert onClose={handleCloseSnackbar} severity={message.includes('sucesso') ? 'success' : 'error'} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </Grid>
    )
}

export default RegisterCurve
