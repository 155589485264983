import { Avatar, Button, Grid, Paper, TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import InputNumberMask from "src/components/input-number-mask"
import LayoutPage from "src/components/page-layout/layout-page"
import UserService from "src/services/user-service"
import { CELLPHONE_MASK } from "src/utils/constants"
import { Person } from "@mui/icons-material"
import AlertMessage from "src/components/alert-message"


const ProfilePage = () => {
    const userService = new UserService()
    const [loading, setLoading] = useState(false)
    const [validateInput, setValidateInput] = useState({
        name: false,
        phone: false
    })
    const [profile, setProfile] = useState({
        name: "",
        client: "",
        phone: "",
        email: ""
    })
    const [alertMessageState, setAlertMessageState] = React.useState({
        open: false,
        message: "",
        type: ""
    })

    useEffect(() => {
        loadUser()
    }, [])

    const loadUser = async () => {
        setLoading(true)
        userService.getLoggedUser().then((response) => {
            const { body } = response
            const { NomeCompleto, username, NomeCliente, Telefone } = body

            setProfile({
                name: NomeCompleto,
                client: NomeCliente,
                phone: Telefone,
                email: username
            })
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    }

    const validateForm = () => {
        if (profile.name === "") {
            setValidateInput({ ...validateInput, name: true })
            return false
        }
        if (profile.phone === "") {
            setValidateInput({ ...validateInput, phone: true })
            return false
        }

        setValidateInput({ name: false, phone: false })
        return true
    }

    const updateUser = async () => {
        if (!validateForm()) return

        if (profile.name !== "" && profile.phone !== "") {
            setLoading(true)
            await userService.updateLoggedUser(profile.name, profile.phone)
            setLoading(false)
            openAlert("Dados atualizados", "success")
        }
    }

    const closeAlert = () => {
        setAlertMessageState({ ...alertMessageState, open: false })
    }

    const openAlert = (message, type) => {
        setAlertMessageState({
            ...alertMessageState,
            open: true,
            message: message,
            type: type
        })
    }

    return <LayoutPage title="Perfil" loading={loading}>
        <AlertMessage open={alertMessageState.open} message={alertMessageState.message} type={alertMessageState.type} close={closeAlert} />
        <Grid container justifyContent={"flex-start"}>
            <Grid item xs={12} md={12} xl={4} >
                <Grid container>
                    <Grid container justifyContent={"flex-end"} spacing={4}>
                        <Grid item>
                            <Button variant="contained" onClick={updateUser}>
                                Salvar
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <br />
        <Grid container justifyContent={"flex-start"}>
            <Grid item xs={12} md={12} xl={4}>
                <Paper sx={{ padding: 4 }}>
                    <Grid container justifyContent={"center"}>
                        <Grid item>
                            <Avatar style={{ width: 100, height: 100 }}>
                                <Person style={{ fontSize: 80 }} />
                            </Avatar>
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={12} xl={12}>
                            <TextField
                                label="Nome *"
                                variant="standard"
                                error={validateInput.name}
                                helperText={validateInput.name ? "Campo Obrigatório" : ""}
                                value={profile.name}
                                onChange={(e) => setProfile({ ...profile, name: e.target.value })}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6} xl={6}>
                            <InputNumberMask
                                value={profile.phone}
                                mask={CELLPHONE_MASK}
                                error={validateInput.phone}
                                helperText={validateInput.phone ? "Campo Obrigatório" : ""}
                                label="Telefone *"
                                onChange={(value) => setProfile({ ...profile, phone: value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} xl={12}>
                            <TextField
                                variant="standard"
                                label="Email"
                                helperText="Somente leitura"
                                value={profile.email}
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={12} xl={12}>
                            <TextField
                                label="Cliente"
                                value={profile.client}
                                helperText="Somente leitura"
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="standard"
                                fullWidth
                            />
                        </Grid>
                    </Grid>

                </Paper>
            </Grid>
        </Grid>


    </LayoutPage>
}

export default ProfilePage