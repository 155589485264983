export const onlyTextRegex = (str) => {
    const regex = /^[A-Za-z0-9_.-\s]+$/
    return regex.test(str)
}

export const applyRegex = (str) => {
    return str.replace(/[^A-Za-z0-9_.-\s]/g, '')
}

export const onlyNumberRegex = (str) => {
    return str.replace(/\D/g, '')
}
