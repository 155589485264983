import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material"

const LineCheckbox = (props) => {
    const { items, allChecked, callback } = props
    const [selectAllTitle, setSelectAllTitle] = useState(allChecked ? "Desmarcar Todos" : "Selecionar Todos")
    const [selected, setSelected] = useState(allChecked ? items : [])

    useEffect(() => {
        if (allChecked) {
            setSelectAllTitle("Desmarcar Todos")
            setSelected(items)
        } else {
            setSelectAllTitle("Selecionar Todos")
        }
    }, [allChecked, items])

    const selectItem = (event, item) => {
        const action = event.target.checked
        let newArray
        if (action) {
            newArray = [...selected, item]
        } else {
            newArray = selected.filter((select) => select !== item)
        }
        setSelected(newArray)
        callback(newArray)
    }

    const enableDisableAll = (event) => {
        const action = event.target.checked
        if (action) {
            setSelectAllTitle("Desmarcar Todos")
            setSelected(items)
            callback(items)
        } else {
            setSelectAllTitle("Selecionar Todos")
            setSelected([])
            callback([])
        }
    }

    return (
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
            <Grid item>
                <Typography variant="body1" fontWeight="bold">Rating</Typography>
            </Grid>
            {items.map((item, index) => (
                <Grid item key={index}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selected.includes(item)}
                                onChange={(event) => selectItem(event, item)}
                            />
                        }
                        label={item}
                    />
                </Grid>
            ))}
            <Grid item>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={selected.length === items.length}
                            indeterminate={selected.length !== items.length && selected.length > 0}
                            onChange={(event) => enableDisableAll(event)}
                        />
                    }
                    label={selectAllTitle}
                />
            </Grid>
        </Grid>
    )
}

LineCheckbox.propTypes = {
    items: PropTypes.array.isRequired,
    allChecked: PropTypes.bool.isRequired,
    callback: PropTypes.func.isRequired
}

export default LineCheckbox
