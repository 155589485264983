import React from "react"
import TableColumns from "src/components/table/table-columns"
import PropTypes from 'prop-types'
import dayjs from "dayjs"
import { DATE_FORMAT_SHOW } from "src/utils/constants"
import { formatStringToFloatDecimalCase } from "src/utils/format-decimal"

const PriceCharacteristicsEmissao = (props) => {

    const getDate = (value) => {
        if (props.data == null || props.data[value] == null) {
            return null
        }

        return dayjs(props.data[value]).format(DATE_FORMAT_SHOW)
    }

    const getValueFormated = (value) => {
        if (props.data == null || props.data[value] == null) {
            return null
        }

        return formatStringToFloatDecimalCase(props.data[value])
    }


    const getValue = (value) => {
        if (props.data == null) {
            return null
        }

        return props.data[value]
    }

    const columns = [
        { keyA: "CETIP", valueA: getValue('CETIP'), keyB: "ISIN", valueB: getValue('ISIN') },
        { keyA: "Série", valueA: getValue('Serie'), keyB: "Emissão", valueB: getValue('Emissao') },
        { keyA: "Tipo Ativo", valueA: getValue('TipoAtivo'), keyB: "Fluxo Base", valueB: getValue('FluxoBase') },
        { keyA: "Data Emissão", valueA: getDate('DataEmissao'), keyB: "Data Vencimento", valueB: getDate('DataVencimento') },
        { keyA: "Data Primeira Integralização", valueA: getDate('DataPrimeiraIntegralizacao'), keyB: "Data Resgate Antecipado", valueB: getDate('DataResgateAntecipado') },
        { keyA: "PU Emissão", valueA: getValueFormated('PU_Emissao'), keyB: "Quantidade Emitida", valueB: getValueFormated('QuantidadeEmitida') },
        { keyA: "Volume Financeiro", valueA: getValueFormated('VolumeFinanceiro'), keyB: "Lei 12.431", valueB: getValue('Lei12431') },
        { keyA: "Indicador", valueA: getValue('Indicador'), keyB: "Taxa Accrual", valueB: getValueFormated('TaxaAccrual') },
        { keyA: "Agente Fiduciário", valueA: getValue('AgenteFiduciario'), keyB: "Cedente", valueB: getValue('Cedente') },
        { keyA: "Devedor", valueA: getValue('Devedor'), keyB: "CPF_CNPJ_Devedor", valueB: getValue('CPF_CNPJ_Devedor') },
        { keyA: "Grupo Econômico", valueA: getValue('GrupoEconomico'), keyB: "CNPJ Grupo Econômico", valueB: getValue('CNPJGrupoEconomico') },
        { keyA: "Setor", valueA: getValue('Setor'), keyB: "Subsetor", valueB: getValue('SubSetor') },
    ]

    return (
        <>
            <TableColumns columns={columns} title={"Características da Emissão"} />
        </>
    )
}

PriceCharacteristicsEmissao.propTypes = {
    data: PropTypes.object
}


export default PriceCharacteristicsEmissao