import React from "react"
import LayoutPage from "src/components/page-layout/layout-page"
import { RedocStandalone } from 'redoc'


const ApiPage = () => {
    return <LayoutPage title="API Integração">
        <RedocStandalone
            specUrl={`./docs/api-doc.json`}
            options={{
                nativeScrollbars: true,
                theme: { colors: { primary: { main: '#dd5522' } } },
            }}
        />
    </LayoutPage>
}

export default ApiPage