import { IMPORT_DATALAKE_PATH, RECEIVABLE_CONCESSIONS_PATH, RECEIVABLE_WALLETS_PATH } from "src/utils/api-routes"
import { formatDecimalCaseReplaceDot, formatToFloatDecimalCase } from "src/utils/format-decimal"
import { ServiceRequest } from "src/utils/service-request"

class ImportFileService {
    constructor() {
        this.serviceRequest = new ServiceRequest()
    }

    getAPPList() {
        return [
            { value: 'cadastro', description: "Cadastro" },
            { value: 'calculo', description: "CreditCalc" },
            { value: 'datalake', description: "DataLake" },
            { value: 'rater', description: "Rater" },
            { value: 'recebiveis', description: "GR" },
        ]
    }

    async sendDataLakeFile(selectedFile, importType, app) {
        const formData = new FormData()

        formData.append('arquivo', selectedFile)
        formData.append('modelo', importType)
        formData.append('app', app)

        return this.serviceRequest.postFormData(IMPORT_DATALAKE_PATH, formData)
    }

    async sendReceivableOperationFile(selectedFile, filename) {
        const formData = new FormData()

        formData.append('arquivo', selectedFile)
        formData.append('filename', filename)

        return this.serviceRequest.postFormData(RECEIVABLE_CONCESSIONS_PATH, formData)
    }

    async sendReceivableWalletFile(selectedFile, nomeCarteira, valorPercentil, subordinacao, caixa) {
        const formData = new FormData()
        const valorCaixaFormatado = formatToFloatDecimalCase(formatDecimalCaseReplaceDot(caixa))
        const valorCaixa = (valorCaixaFormatado) !== null ? valorCaixaFormatado : 0
        const formatedSubordinacao = subordinacao.map(({
            nomeCota,
            plCota,
            indexador,
            taxaAccrual,
            durationCota
        }) => {
            let DU = durationCota
            if (DU === '') {
                return {
                    "NomeCota": nomeCota,
                    "PLCota": formatToFloatDecimalCase(plCota),
                    "Indexador": indexador,
                    "TaxaAccrual": parseFloat(taxaAccrual)
                }
            }
            else {// console.log('Este é o valor APÓS O IF de DU: ', DU, ' e o tipo', typeof DU)
                return {
                    "NomeCota": nomeCota,
                    "PLCota": formatToFloatDecimalCase(plCota),
                    "Indexador": indexador,
                    "TaxaAccrual": parseFloat(taxaAccrual),
                    "DurationCota": parseFloat(durationCota)
                }
            }
        })

        const parsedSubordinacao = JSON.stringify(formatedSubordinacao)
        console.log('Este é o valor de parsedSubordinacao:', parsedSubordinacao)

        formData.append('arquivo', selectedFile)
        formData.append('nome_carteira', nomeCarteira)
        formData.append('percentil', valorPercentil)
        formData.append('caixa', valorCaixa)
        formData.append('subordinacao[]', parsedSubordinacao)

        return this.serviceRequest.postFormData(RECEIVABLE_WALLETS_PATH, formData)
    }

    async loadLastImports(limit = 200, type) {
        return this.serviceRequest.get(`${IMPORT_DATALAKE_PATH}?limit=${limit}&type=${type}`)
    }

    verifyTableStatus(value) {
        let text = "Não informado"
        let color = ""
        switch (value) {
            case "DONE":
                text = "SUCESSO"
                color = "success"
                break
            case "IN_PROGRESS":
                text = "EM PROGRESSO"
                color = "primary"
                break
            case "PENDING":
                text = "PENDENTE"
                color = "warning"
                break
            case "ERROR":
                text = "ERRO"
                color = "error"
                break
            case "FILE_ERROR":
                text = "ERRO NO ARQUIVO"
                color = "error"
                break
        }

        return {
            text: text,
            color: color
        }
    }

}

export default ImportFileService