import React, { useEffect, useState } from "react"
import { formatDateDataGrid } from "src/utils/format-date"
import PropTypes from 'prop-types'
import ParameterPaginationTable from "src/components/table/parameter-pagination-table"
import DatalakeService from "src/services/datalake-service"
import dayjs from "dayjs"
import { DATE_FORMAT } from "src/utils/constants"

const ParameterDatalakeSpreadsIfNegTable = (props) => {
    const dataLakeService = new DatalakeService()
    const [data, setData] = useState([])
    const [pagination, setPagination] = useState({ count: 0, next: null, previous: null })

    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        props.loading(true)
        dataLakeService.loadPaginationEndpoint(props.endpoint).then((response) => {
            props.loading(false)

            const { body } = response
            const { results, count, next, previous } = body
            const list = results.map((obj, index) => ({ ...obj, id: index }))
            setPagination({ count: count, next: next, previous: previous })
            setData(list)
        })
    }

    const createRow = async () => {
        const body = {
            Devedor: 'N/A',
            DataReferencia: dayjs().format(DATE_FORMAT),
            Fonte: 'N/A',
            TipoAtivo: 'N/A',
            PUEmissao: 0,
            QtdEmitida: 0,
            Indicador: 'N/A',
            TaxaMTM: 0,
            IDRiskFree: 0,
            IDCurvaRating: 0,
            DurationDC: 0,
            DurationDU: 0,
            TaxaRiskFree: 0,
            TaxaCurvaRating: 0,
            TIRComIR: 0,
            TIRSemIR: 0,
            SpreadCreditoFinal: 0,
            SpreadRelativoFinal: 0,
            SpreadCreditoComIR: 0,
            SpreadCreditoSemIR: 0,
            SpreadCurvaRating: 0,
            SpreadRelativoComIR: 0,
            SpreadRelativoSemIR: 0,
            LOGERRO: 'N/A'
        }

        props.loading(true)
        return dataLakeService.createTableEndpoint(props.endpoint, body).then(() => {
            props.loading(false)
            loadData()
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const updateRow = async (newRow) => {
        const { ID, Devedor, DataReferencia, Fonte, TipoAtivo, PUEmissao, QtdEmitida, Indicador, TaxaMTM,
            IDRiskFree, IDCurvaRating, DurationDC, DurationDU, TaxaRiskFree, TaxaCurvaRating, TIRComIR,
            TIRSemIR, SpreadCreditoFinal, SpreadRelativoFinal, SpreadCreditoComIR, SpreadCreditoSemIR,
            SpreadCurvaRating, SpreadRelativoComIR, SpreadRelativoSemIR, LOGERRO } = newRow

        const body = {
            Devedor: Devedor,
            DataReferencia: DataReferencia,
            Fonte: Fonte,
            TipoAtivo: TipoAtivo,
            PUEmissao: PUEmissao,
            QtdEmitida: QtdEmitida,
            Indicador: Indicador,
            TaxaMTM: TaxaMTM,
            IDRiskFree: IDRiskFree,
            IDCurvaRating: IDCurvaRating,
            DurationDC: DurationDC,
            DurationDU: DurationDU,
            TaxaRiskFree: TaxaRiskFree,
            TaxaCurvaRating: TaxaCurvaRating,
            TIRComIR: TIRComIR,
            TIRSemIR: TIRSemIR,
            SpreadCreditoFinal: SpreadCreditoFinal,
            SpreadRelativoFinal: SpreadRelativoFinal,
            SpreadCreditoComIR: SpreadCreditoComIR,
            SpreadCreditoSemIR: SpreadCreditoSemIR,
            SpreadCurvaRating: SpreadCurvaRating,
            SpreadRelativoComIR: SpreadRelativoComIR,
            SpreadRelativoSemIR: SpreadRelativoSemIR,
            LOGERRO: LOGERRO
        }

        props.loading(true)
        return dataLakeService.updateTableEndpoint(props.endpoint, ID, body).then(() => {
            props.loading(false)
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const deleteRow = async (row) => {
        props.loading(true)
        const { ID } = row
        return dataLakeService.deleteTableEndpoint(props.endpoint, ID).then(() => {
            props.loading(false)
            const listRemoved = data.filter((item) => item.ID !== ID)
            setData(listRemoved)
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const onFilter = async (filterList, limit, offset) => {
        const query = filterList.map(obj => `${encodeURIComponent(obj.field)}=${encodeURIComponent(obj.value)}`).join('&')

        props.loading(true)
        dataLakeService.loadPaginationEndpoint(props.endpoint, query, limit, offset).then((response) => {
            props.loading(false)
            const { body } = response
            const { results, count, next, previous } = body
            const list = results.map((obj) => ({ ...obj, id: obj.ID }))
            setPagination({ count: count, next: next, previous: previous })
            setData(list)
        })
    }

    const changePage = async (endpoint) => {
        props.loading(true)
        dataLakeService.paginate(endpoint).then((response) => {
            props.loading(false)
            const { body } = response
            const { results, count, previous, next } = body
            const list = results.map((obj) => ({ ...obj, id: obj.ID }))

            setPagination({ count: count, next: next, previous: previous })
            setData([])
            setData(list)
        })
    }

    const columns = [
        {
            field: 'ID',
            headerName: '#',
            disableColumnMenu: true,
            disableFilter: true,
            flex: true,
            minWidth: 80,
        },
        {
            field: 'Devedor',
            headerName: 'Devedor',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'DataReferencia',
            headerName: 'DataReferencia',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'date',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Fonte',
            headerName: 'Fonte',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'TipoAtivo',
            headerName: 'TipoAtivo',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'PUEmissao',
            headerName: 'PUEmissao',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'QtdEmitida',
            headerName: 'QtdEmitida',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Indicador',
            headerName: 'Indicador',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'TaxaMTM',
            headerName: 'TaxaMTM',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'IDRiskFree',
            headerName: 'IDRiskFree',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'IDCurvaRating',
            headerName: 'IDCurvaRating',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'DurationDC',
            headerName: 'DurationDC',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'DurationDU',
            headerName: 'DurationDU',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'TaxaRiskFree',
            headerName: 'TaxaRiskFree',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'TaxaCurvaRating',
            headerName: 'TaxaCurvaRating',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'TIRComIR',
            headerName: 'TIRComIR',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'TIRSemIR',
            headerName: 'TIRSemIR',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'SpreadCreditoFinal',
            headerName: 'SpreadCreditoFinal',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'SpreadRelativoFinal',
            headerName: 'SpreadRelativoFinal',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'SpreadCreditoComIR',
            headerName: 'SpreadCreditoComIR',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'SpreadCreditoSemIR',
            headerName: 'SpreadCreditoSemIR',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'SpreadCurvaRating',
            headerName: 'SpreadCurvaRating',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'SpreadRelativoComIR',
            headerName: 'SpreadRelativoComIR',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'SpreadRelativoSemIR',
            headerName: 'SpreadRelativoSemIR',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'LOGERRO',
            headerName: 'LOGERRO',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'CriadoEm',
            headerName: 'Data de Criação',
            flex: true,
            minWidth: 150,
            disableColumnMenu: true,
            disableFilter: true,
            renderCell: (params) => formatDateDataGrid(params)
        },
        {
            field: 'AtualizadoEm',
            headerName: 'Data de Atualização',
            flex: true,
            minWidth: 150,
            disableColumnMenu: true,
            disableFilter: true,
            renderCell: (params) => formatDateDataGrid(params)
        }
    ]

    return (
        <div>
            <ParameterPaginationTable
                columns={columns}
                data={data}
                setData={setData}
                createRow={createRow}
                deleteRow={deleteRow}
                updateRow={updateRow}
                filterFunction={onFilter}
                pagination={pagination}
                onChangePage={changePage}
                customFilter={true} />
        </div>
    )
}

ParameterDatalakeSpreadsIfNegTable.propTypes = {
    loading: PropTypes.func,
    endpoint: PropTypes.string,
}

export default ParameterDatalakeSpreadsIfNegTable
