import { Button, Grid, TextField } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import React, { useEffect, useState } from "react"
import ModalDialogPage from "src/components/modal-dialog"
import TableGray from "src/components/table/table-gray"
import EmissaoService from "src/services/emissao-service"
import PropTypes from 'prop-types'
import { DATE_FORMAT, DATE_FORMAT_SHOW } from "src/utils/constants"
import dayjs from "dayjs"
import SkeletonLine from "src/components/skeleton-line"
import { formatDecimalCaseReplaceComma, formatDecimalCaseWithComma } from "src/utils/format-decimal"
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'

const tableExample = [
    {
        cells: ["CETIP: ", "ISIN: "]
    }
]


const UltimoNegocio = ({ cetipIsin, loading }) => {
    const emissaoService = new EmissaoService()
    const ultimoNegocioDefault = { data_referencia: "", pupar: "", spread_credito: "" }
    const [openMostrarTodos, setOpenMostrarTodos] = useState(false)
    const [ultimoNegocioState, setUltimoNegocioState] = useState(ultimoNegocioDefault)
    const [loadingUltimoNegocio, setLoadingUltimoNegocio] = useState(false)
    const [rowsUltimoNegocio, setRowsUltimoNegocio] = useState([])
    const [tableTop, setTableTop] = useState(tableExample)

    useEffect(() => {
        if (cetipIsin && cetipIsin.length > 4) {
            setTableTop([{ cells: [`CETIP: ${cetipIsin}`, "ISIN: "] }])
            loadData(cetipIsin)
        }
    }, [cetipIsin])

    const loadData = (cetipIsin) => {
        console.log('UltimoEventoCreditoEmissao: ', cetipIsin)
        setLoadingUltimoNegocio(true)
        emissaoService.getUltimoSpreadNegociados(cetipIsin).then((response) => {
            const { body } = response
            const { data } = body

            if (data !== null) {
                const { DataReferencia, PercentualPUPAR, SpreadCreditoFinal } = data

                setUltimoNegocioState({
                    data_referencia: dayjs(DataReferencia).format(DATE_FORMAT_SHOW),
                    pupar: formatDecimalCaseReplaceComma(PercentualPUPAR),
                    spread_credito: formatDecimalCaseWithComma(SpreadCreditoFinal, 4),
                })
            } else {
                setUltimoNegocioState(ultimoNegocioDefault)
            }
        }).catch(() => {
            setUltimoNegocioState(ultimoNegocioDefault)
        }).finally(() => {
            setLoadingUltimoNegocio(false)
        })
    }

    const openUltimoNegocioModal = () => {
        loading(true)

        const dataMeno60Dias = dayjs().subtract(60, 'day')
        const minus60Days = dataMeno60Dias.format(DATE_FORMAT)
        emissaoService.ultimoNegocios("get", `?CETIP=${cetipIsin.toUpperCase()}&DataReferencia__gte=${minus60Days}`)
            .then((response) => {
                setOpenMostrarTodos(true)
                const { body } = response
                const { results } = body
                const rows = results.map((obj, index) => ({ ...obj, id: index }))
                setRowsUltimoNegocio(rows)
            })
            .catch(() => { })
            .finally(() => {
                loading(false)
            })
    }

    const deleteItem = (params) => {
        const { row } = params
        const { ID } = row

        loading(true)
        emissaoService.ultimoNegocios("delete", ID)
            .then(() => { openUltimoNegocioModal() })
            .finally(() => {
                loading(false)
            })
    }

    return <>
        {loadingUltimoNegocio ? <SkeletonLine /> :
            <Grid container direction="row" alignItems={"center"} justifyContent="space-between" spacing={2}>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <TextField fullWidth label="Data Referência" value={ultimoNegocioState.data_referencia} />
                </Grid>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <TextField fullWidth label="%PUPAR" value={ultimoNegocioState.pupar} />
                </Grid>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <TextField fullWidth label="Spread Crédito" value={ultimoNegocioState.spread_credito} />
                </Grid>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <Button fullWidth variant="contained" onClick={openUltimoNegocioModal}>Mostrar Todos</Button>
                </Grid>
            </Grid>
        }

        <ModalDialogPage open={openMostrarTodos}
            title="Últimos Negócios"
            close={() => {
                setOpenMostrarTodos(false)
            }}>
            <>
                <Grid container direction="column" spacing={2}>
                    <Grid item xs={12} md={12} xl={12}>
                        <TableGray data={tableTop} />
                    </Grid>
                </Grid>
                <br />
                <Grid container direction="column">
                    <Grid item xs={12} md={12} xl={12}>
                        <DataGrid
                            autoHeight
                            columns={[
                                { field: 'DataReferencia', headerName: 'Data Referencia', flex: 1 },
                                { field: 'PU_MTM', headerName: 'PU MTM', flex: 1 },
                                { field: 'PercentualPUPAR', headerName: '%PUPAR', flex: 1 },
                                { field: 'TaxaMTM', headerName: 'Taxa MTM', flex: 1 },
                                { field: 'SpreadCreditoFinal', headerName: 'Spread Crédito', flex: 1 },
                                { field: 'VolumeFinanceiro', headerName: 'Volume FInanceiro', flex: 1 },
                                {
                                    field: "",
                                    headerName: "Remover",
                                    minWidth: 150,
                                    renderCell: (params) => <Button color="error" variant="outlined" onClick={() => deleteItem(params)}>
                                        <DeleteRoundedIcon />
                                    </Button>
                                }
                            ]}
                            rows={rowsUltimoNegocio}
                        />
                    </Grid>
                </Grid>
            </>
        </ModalDialogPage>
    </>
}

UltimoNegocio.propTypes = {
    cetipIsin: PropTypes.string,
    loading: PropTypes.func,
}

export default UltimoNegocio