import { Button, ButtonGroup } from "@mui/material"
import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import dayjs from "dayjs"
import DateRangeModal from "./date-range-modal"
// import DatePickerModal from "./date-picker-modal"

const DateIntervalButtonGroup = (props) => {
    const [selectedOption, setSelectedOption] = useState(null)
    const [openIntervalDate, setOpenIntervalDate] = useState(false)
    // const [openMaxDate, setOpenMaxDate] = useState(false)
    const [rangeDate, setRangeDate] = useState({
        firstDate: dayjs(),
        endDate: dayjs()
    })

    useEffect(() => {
        if (props.valueDefault !== undefined) {
            const valueInformed = options.filter((item) => item.value = props.valueDefault)[0]
            valueInformed.action()
        } else {
            receiveMaxDate('max')
        }
    }, [])

    const handleOptionChange = (value) => {
        setSelectedOption(value)
    }

    const returnValues = (valuesCallback) => {
        console.log(rangeDate)
        if (props.callback) {
            props.callback(valuesCallback)
        }
    }

    const monthAgo = (value, days) => {
        handleOptionChange(value)
        const today = dayjs()
        const oneMonthAgo = today.subtract(days, 'day')
        const objectCallback = {
            firstDate: oneMonthAgo,
            endDate: today
        }
        setRangeDate(objectCallback)
        returnValues(objectCallback)
    }

    const receiveMaxDate = (value) => {
        handleOptionChange(value)
        returnValues({
            firstDate: null,
            endDate: dayjs()
        })
        // setOpenMaxDate(false)
    }

    const receiveIntervalDate = (firstDate, endDate) => {
        returnValues({
            firstDate: firstDate,
            endDate: endDate
        })
        setOpenIntervalDate(false)
    }

    const openIntervalModal = (value) => {
        handleOptionChange(value)
        setOpenIntervalDate(true)
    }

    const options = [
        { value: 'interval', label: 'Intervalo', action: () => { openIntervalModal('interval') } },
        // { value: 'max', label: 'Máximo', action: () => { setOpenMaxDate(true) } },
        { value: 'max', label: 'Máximo', action: () => { receiveMaxDate('max') } },
        { value: '12m', label: '12 meses', action: () => { monthAgo('12m', 365) } },
        { value: '1m', label: '1 mês', action: () => { monthAgo('1m', 30) } },
    ]

    return <div>
        <ButtonGroup color="primary" variant="outlined">
            {options.map(option => (
                <Button
                    key={option.value}
                    onClick={() => {
                        handleOptionChange(option.value)
                        option.action()
                    }}
                    variant={selectedOption === option.value ? 'contained' : 'outlined'}
                >
                    {option.label}
                </Button>
            ))}
        </ButtonGroup>
        <DateRangeModal
            callback={receiveIntervalDate}
            open={openIntervalDate}
            onClose={() => { setOpenIntervalDate(false) }} />

        {/* <DatePickerModal
            callback={receiveMaxDate}
            open={openMaxDate}
            onClose={() => { setOpenMaxDate(false) }}
        /> */}
    </div>
}

DateIntervalButtonGroup.propTypes = {
    valueDefault: PropTypes.string,
    callback: PropTypes.func
}


export default DateIntervalButtonGroup