import { Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material"
import React from "react"

const SkeletonTable = () => {
    const linhasEsqueleto = Array.from({ length: 5 }, (_, index) => index)

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableBody>
                    {linhasEsqueleto.map((linha) => (
                        <TableRow key={linha}>
                            <TableCell>
                                <Skeleton variant="text" />
                            </TableCell>
                            <TableCell>
                                <Skeleton variant="text" />
                            </TableCell>
                            <TableCell>
                                <Skeleton variant="text" />
                            </TableCell>
                            <TableCell>
                                <Skeleton variant="text" />
                            </TableCell>
                            <TableCell>
                                <Skeleton variant="text" />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )

}

export default SkeletonTable