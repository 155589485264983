import { Box, Button, Grid, Paper } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import AlertMessage from "src/components/alert-message"
import LineChartModalComponent from "src/components/chart/line-chart-modal"
import DatePickerSelect from "src/components/date/date-picker-select"
import LineCheckbox from "src/components/line-checkbox"
import LayoutPage from "src/components/page-layout/layout-page"
import CurveService from "src/services/curve-service"
import { DATE_FORMAT, DATE_FORMAT_SHOW } from "src/utils/constants"
import { localeBRDataGridText } from "src/utils/data-grid-local-text"

const CurvesTirPage = () => {
    const curveService = new CurveService()
    const [curveList, setCurveList] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])
    const [dateSelected, setDateSelected] = useState(null)
    const [dateSelectedShow, setDateSelectedShow] = useState(null)
    const [tableRating, setTableRating] = useState([])
    const [ratingsSelected, setRatingsSelected] = useState([])
    const [openChart, setOpenChart] = useState(false)
    const [csvData, setCsvData] = useState([])
    const [alertMessageState, setAlertMessageState] = React.useState({
        open: false,
        message: "",
        type: "info"
    })
    const [chartConfig, setChartConfig] = useState({
        title: "",
        xTitle: "",
        yTitle: "",
        xValue: [],
        values: []
    })
    const [chartVolConfig, setChartVolConfig] = useState({
        title: "",
        xTitle: "",
        yTitle: "",
        xValue: [],
        values: []
    })

    const [clienteId, setClienteId] = useState(null) // estado para clienteId

    useEffect(() => {
        const date = dayjs()
        setDateSelectedShow(date.format(DATE_FORMAT_SHOW))

        const jwt = localStorage.getItem("jwt")
        if (jwt) {
            document.cookie = `c2p_jwt=${jwt} domain=.c2ps.com.br path=/`
            const payload = JSON.parse(atob(jwt.split(".")[1])) // decodifica o payload do JWT
            if (payload.user && payload.user.IDCliente) {
                setClienteId(payload.user.IDCliente) // atualiza o estado com o IDCliente
            }
        }
    }, []) // executa apenas na montagem do componente

    useEffect(() => {
        const dateFormatted = dayjs().format(DATE_FORMAT)
        loadCurves(dateFormatted)
    }, [clienteId]) // chama sempre que clienteId muda

    const onChangeDatePickerDate = (value) => {
        setDateSelectedShow(value.format(DATE_FORMAT_SHOW))
        setDateSelected(value.format(DATE_FORMAT))
    }

    const onSelectRow = (rows) => {
        let selected = []
        rows.map((item) => {
            selected.push(curveList[item])
        })
        setSelectedRows(selected)
    }

    const loadCurves = (date) => {
        setDateSelected(date)
        setLoading(true)

        const promises = [
            curveService.getCurvesTir(date),
            curveService.getRatingsByClient(clienteId, "TIR"),
        ]

        Promise.all(promises)
            .then((responses) => {
                const [resposta1, respostaTIR] = responses

                if (!resposta1 || !resposta1.body) {
                    console.error("Erro ao obter data de referência das curvas:", resposta1)
                    openAlert("Erro ao obter data de referência das curvas.", "error")
                    return
                }
                
                if (!respostaTIR || !respostaTIR.body) {
                    console.error("Erro ao obter ratings do cliente (TIR):", respostaTIR)
                    openAlert("Erro ao obter ratings do cliente (TIR).", "error")
                    return
                }
                const { data_referencia } = resposta1.body
                setDateSelected(data_referencia)
                setDateSelectedShow(dayjs(data_referencia).format(DATE_FORMAT_SHOW))

                const ratingsCliente =  Array.from(new Set([
                    ...respostaTIR.body.ratings,]))

                const descricoes = Array.from(new Set([
                    ...respostaTIR.body.descricoes,])).sort()

                const rows = descricoes.map((descricao, index) => ({ id: index, grupo: descricao }))
                
                setCurveList(rows)
                setTableRating(ratingsCliente)
                setRatingsSelected(ratingsCliente)
            })
            .catch(() => {
                openAlert("Erro ao carregar dados.", "error")
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const selectRating = (items) => {
        setRatingsSelected(items)
    }

    const validateBody = () => {
        let valid = true

        if (ratingsSelected.length === 0) {
            openAlert("Selecione ao menos um rating", "warning")
            valid = false
        }

        if (selectedRows.length === 0) {
            openAlert("Selecione ao menos um grupo", "warning")
            valid = false
        }

        if (dateSelected === null) {
            openAlert("Selecione uma data válida", "warning")
            valid = false
        }

        return valid
    }

    const loadChartData = async () => {
        setLoading(true) 

        if (!validateBody()) {
            return
        }
        
        const groups = selectedRows.map((grupo) => grupo.grupo)
        curveService.generateCurveChart(groups, ratingsSelected, dateSelected, "TIR").then((response) => {
            const { taxa, volatilidade } = response
            
            setChartConfig({
                title: "Curvas ETTJC", 
                xTitle: taxa.curva.NomeCurva,
                yTitle: "Taxas %",
                xValue: taxa.curva.values,
                values: taxa.grupos
            })

            setChartVolConfig({
                title: "Curvas ETTJC", //tir 
                xTitle: volatilidade.curva.NomeCurva,
                yTitle: "Taxas %",
                xValue: volatilidade.curva.values,
                values: volatilidade.grupos,
            })

            setOpenChart(true)
            setLoading(false)
            setCsvData(taxa.rawData)
        })
        .catch(() => {
            setLoading(false)
            openAlert("Nenhum dado a ser exibido com os parâmetros informados", "warning")
        })

    }

    const closeAlert = () => {
        setAlertMessageState({ ...alertMessageState, open: false })
    }

    const openAlert = (message, type) => {
        setAlertMessageState({
            ...alertMessageState,
            open: true,
            message: message,
            type: type
        })
    }

    const columns = [
        {
            field: 'grupo',
            headerName: 'Descrição',
            flex: true,
            minWidth: 250,
            disableColumnMenu: true
        }
    ]

    return (
        <LayoutPage loading={loading} title="Curvas ETTJC">
            <div>
                <AlertMessage 
                    open={alertMessageState.open} 
                    message={alertMessageState.message} 
                    type={alertMessageState.type} 
                    close={closeAlert} 
                />
                <Grid container direction="row" alignItems={"center"} justifyContent="flex-end" spacing={3}>
                    <Grid item>
                        <DatePickerSelect
                            label="Data de Exibição"
                            onChange={onChangeDatePickerDate}
                            value={dateSelected} 
                        />
                    </Grid>
                    <Grid item>
                        <Button variant="contained" onClick={loadChartData}>
                            Gerar Gráfico
                        </Button>
                    </Grid>
                </Grid>
                <Box marginBottom={6} />
                <Paper>
                    <LineCheckbox
                        items={tableRating}
                        callback={selectRating}
                        allChecked
                    />
                    <br />
                </Paper>
                <Grid container direction="row" justifyContent="center" spacing={2}>
                    <Grid item xl={12} md={12} xs={12}>
                        <Paper>
                            <DataGrid
                                autoHeight
                                checkboxSelection
                                getRowHeight={() => 'auto'}
                                rows={curveList}
                                columns={columns}
                                onRowSelectionModelChange={onSelectRow}
                                sortingOrder={['asc', 'desc']}
                                localeText={localeBRDataGridText}
                            />
                        </Paper>
                    </Grid>
                </Grid>

                <LineChartModalComponent
                    open={openChart}
                    onClose={() => setOpenChart(false)}
                    title={`Curvas ETTJC`} 
                    selectedDate={dateSelectedShow}
                    chartOneData={chartConfig}
                    chartTwoData={chartVolConfig}
                    csvData={csvData}
                    chartOneSelectTitle={"Taxa"}
                    chartTwoSelectTitle={"Volatilidade"}
                />
            </div>
        </LayoutPage>
    )
}

export default CurvesTirPage
