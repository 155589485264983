import { ThemeProvider } from "@emotion/react"
import { Box, CssBaseline } from "@mui/material"
import React, { useEffect } from "react"
import theme from "src/utils/theme"
import PropTypes from 'prop-types'
import AppBarSideMenu from "./app-bar-side-menu"
import SecurityService from "src/services/security-service"
import SessionTimeout from "src/utils/session-timeout"
import Loading from "../loading"
import './scrollbar.css'
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"

const root = {
    display: "relative"
}
dayjs.extend(utc)

const LayoutPage = (props) => {

    const securityService = new SecurityService()

    useEffect(() => {
        document.title = props.title
        securityService.logoutPassTime()
        securityService.logoutInvalidToken()
    }, [props])

    return (
        <ThemeProvider theme={theme}>
            <div style={root}>
                {/* Session Timeout */}
                <SessionTimeout />

                {/* Theme Css */}
                <CssBaseline />

                {/* Side Menu and Page Content */}
                <AppBarSideMenu title={props.title} />

                {/* Page Content */}
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        p: 3,
                        // width: `calc(100% - ${drawerWidth}px)`,
                        marginLeft: 7,
                    }}
                >
                    <div>{props.children}</div>
                </Box>

                <Loading show={props.loading} />

                <div style={{ marginTop: 60 }}></div>
            </div>
        </ThemeProvider>

    )
}

LayoutPage.propTypes = {
    children: PropTypes.element,
    title: PropTypes.string,
    loading: PropTypes.bool,
}


export default LayoutPage