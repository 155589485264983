import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import { Route, HashRouter as Router, Routes } from "react-router-dom"
import AccessPage from './pages/access/AccessPage'
import LoginPage from './pages/auth/login'
import LogoutPage from './pages/auth/logout'
import RecoverPasswordPage from './pages/auth/recover-password'
import RegisterPasswordPage from './pages/auth/register-password'
import ClientPage from './pages/client/client'
import CurveTablePage from './pages/curves/curve-table'
import CurvesDTSPage from './pages/curves/curves-dts'
import CurvesSetorialPage from './pages/curves/curves-setorial'
import CurvesSubsetorialPage from './pages/curves/curves-subsetorial'
import CurvesSpreadPage from './pages/curves/curves-spread'
import CurvesTirPage from './pages/curves/curves-tir'
import HomePage from './pages/dashboard/dashboard'
import NotFound from './pages/default/not-found'
import WithoutPermissionPage from './pages/default/without-permission'
import ImportDataPage from './pages/import/import-data-page'
import DeleteDataPage from './pages/import/delete-data-page'
import MethodologyPage from './pages/methodology/methodology'
import PricePUPage from './pages/prices/price-pu'
import PricePUPARPage from './pages/prices/price-pupar'
import PriceTablePage from './pages/prices/price-table'
import PriceTirPage from './pages/prices/price-tir'
import ProfilePage from './pages/user/profile'
import UserPage from './pages/user/user'
import ConcessionsPage from './pages/receivable-management/concessions'
import WalletsPage from './pages/receivable-management/wallets'
import MarketRatesPage from './pages/receivable-management/market-rates'
import ParametersPage from './pages/receivable-management/parameters'
import PriceChangePage from './pages/price-change/price-change'

import {
  CLIENT_PAGE,
  CURVES_PAGE,
  CURVES_SPREAD_PAGE,
  CURVES_TIR_PAGE,
  HOME_PAGE,
  INDEX_PAGE,
  LOGOUT_PAGE,
  PRICES_PAGE,
  RECOVER_PASSWORD_PAGE,
  REGISTER_PASSWORD_PAGE,
  PROFILE_PAGE,
  USER_PAGE,
  CURVES_SETORIAL_PAGE,
  CURVES_SUBSETORIAL_PAGE,
  CURVES_DTS_PAGE,
  PRICES_PU_PAGE,
  IMPORT_PAGE,
  PRICES_TIR_PAGE,
  PRICES_PUPAR_PAGE,
  CONCESSIONS_PAGE,
  WALLETS_PAGE,
  MARKET_RATES_PAGE,
  PARAMETERS_PAGE,
  ACCESS_PAGE,
  WITHOUT_PERMISSION_PAGE,
  METHODOLOGY_PAGE,
  ROUTINES_PAGE,
  ROUTINES_HOME_PAGE,
  INTEGRATION_WORKFLOW_PAGE,
  REPORT_DATA_WORKFLOW_PAGE,
  INTEGRATION_PAGE,
  INTEGRATION_DATA_PAGE,
  DELETE_DATA_PAGE,
  PRICES_UPDATE_SPREADS,
  API_INTEGRATION_PAGE,
  PARAMETERS_CALC_PAGE,
  SUPPORT_PAGE,
  PARAMETERS_DATALAKE_PAGE,
  PRODUCT_PAGE,
  PRICES_CHARACTERISTICS,
  SIMULADOR_SUBORDINACAO_PAGE,
  PRICE_CHANGE_PAGE,
  EMISSAO_PAGE
} from './utils/routes'
import * as serviceWorker from './utils/service-worker'
import RoutinesPage from './pages/routines/routines'
import RoutinesHomePage from './pages/routines/routines-home'
import IntegrationWorkflowPage from './pages/integration/integration-workflow'
import ReportDataWorkflowPage from './pages/integration/report-data-workflow'
import IntegrationPage from './pages/integration/integration'
import PriceUpdateSpreadsPage from './pages/prices/price-update-spreads'
import ApiPage from './pages/api/api'
import ParameterCalcPage from './pages/parameters/parameters-calc/parameter-calc'
import SupportPage from './pages/support/support'
import ParameterDatalakePage from './pages/parameters/parameters-datalake/parameter-datalake'
import ProductPage from './pages/product/product'
import PriceCharacteristics from './pages/prices/characteristcs/price-characteristics'
import SimuladorSubordinacaoPage from './pages/receivable-management/simulador-subordinacao/simulador-subordinacao'
import EmissaoPage from './pages/emissao/emissao_page'
import IntegrationDataPage from './pages/integration/integration-data'

const rootElement = document.getElementById('root')
const root = ReactDOM.createRoot(rootElement)

root.render(

  <Router>
    <Routes>
      <Route path={INDEX_PAGE} element={<LoginPage />} />
      <Route path={WITHOUT_PERMISSION_PAGE} element={<WithoutPermissionPage />} />
      <Route path={RECOVER_PASSWORD_PAGE} element={<RecoverPasswordPage />} />
      <Route path={REGISTER_PASSWORD_PAGE} element={<RegisterPasswordPage />} />
      <Route path={HOME_PAGE} element={<HomePage />} />
      <Route path={CLIENT_PAGE} element={<ClientPage />} />
      <Route path={PRICES_PAGE} element={<PriceTablePage />} />
      <Route path={ACCESS_PAGE} element={<AccessPage />} />
      <Route path={PRODUCT_PAGE} element={<ProductPage />} />
      <Route path={PRICES_PU_PAGE} element={<PricePUPage />} />
      <Route path={PRICES_UPDATE_SPREADS} element={<PriceUpdateSpreadsPage />} />
      <Route path={PRICES_TIR_PAGE} element={<PriceTirPage />} />
      <Route path={PRICES_PUPAR_PAGE} element={<PricePUPARPage />} />
      <Route path={PRICES_CHARACTERISTICS} element={<PriceCharacteristics />} />
      <Route path={CURVES_PAGE} element={<CurveTablePage />} />
      <Route path={CURVES_TIR_PAGE} element={<CurvesTirPage />} />
      <Route path={CURVES_SPREAD_PAGE} element={<CurvesSpreadPage />} />
      <Route path={CURVES_SETORIAL_PAGE} element={<CurvesSetorialPage />} />
      <Route path={CURVES_SUBSETORIAL_PAGE} element={<CurvesSubsetorialPage />} />
      <Route path={CURVES_DTS_PAGE} element={<CurvesDTSPage />} />
      <Route path={IMPORT_PAGE} element={<ImportDataPage />} />
      <Route path={METHODOLOGY_PAGE} element={<MethodologyPage />} />
      <Route path={ROUTINES_PAGE} element={<RoutinesPage />} />
      <Route path={ROUTINES_HOME_PAGE} element={<RoutinesHomePage />} />
      <Route path={INTEGRATION_WORKFLOW_PAGE} element={<IntegrationWorkflowPage />} />
      <Route path={REPORT_DATA_WORKFLOW_PAGE} element={<ReportDataWorkflowPage />} /> 
      <Route path={INTEGRATION_DATA_PAGE} element={<IntegrationDataPage />} />
      <Route path={INTEGRATION_PAGE} element={<IntegrationPage />} />
      <Route path={DELETE_DATA_PAGE} element={<DeleteDataPage />} />
      <Route path={USER_PAGE} element={<UserPage />} />
      <Route path={PROFILE_PAGE} element={<ProfilePage />} />
      <Route path={SUPPORT_PAGE} element={<SupportPage />} />
      <Route path={LOGOUT_PAGE} element={<LogoutPage />} />
      <Route path={CONCESSIONS_PAGE} element={<ConcessionsPage />} />
      <Route path={WALLETS_PAGE} element={<WalletsPage />} />
      <Route path={SIMULADOR_SUBORDINACAO_PAGE} element={<SimuladorSubordinacaoPage />} />
      <Route path={MARKET_RATES_PAGE} element={<MarketRatesPage />} />
      <Route path={PARAMETERS_PAGE} element={<ParametersPage />} />
      <Route path={PARAMETERS_CALC_PAGE} element={<ParameterCalcPage />} />
      <Route path={PARAMETERS_DATALAKE_PAGE} element={<ParameterDatalakePage />} />
      <Route path={API_INTEGRATION_PAGE} element={<ApiPage />} />
      <Route path={PRICE_CHANGE_PAGE} element={<PriceChangePage />} />
      <Route path={EMISSAO_PAGE} element={<EmissaoPage />} />
      <Route element={<NotFound />} />
    </Routes>
  </Router>

)

serviceWorker.unregister()