import React, { useEffect, useState } from "react"
import { formatDateDataGrid } from "src/utils/format-date"
import PropTypes from 'prop-types'
import ParameterPaginationTable from "src/components/table/parameter-pagination-table"
import DatalakeService from "src/services/datalake-service"
//import dayjs from "dayjs"
//import { DATE_FORMAT } from "src/utils/constants"

const ParameterDatalakePrecosInadimplentesLGD= (props) => {
    const dataLakeService = new DatalakeService()
    const [data, setData] = useState([])
    const [pagination, setPagination] = useState({ count: 0, next: null, previous: null })

    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        props.loading(true)
        dataLakeService.loadPaginationEndpoint(props.endpoint).then((response) => {
            props.loading(false)

            const { body } = response
            const { results, count, next, previous } = body
            const list = results.map((obj, index) => ({ ...obj, id: index }))
            setPagination({ count: count, next: next, previous: previous })
            setData(list)
        })
    }

    const createRow = async () => {
        const body = {
            "CETIP": null,
            "DataReferencia": null,
            "DataValidade": null,
            "LGD": null, 
            "StopAccrual": null,
        }


        props.loading(true)
        return dataLakeService.createTableEndpoint(props.endpoint, body).then(() => {
            props.loading(false)
            loadData()
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const updateRow = async (newRow) => {
        const { ID, CETIP, DataReferencia, DataValidade, LGD, StopAccrual} = newRow

        const body = {
            "CETIP": CETIP,
            "DataReferencia": DataReferencia,
            "DataValidade": DataValidade,
            "LGD": LGD,
            "StopAccrual": StopAccrual,
        }

        props.loading(true)
        return dataLakeService.updateTableEndpoint(props.endpoint, ID, body).then(() => {
            props.loading(false)
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const deleteRow = async (row) => {
        props.loading(true)
        const { ID } = row
        return dataLakeService.deleteTableEndpoint(props.endpoint, ID).then(() => {
            props.loading(false)
            const listRemoved = data.filter((item) => item.ID !== ID)
            setData(listRemoved)
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const onFilter = async (filterList, limit, offset) => {
        const query = filterList.map(obj => `${encodeURIComponent(obj.field)}=${encodeURIComponent(obj.value)}`).join('&')

        props.loading(true)
        dataLakeService.loadPaginationEndpoint(props.endpoint, query, limit, offset).then((response) => {
            props.loading(false)
            const { body } = response
            const { results, count, next, previous } = body
            const list = results.map((obj) => ({ ...obj, id: obj.ID }))
            setPagination({ count: count, next: next, previous: previous })
            setData(list)
        })
    }

    const changePage = async (endpoint) => {
        props.loading(true)
        dataLakeService.paginate(endpoint).then((response) => {
            props.loading(false)
            const { body } = response
            const { results, count, previous, next } = body
            const list = results.map((obj) => ({ ...obj, id: obj.ID }))

            setPagination({ count: count, next: next, previous: previous })
            setData([])
            setData(list)
        })
    }

    const columns = [
        {
            field: 'ID',
            headerName: '#',
            disableColumnMenu: true,
            disableFilter: true,
            flex: true,
            minWidth: 80,
        },
        {
            field: 'CETIP',
            headerName: 'CETIP',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'DataReferencia',
            headerName: 'DataReferencia',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'date',
            disableColumnMenu: true,
            editable: true,
        },
        {
            field: 'DataValidade',
            headerName: 'DataValidade',
            lex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'date',
            disableColumnMenu: true,
            editable: true,
        },
        {
            field: 'LGD',
            headerName: 'LGD',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'CriadoEm',
            headerName: 'Data de Criação',
            flex: true,
            minWidth: 150,
            disableColumnMenu: true,
            disableFilter: true,
            renderCell: (params) => formatDateDataGrid(params)
        },
        {
            field: 'AtualizadoEm',
            headerName: 'Data de Atualização',
            flex: true,
            minWidth: 150,
            disableColumnMenu: true,
            disableFilter: true,
            renderCell: (params) => formatDateDataGrid(params)
        },
        {
            field: 'StopAccrual',
            headerName: 'StopAccrual',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        }
    ]

    return (
        <div>
            <ParameterPaginationTable
                columns={columns}
                data={data}
                setData={setData}
                createRow={createRow}
                deleteRow={deleteRow}
                updateRow={updateRow}
                filterFunction={onFilter}
                pagination={pagination}
                onChangePage={changePage}
                customFilter={true} />
        </div>
    )
}

ParameterDatalakePrecosInadimplentesLGD.propTypes = {
    loading: PropTypes.func,
    endpoint: PropTypes.string,
}

export default ParameterDatalakePrecosInadimplentesLGD
