import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import React from "react"
import theme from "src/utils/theme"
import PropTypes from 'prop-types'
import { formatStringToFloatDecimalCase } from "src/utils/format-decimal"
import { DATE_FORMAT_SHOW } from "src/utils/constants"
import dayjs from "dayjs"

const PriceMemoryResultTable = (props) => {

    const headItems = [
        'CETIP',
        'ISIN',
        'Data Referência',
        'Indicador',
        'Taxa Accrual',
        'Taxa MTM',
        'Spread Crédito',
        'Duration DU',
        'PU MTM',
        'PUPAR',
        '%PUPAR'
    ]

    const getDate = (value) => {
        if (props.data == null || props.data[value] == null) {
            return null
        }

        return dayjs(props.data[value]).format(DATE_FORMAT_SHOW)
    }


    const getValueFormated = (value) => {
        if (props.data == null || props.data[value] == null) {
            return null
        }

        return formatStringToFloatDecimalCase(props.data[value])
    }


    const getValue = (value) => {
        if (props.data == null) {
            return null
        }

        return props.data[value]
    }

    const tableHeadItem = (title) => <TableCell align="center" sx={{ color: "#fff", fontWeight: 'bold', border: '1px solid black', backgroundColor: theme.palette.primary.main }}>{title}</TableCell>
    const tableItem = (value) => <TableCell align="center" sx={{ border: '1px solid black' }}>{value}</TableCell>

    return (
        <Paper>
            <TableContainer sx={{
                maxHeight: 400,
                '&::-webkit-scrollbar': {
                    width: '8px',
                    height: '10px',
                },
            }}>
                <Table ref={props.tableRef}>
                    <TableHead sx={{ backgroundColor: theme.palette.primary.main }}>
                        <TableRow>
                            {headItems.map((item) => tableHeadItem(item))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            {tableItem(getValue('CETIP'))}
                            {tableItem(getValue('ISIN'))}
                            {tableItem(getDate('DataReferencia'))}
                            {tableItem(getValue('Indicador'))}
                            {tableItem(getValueFormated('TaxaAccrual'))}
                            {tableItem(getValueFormated('TaxaMTM'))}
                            {tableItem(getValueFormated('SpreadCredito'))}
                            {tableItem(getValueFormated('DurationDU'))}
                            {tableItem(getValueFormated('PU_MTM'))}
                            {tableItem(getValueFormated('PUPAR'))}
                            {tableItem(getValueFormated('%PUPAR'))}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}


PriceMemoryResultTable.propTypes = {
    data: PropTypes.object,
    tableRef: PropTypes.any
}


export default PriceMemoryResultTable