import { Grid, Typography } from '@mui/material'
import * as React from 'react'
import LayoutPage from 'src/components/page-layout/layout-page'

const HomePage = () => {

    return (
        <LayoutPage title="Home">
            <Grid container>
                <Grid item>
                    <Typography variant={"h3"}>Bem Vindo à C2P</Typography>
                </Grid>
            </Grid>
        </LayoutPage>
    )
}

export default HomePage