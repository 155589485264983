import * as React from 'react'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

export const Copyright = () => {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      <Link color="inherit" href="https://c2ps.com.br">
        Copyright © {new Date().getFullYear()}
      </Link>
    </Typography>
  )
}
