import React from "react"
import { Tooltip } from "@mui/material"
import { GridEditInputCell } from "@mui/x-data-grid"
import PropTypes from "prop-types"

function EditInputCell(props) {
    const { error } = props

    return (
        <>
            <Tooltip placement="bottom-start" open={!!error} title={error}>
                <span>
                    <GridEditInputCell {...props} />
                </span>
            </Tooltip>
        </>

    )
}

export default EditInputCell

EditInputCell.propTypes = {
    error: PropTypes.any
}
