import { CETIPS_PATH, PRICES_CLIENT_PATH } from "src/utils/api-routes"
import { ServiceRequest } from "src/utils/service-request"

export default class CetipService {

    constructor() {
        this.serviceRequest = new ServiceRequest()
    }

    async getCetips() {
        return await this.serviceRequest.get(`${CETIPS_PATH}`)
    }

    async registerNewCetips(newCetip) {
        return this.serviceRequest.post(`${PRICES_CLIENT_PATH}`, newCetip)
    }

    async getClientsFromCetip(cetip) {
        const query = `CETIP=${cetip}&limit=10000`
        return this.serviceRequest.get(`${PRICES_CLIENT_PATH}?${query}`)
    }

    async deleteCetip(id) {
        return this.serviceRequest.delete(`${PRICES_CLIENT_PATH}/${id}`)
    }


}