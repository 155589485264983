import React from "react"
import { Button, Grid } from "@mui/material"
import PropTypes from 'prop-types'
import ReceivableService from "src/services/receivable-service"

/* eslint-disable */
const ReportButtons = ({ openAlert, disabled, reportLinks, loading }) => {
    const receivableService = new ReceivableService()
    const handleClick = async (url, type) => {
        loading(true)
        const link = document.createElement("a")
        link.target = "_blank"

        try {
            const response = await receivableService.downloadFile(url)
            link.download = response.headers['content-disposition']
                .split('=')[1]
                .replace(/"/g, '')
            link.href = URL.createObjectURL(
                new Blob([response.body], { type: type })
            );
            link.click()
        } catch (error) {
            openAlert("Error ao abrir arquivo. Tente novamente em instantes ou contate o administrador.", "error")
        } finally {
            loading(false)
        }
    }

    return <Grid container spacing={1}>
        <Grid item>
            <Button disabled={disabled} onClick={() => handleClick(reportLinks.pdf, 'application/pdf')} variant="outlined" color="error" size="small">PDF</Button>
        </Grid>
        <Grid item>
          <Button disabled={disabled} onClick={() => handleClick(reportLinks.csv, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')} variant="outlined" color="success" size="small">EXCEL</Button>
        </Grid>
    </Grid>
}
/* eslint-enable */

export default ReportButtons


ReportButtons.propTypes = {
    children: PropTypes.element,
    disabled: PropTypes.bool,
    reportLinks: PropTypes.shape({
        csv: PropTypes.string,
        pdf: PropTypes.string
    }),
    loading: PropTypes.func
}