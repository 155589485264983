import { Alert, Autocomplete, Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import InputNumberMask from "src/components/input-number-mask"
import ClientService from "src/services/client-service"
import UserService from "src/services/user-service"
import { captalize } from "src/utils/captalize"
import { CELLPHONE_MASK } from "src/utils/constants"
import PropTypes from 'prop-types'
import Loading from "src/components/loading"
import { TokenService } from "src/services/token-service"

const RegisterUserPage = (props) => {
    const clientService = new ClientService()
    const userService = new UserService()
    const tokenService = new TokenService()
    const [clientList, setClientList] = useState([])
    const [alert, setAlert] = useState(false)
    const [load, setLoad] = useState(false)
    const [user, setUser] = useState({
        Id: "",
        username : "",
        name: "",
        client: null,
        email: "",
        phone: "",
        admin: false
    })
    const [validateInput, setValidateInput] = useState({
        name: false,
        client: false,
        email: false,
        phone: false
    })

    useEffect(() => {
        loadClients()
    }, [])

    const loadEditableuser = (clients, userId) => {
        setLoad(true)

        userService.getuser(userId).then((response) => {
            setLoad(false)
            const { body } = response
            const { NomeCompleto, Telefone, username, ID, IDCliente, is_staff } = body
            const clientFromUser = clients.filter(client => client.id === IDCliente)


            setUser({
                id: ID,
                name: NomeCompleto,
                phone: Telefone,
                email: username,
                client: clientFromUser[0] || null,
                admin: is_staff
            })
        }).catch(() => {
            setLoad(false)
        })

    }

    const loadClients = async () => {
        clientService.getClients(1000, 0).then((response) => {
            const { body } = response
            const { results } = body
            const clients = results.map((item) => ({ label: captalize(item.Nome), id: item.ID }))
            setClientList(clients)

            if (props.userSelected != null) {
                const { ID } = props.userSelected
                loadEditableuser(clients, ID)
            }
        }).catch(() => {
            setLoad(false)
        })
    }

    const validate = () => {
        if (user.name === "") {
            setValidateInput({ ...validateInput, name: true })
            return false
        }
        if (user.email === "") {
            setValidateInput({ ...validateInput, email: true })
            return false
        }
        if (user.phone === "") {
            setValidateInput({ ...validateInput, phone: true })
            return false
        }
        if (user.client === null) {
            setValidateInput({ ...validateInput, client: true })
            return false
        }
        if (user.levelAccess === "") {
            setValidateInput({ ...validateInput, levelAccess: true })
            return false
        }

        return true
    }

    const registerUser = () => {
        if (validate()) {
            setLoad(true)
            userService.createUser(user).then(() => {
                setLoad(false)
                setAlert(false)
                props.onSuccess()
            }).catch(() => {
                setLoad(false)
                setAlert(true)
            })
        }
    }

    const updateUser = () => {
        if (validate()) {
            setLoad(true)
            userService.updateUser(user.id, user).then(() => {
                setLoad(false)
                setAlert(false)
                props.onSuccess()
            }).catch(() => {
                setLoad(false)
                setAlert(true)
            })
        }
    }

    return (
        <Grid container spacing={0}>
            <Loading show={load} />
            <Grid item sm={12} md={12} xl={12}>

                {/* user data */}
                <Typography variant="h6">
                    Dados do Usuário
                </Typography>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={12} xl={12} xs={12}>
                        <TextField
                            name="register-user-name"
                            InputLabelProps={{ shrink: true }}
                            error={validateInput.name}
                            fullWidth
                            value={user.name}
                            onChange={(event) => setUser({ ...user, name: event.target.value })}
                            placeholder="Informe o nome do usuário"
                            label="Nome"
                            variant="standard" />
                    </Grid>
                </Grid>
                <br />
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={3}>
                    <Grid item sm={12} md={12} xl={6} xs={12}>
                        <TextField
                            name="register-user-email"
                            error={validateInput.email}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value={user.email}
                            placeholder="Informe o email do usuário"
                            label="Email"
                            onChange={(event) => setUser({ ...user, email: event.target.value })}
                            variant="standard" />
                    </Grid>
                    <Grid item sm={6} md={6} xl={6} xs={12}>
                        <InputNumberMask
                            value={user.phone}
                            name={"register-user-phone"}
                            mask={CELLPHONE_MASK}
                            error={validateInput.phone}
                            label="Telefone"
                            onChange={(value) => setUser({ ...user, phone: value })} />
                    </Grid>
                </Grid>
                <br />
                <Grid container spacing={2} direction="column">
                    <Grid item sm={12} md={6} xl={6} xs={12}>
                        <Autocomplete
                            disablePortal
                            id="register-user-client"
                            value={user.client}
                            onChange={(event, value) => setUser({ ...user, client: value })}
                            options={clientList}
                            renderInput={(params) => <TextField
                                {...params}
                                fullWidth
                                error={validateInput.client}
                                variant="standard"
                                label="Cliente" />}
                        />
                    </Grid>
                    {tokenService.verifyUserIsAdmin() ?
                        <Grid item sm={6} md={6} xl={6} xs={12}>
                            <FormControlLabel
                                control={<Checkbox checked={user.admin} onChange={(event) => setUser({ ...user, admin: event.target.checked })} />}
                                label="Administrador"
                            />
                        </Grid>
                        : null}
                </Grid>

                <br />
                {alert ?
                    <div>
                        <Alert severity="warning">Ops, houve um erro ao tentar relizar a operação, tente novamente mais tarde.</Alert>
                        <br />
                    </div>
                    : null}

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={0}>
                    <Grid item>
                        <Button id="register-button" variant="contained" onClick={props.userSelected ? updateUser : registerUser}>
                            {props.userSelected !== null ? "Atualizar" : "Cadastrar"}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    )
}

RegisterUserPage.propTypes = {
    onSuccess: PropTypes.func,
    userSelected: PropTypes.object
}

export default RegisterUserPage