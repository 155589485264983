import { ExportFile } from "src/utils/export-file"

export class MethodologyService {
    constructor() {
        this.exportFile = new ExportFile()
    }

    downloadPdf(path, file) {
        this.exportFile.downloadPdf(path, file)
    }
}