import { CALC_DATA_RECENTE_CHARACTERISTICS, CALC_ISSUE_CHARACTERISTICS, CALC_REPORT_ERROR, POST_GUARANTEE_CALC, POST_MEMORY_CALC_PUMTM, POST_MEMORY_CALC_PUPAR, POST_MEMORY_RESULT_PRICE, POST_PARAMETERS_CALC } from "src/utils/api-routes"
import { ServiceRequest } from "src/utils/service-request"
import { TokenService } from "./token-service"
import * as XLSX from 'xlsx-js-style'
import { puparHeadItems } from "src/pages/prices/characteristcs/table-memory/price-memory-pupar-calc"
import { puMtmHeadItems } from "src/pages/prices/characteristcs/table-memory/price-memory-pu-mtm-calc"
import { formatDecimalCaseReplaceComma } from "src/utils/format-decimal"

class CharacteristicsService {

    constructor() {
        this.request = new ServiceRequest()
        this.tokenService = new TokenService()
    }

    async getDataMaisRecente(cetipIsin) {
        const body = {
            'codigo_cetip_ou_isin': cetipIsin
        }
        return this.request.post(CALC_DATA_RECENTE_CHARACTERISTICS, body)
    }

    async getCharacteristics(cetipIsin) {
        const body = {
            'codigo_cetip_ou_isin': cetipIsin
        }

        return this.request.post(CALC_ISSUE_CHARACTERISTICS, body)
    }

    async getParametersCalc(cetipIsin, dataReferencia) {
        const body = {
            'codigo_cetip_ou_isin': cetipIsin,
            'data_referencia': dataReferencia
        }

        return this.request.post(POST_PARAMETERS_CALC, body)
    }

    async getGuaranteeCalc(cetipIsin) {
        const body = {
            'codigo_cetip_ou_isin': cetipIsin,
        }

        return this.request.post(POST_GUARANTEE_CALC, body)
    }

    async getMemoryCalcResultPrice(cetipIsin, dataReferencia) {
        const body = {
            'codigo_cetip_ou_isin': cetipIsin,
            'data_referencia': dataReferencia
        }

        return this.request.post(POST_MEMORY_RESULT_PRICE, body)
    }


    async getMemoryCalcPUPAR(cetipIsin, dataReferencia) {
        const body = {
            'cetip': cetipIsin,
            'data_referencia': dataReferencia
        }

        return this.request.post(POST_MEMORY_CALC_PUPAR, body)
    }


    async getMemoryCalcMTM(cetipIsin, dataReferencia) {
        const body = {
            'cetip': cetipIsin,
            'data_referencia': dataReferencia
        }

        return this.request.post(POST_MEMORY_CALC_PUMTM, body)
    }


    async postReportError(cetip, message) {
        const { user } = this.tokenService.decodeToken()
        const { username, Cliente, NomeCompleto } = user
        const { Nome } = Cliente

        const body = {
            'title': "Reporte de Erro em Características",
            'data': {
                'client': Nome,
                'name': NomeCompleto,
                'username': username,
                'cetip/isin': cetip,
                'message': message
            }
        }

        return this.request.post(CALC_REPORT_ERROR, body)
    }

    headerCell(value) {
        return {
            v: value,
            t: "s",
            s: {
                fill: { fgColor: { rgb: "222851" } },
                font: { color: { rgb: "FFFFFF", sz: 11, bold: true } },
                alignment: { wrapText: true, horizontal: "center", vertical: "center" },
                border: {
                    top: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } }
                },
            }
        }
    }

    valueCell(value) {
        console.log(typeof value)
        return {
            v: typeof value === 'number' ? formatDecimalCaseReplaceComma(value) : value,
            t: "s",
            s: {
                fill: { fgColor: { rgb: "FFFFFF" } },
                font: { color: { rgb: "000000", sz: 11 } },
                alignment: { wrapText: true, horizontal: "center", vertical: "center" },
                border: {
                    top: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } }
                },
            }
        }
    }

    getExcelColumnName(colIndex) {
        let dividend = colIndex + 1
        let columnName = ''
        let modulo
        while (dividend > 0) {
            modulo = (dividend - 1) % 26
            columnName = String.fromCharCode(65 + modulo) + columnName
            dividend = Math.floor((dividend - modulo) / 26)
        }
        return columnName
    }

    getValue(data, value, { defaultValue = "" } = {}) {
        if (data[value] === undefined) {
            return defaultValue
        }

        return data[value] !== null ? data[value] : defaultValue
    }

    async exportMemoryExcel(cetip, memoryCalcValues, puparItems, pumtmItems) {
        const bodyTable = [
            { key: "CETIP", value: this.getValue(memoryCalcValues, "CETIP") },
            { key: "ISIN", value: this.getValue(memoryCalcValues, "ISIN") },
            { key: "Data Referência", value: this.getValue(memoryCalcValues, "DataReferencia") },
            { key: "Indicador", value: this.getValue(memoryCalcValues, "Indicador") },
            { key: "Taxa Accrual", value: this.getValue(memoryCalcValues, "TaxaAccrual") },
            { key: "Taxa MTM", value: this.getValue(memoryCalcValues, "TaxaMTM") },
            { key: "Spread Crédito", value: this.getValue(memoryCalcValues, "SpreadCredito") },
            { key: "Duration DU", value: this.getValue(memoryCalcValues, "DurationDU") },
            { key: "PU MTM", value: this.getValue(memoryCalcValues, "PU_MTM") },
            { key: "PUPAR", value: this.getValue(memoryCalcValues, "PUPAR") },
            { key: "%PUPAR", value: this.getValue(memoryCalcValues, "%PUPAR") },
            { key: "Tipo Juros PUPAR", value: this.getValue(memoryCalcValues, "TipoJurosPUPAR") },
            { key: "Tipo Capitalização PUPAR", value: this.getValue(memoryCalcValues, "TipoCapitalizacaoPUPAR") },
            { key: "Tipo Juros PU MTM", value: this.getValue(memoryCalcValues, "TipoJurosPU_MTM") },
            { key: "Tipo Capitalização PU MTM", value: this.getValue(memoryCalcValues, "TipoCapitalizacaoPU_MTM") },
            { key: "Início Rentabilidade", value: this.getValue(memoryCalcValues, "InicioRentabilidade") },
            { key: "Fim Rentabilidade", value: this.getValue(memoryCalcValues, "FimRentabilidade") },
            { key: "Valor Nominal", value: this.getValue(memoryCalcValues, "ValorNominal") },
            { key: "Defasagem", value: this.getValue(memoryCalcValues, "Defasagem") },
            { key: "Inflação Anual", value: this.getValue(memoryCalcValues, "InflacaoAnual") },
            { key: "Mês Aniversário", value: this.getValue(memoryCalcValues, "MesAniversario") },
            { key: "Projeção Inflação", value: this.getValue(memoryCalcValues, "ProjecaoInflacao") },
            { key: "Inflação Negativa", value: this.getValue(memoryCalcValues, "InflacaoNegativa") },
        ]

        const memoryPuparCalcBodyTable = {
            title: "Memória de Cálculo PUPAR",
            header: puparHeadItems,
            items: puparItems
        }

        const memoryPuMtmCalcBodyTable = {
            title: "Memória de Cálculo PU MTM",
            header: puMtmHeadItems,
            items: pumtmItems
        }

        let workbook = XLSX.utils.book_new()
        let worksheet = {}

        bodyTable.map((item, index) => {
            worksheet[`A${index + 1}`] = this.headerCell(item.key)
            worksheet[`B${index + 1}`] = this.valueCell(item.value)
        })

        worksheet[`D1`] = this.headerCell(memoryPuparCalcBodyTable.title)
        memoryPuparCalcBodyTable.header.map((item, index) => {
            let columnLetter = String.fromCharCode('D'.charCodeAt(0) + index)
            let cellAddress = `${columnLetter}2`
            worksheet[cellAddress] = this.headerCell(item)
        })
        memoryPuparCalcBodyTable.items.map((item, index) => {
            let columnIndex = index + 3
            Object.keys(item).forEach((key, index) => {
                let columnLetter = String.fromCharCode('D'.charCodeAt(0) + index)
                let cellAddress = `${columnLetter}${columnIndex}`

                worksheet[cellAddress] = this.valueCell(this.getValue(item, key))
            })
        })

        worksheet[`Z1`] = this.headerCell(memoryPuMtmCalcBodyTable.title)
        memoryPuMtmCalcBodyTable.header.map((item, index) => {
            let columnLetter = this.getExcelColumnName(25 + index) // 'Z' corresponde a 25
            let cellAddress = `${columnLetter}2`
            worksheet[cellAddress] = this.headerCell(item)
        })
        memoryPuMtmCalcBodyTable.items.map((item, index) => {
            let columnIndex = index + 3
            Object.keys(item).forEach((key, itemIndex) => {
                let columnLetter = this.getExcelColumnName(25 + itemIndex) // 'Z' corresponde a 25
                let cellAddress = `${columnLetter}${columnIndex}`
                worksheet[cellAddress] = this.valueCell(this.getValue(item, key))
            })
        })

        worksheet['!ref'] = "A1:AY9999"
        // Defina a largura das colunas

        let cols = new Array(50).fill({ wch: 18 }) // 50 colunas de A a AX
        cols[0] = { wch: 30 } // Coluna C (índice 2) com largura "normal"
        cols[1] = { wch: 30 } // Coluna C (índice 2) com largura "normal"
        cols[2] = { wch: 10 } // Coluna C (índice 2) com largura "normal"
        cols[24] = { wch: 10 } // Coluna Y (índice 24) com largura "normal"
        worksheet['!cols'] = cols

        // Mesclar colunas de D1 a Z1
        worksheet['!merges'] = [
            { s: { r: 0, c: 3 }, e: { r: 0, c: 23 } },
            { s: { r: 0, c: 25 }, e: { r: 0, c: 45 } }
        ]

        // Adicione a worksheet ao workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, "C2P")

        // Escreva o arquivo Excel atualizado
        XLSX.writeFile(workbook, `MemoriaCalculo_${cetip}.xlsx`)

    }

    exportEmissaoExcel(cetip, data) {
        let ws = XLSX.utils.json_to_sheet(data)
        let fileName = `CaracteristicasEmissao_${cetip}.xlsx`

        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'C2P')
        XLSX.writeFile(wb, fileName)
    }

}

export default CharacteristicsService