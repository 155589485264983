import React from 'react'
import { Grid, IconButton, Typography } from '@mui/material'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import PropTypes from 'prop-types'

const ParameterCustomFooter = (props) => {
    const handleBackButtonClick = () => {
        props.onChagePage(props.previous, false)
    }

    const handleNextButtonClick = () => {
        props.onChagePage(props.next, true)
    }

    return (
        <Grid container direction={"row"} justifyContent="flex-end" alignItems="center" padding={2}>
            <Grid item>
                <Typography>
                    Exibindo {props.init}-{props.end} de {props.total ? props.total : 0}
                </Typography>
            </Grid>
            <Grid item>
                <IconButton onClick={handleBackButtonClick} disabled={props.previous === null}>
                    <KeyboardArrowLeft />
                </IconButton>
            </Grid>
            <Grid item>
                <IconButton onClick={handleNextButtonClick} disabled={props.next === null}>
                    <KeyboardArrowRight />
                </IconButton>
            </Grid>
        </Grid>
    )
}

ParameterCustomFooter.propTypes = {
    init: PropTypes.number,
    end: PropTypes.number,
    total: PropTypes.number,
    next: PropTypes.string,
    previous: PropTypes.string,
    onChagePage: PropTypes.func
}


export default ParameterCustomFooter
