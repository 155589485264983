import * as React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@mui/material'
import ReactInputMask from 'react-input-mask'

const InputNumberMask = (props) => {

  const changeValue = (event) => {
    props.onChange(event.target.value)
  }

  return (
    <ReactInputMask
      mask={props.mask}
      value={props.value}
      disabled={props.error}
      onChange={changeValue}
      maskChar=" "
    >
      {() => <TextField
        fullWidth
        name={props.name ? props.name : ""}
        InputLabelProps={{ shrink: true }}
        error={props.error}
        helperText={props.helperText ? props.helperText : ""}
        placeholder={props.placeholder}
        label={props.label}
        variant={props.variant ? props.variant : "standard"} />}
    </ReactInputMask>
  )
}


InputNumberMask.propTypes = {
  mask: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  variant: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.bool,
  name: PropTypes.string
}

export default InputNumberMask
