import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import DatalakeService from "src/services/datalake-service"
import dayjs from "dayjs"
import { DATE_FORMAT, DATE_FORMAT_SHOW } from "src/utils/constants"
import ParameterTable from "src/components/table/parameter-table"

const ParameterDatalakeRatingDevedorTable = (props) => {
    const calcService = new DatalakeService()
    const [data, setData] = useState([])

    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        props.loading(true)
        calcService.loadEndpoint(props.endpoint).then((response) => {
            props.loading(false)
            console.log(response)
            const { body } = response
            const { results } = body
            const list = results.map((obj, index) => ({ ...obj, id: index }))

            setData(list)
        })
    }

    const createRow = async () => {
        const body = {
            "DataReferencia": dayjs().format(DATE_FORMAT),
            "DataValidade": dayjs().format(DATE_FORMAT),
            "CPFCNPJ" : "N/A",
            "Devedor": "N/A",
            "Setor": "N/A",
            "SubSetor": "N/A",
            "Fonte": "N/A",
            "ScoreRating": 0,
            "Rating": "N/A",
            "Sinal": "N/A",
            "Perspectiva": "N/A",
            "Observacao": "N/A",
            "AnaliseCreditoDevedor": "N/A"
        }

        props.loading(true)
        return calcService.createTableEndpoint(props.endpoint, body).then(() => {
            props.loading(false)
            loadData()
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const updateRow = async (newRow) => {
        const { ID, DataReferencia, DataValidade, CPFCNPJ, Devedor, Setor, SubSetor, Fonte,
            ScoreRating, Rating, Sinal, Perspectiva, Observacao, AnaliseCreditoDevedor
        } = newRow

        const body = {
            "DataReferencia": DataReferencia,
            "DataValidade": DataValidade,
            "CPFCNPJ" : CPFCNPJ,
            "Devedor": Devedor,
            "Setor": Setor,
            "SubSetor": SubSetor,
            "Fonte": Fonte,
            "ScoreRating": ScoreRating,
            "Rating": Rating,
            "Sinal": Sinal,
            "Perspectiva": Perspectiva,
            "Observacao": Observacao,
            "AnaliseCreditoDevedor": AnaliseCreditoDevedor
        }

        props.loading(true)
        return calcService.updateTableEndpoint(props.endpoint, ID, body).then(() => {
            props.loading(false)
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const deleteRow = async (row) => {
        const { ID } = row
        return calcService.deleteTableEndpoint(props.endpoint, ID).then(() => {
            props.loading(false)
            loadData()
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const columns = [
        {
            field: 'ID',
            headerName: '#',
            flex: true,
        },
        {
            field: 'DataReferencia',
            headerName: 'DataReferencia',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            editable: true,
            typeComponent: 'date'
        },
        {
            field: 'DataValidade',
            headerName: 'DataValidade',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'date',
            editable: true
        },

        {
            field: 'CPFCNPJ',
            headerName: 'CPFCNPJ',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            editable: true
        },
        {
            field: 'Devedor',
            headerName: 'Devedor',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            editable: true
        },
        {
            field: 'Setor',
            headerName: 'Setor',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            editable: true
        },
        {
            field: 'SubSetor',
            headerName: 'SubSetor',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            editable: true
        },
        {
            field: 'Fonte',
            headerName: 'Fonte',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            editable: true
        },
        {
            field: 'ScoreRating',
            headerName: 'ScoreRating',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            editable: true
        },
        {
            field: 'Rating',
            headerName: 'Rating',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            editable: true
        },
        {
            field: 'Sinal',
            headerName: 'Sinal',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            editable: true
        },
        {
            field: 'Perspectiva',
            headerName: 'Perspectiva',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            editable: true
        },
        {
            field: 'Observacao',
            headerName: 'Observacao',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            editable: true
        },
        {
            field: 'AnaliseCreditoDevedor',
            headerName: 'AnaliseCreditoDevedor',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            editable: true
        },
        {
            field: 'CriadoEm',
            headerName: 'Data de Criação',
            flex: true,
            minWidth: 150,
            type: 'date',
            valueGetter: ({ value }) => value && dayjs(value).toDate(),
            valueFormatter: params => dayjs(params.value).format(DATE_FORMAT_SHOW),
        },
        {
            field: 'AtualizadoEm',
            headerName: 'Data de Atualização',
            flex: true,
            minWidth: 150,
            type: 'date',
            valueGetter: ({ value }) => value && dayjs(value).toDate(),
            valueFormatter: params => dayjs(params.value).format(DATE_FORMAT_SHOW),
        }
    ]

    return (
        <div>
            <ParameterTable
                columns={columns}
                data={data}
                setData={setData}
                createRow={createRow}
                deleteRow={deleteRow}
                updateRow={updateRow}
                loadData={loadData} />
        </div>
    )
}

ParameterDatalakeRatingDevedorTable.propTypes = {
    loading: PropTypes.func,
    endpoint: PropTypes.string,
}

export default ParameterDatalakeRatingDevedorTable
