import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import React from "react"
import PropTypes from 'prop-types'
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded'
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded'

const DataTable = (props) => {

    const headDataTable = props.head.map((head, index) => (
        <TableCell align="left" key={index}>
            {head}
        </TableCell>
    ))

    const nextPage = () => {
        props.nextPage()
    }

    const previousPage = () => {
        props.previousPage()
    }

    return (
        <TableContainer component={props.component ? props.component : Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        {headDataTable}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.body.length === 0 ?
                        <TableRow>
                            <TableCell>
                                <Typography align="left">
                                    Nenhum ítem a ser exibido
                                </Typography>
                            </TableCell>
                        </TableRow>
                        :
                        props.body}
                </TableBody>
            </Table>
            {
                props.pagination && props.body.length !== 0 ?
                    <Grid container direction={"row"} alignContent={"flex-end"} justifyContent={"flex-end"} spacing={0}>
                        <Grid item>
                            <Button disabled={props.disablePreviousPage} onClick={previousPage}>
                                <NavigateBeforeRoundedIcon />
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={nextPage}>
                                <NavigateNextRoundedIcon />
                            </Button>
                        </Grid>
                    </Grid> : null
            }

        </TableContainer>
    )
}

DataTable.propTypes = {
    children: PropTypes.element,
    body: PropTypes.array,
    head: PropTypes.array,
    nextPage: PropTypes.func,
    previousPage: PropTypes.func,
    pagination: PropTypes.bool,
    disablePreviousPage: PropTypes.bool,
    component: PropTypes.element
}

export default DataTable