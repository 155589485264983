import { red } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'

// A custom theme for this app
const theme = createTheme({
  palette: {
    gradient: {
      main: "linear-gradient(left, #222851, #6a7dfe)",
    },
    background: {
      default: "#f2f2f2"
    },
    primary: {
      main: '#222851',
      inverse: "rgba(255, 255, 255, 1)",
      contrastText: '#fff',
    },
    secondary: {
      main: '#19857b',
      contrastText: '#fff',
    },
    white: {
      main: '#FFFFFF'
    },
    warning: {
      main: "#D29F09",
    },
    gray: {
      main: "#B2B2B2",
      secondary: "#E2E1E1"
    },
    error: {
      main: red.A400,
    },
  },
  layout: {
    drawerWidth: 300,
    subMenuHeight: 48
  }
})

export default theme
