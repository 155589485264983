import * as React from 'react'
import { styled } from '@mui/material/styles'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M8 5l5 5-5 5V5z"/></svg>')`, // Seta para a direita
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
    width: 32,
    height: 32,
    '&::before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M12 15l-5-5 5-5v10z"/></svg>')`, // Seta para a esquerda
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}))

const ToggleButtonSelect = (props) => {
  const [checked, setChecked] = React.useState(props.value)

  const handleChange = (event) => {
    const value = event.target.checked
    setChecked(value)
    props.onChange(value)
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography style={{ fontWeight: checked ? 'normal' : 'bold' }}>
        {props.leftLabel}
      </Typography>
      <FormControlLabel
        control={
          <MaterialUISwitch
            sx={{ m: 1 }}
            checked={checked}
            onChange={handleChange}
          />
        }
        label={
          <Typography style={{ fontWeight: checked ? 'bold' : 'normal' }}>
            {props.rightLabel}
          </Typography>
        }
      />
    </Stack>
  )
}

ToggleButtonSelect.propTypes = {
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func
}

export default ToggleButtonSelect
