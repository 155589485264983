import { saveAs } from 'file-saver'
import html2canvas from 'html2canvas'


export class ExportFile {

    // toCSV(data, fileName, separated) {
    //     let valueToSeparatedValues = ","
    
    //     if (separated !== null) {
    //         valueToSeparatedValues = separated
    //     }
    
    //     const header = Object.keys(data[0])
    //     let csv = header.join(valueToSeparatedValues) + '\n'
    
    //     data.forEach(objeto => {
    //         const linha = header.map(chave => objeto[chave])
    //         csv += linha.join(valueToSeparatedValues) + '\n'
    //     })
    
    //     const blob = new Blob(["\ufeff" + csv], { type: 'text/csv;charset=utf-8' })
    //     saveAs(blob, `${fileName}.csv`)
    // }

    toCSV(data, fileName, separated = ";") {
        // Definir o separador como ponto e vírgula
        let valueToSeparatedValues = separated || ";"
    
        // Criar o cabeçalho (headers)
        const header = Object.keys(data[0])
        let csv = header.join(valueToSeparatedValues) + '\n'  // Adicionar o cabeçalho com separadores corretos
    
        // Iterar pelos dados e montar as linhas do CSV
        data.forEach(objeto => {
            const linha = header.map(chave => {
                let valor = objeto[chave] !== undefined ? objeto[chave] : ''  // Pegar o valor ou string vazia se não existir
                // Se o valor for uma string e contém o separador, colocar entre aspas duplas
                if (typeof valor === 'string' && valor.includes(valueToSeparatedValues)) {
                    valor = `"${valor}"`
                }
                return valor 
            })
            // Adicionar a linha ao CSV, separando os valores por ponto e vírgula
            csv += linha.join(valueToSeparatedValues) + '\n'
        })
    
        // Criar o arquivo CSV e forçar a codificação UTF-8 com BOM para suportar caracteres especiais
        const blob = new Blob(["\ufeff" + csv], { type: 'text/csv;charset=utf-8' })
        saveAs(blob, `${fileName}.csv`)
    }
    
    
    

    toImage(element, fileName) {
        html2canvas(element).then((canvas) => {
            // Converta o canvas para uma imagem base64
            const image = canvas.toDataURL()

            // Crie um link de download
            const link = document.createElement('a')
            link.href = image
            link.download = `${fileName}.png`

            // Dispare o evento de clique no link para iniciar o download
            link.click()
        })
    }

    downloadPdf(path, file) {
        const link = document.createElement('a')
        link.href = `${path}/${file}`
        link.download = file
        
        document.body.appendChild(link)
        
        link.click()
        document.body.removeChild(link)
    }
}

