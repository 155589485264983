import { Button, Grid, Paper } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import AlertMessage from "src/components/alert-message"
import LineChartRangeDateModalComponent from "src/components/chart/line-chart-range-date-modal"
import LayoutPage from "src/components/page-layout/layout-page"
import PriceService from "src/services/price-service"
import { DATE_FORMAT_SHOW } from "src/utils/constants"
import { localeBRDataGridText } from "src/utils/data-grid-local-text"
import { formatDecimalCase, formatDecimalCaseReplaceComma } from "src/utils/format-decimal"

const PricePUPage = () => {
    const priceService = new PriceService()
    const [loading, setLoading] = useState(false)
    const [priceList, setPriceList] = useState([])
    const [priceSelectedList, setPriceSelectedList] = useState([])
    const [alertMessageState, setAlertMessageState] = useState({
        open: false,
        message: "",
        type: "info"
    })
    const [openChart, setOpenChart] = useState(false)
    const [chartConfig, setChartConfig] = useState({
        title: "Gráfico de PU",
        xTitle: "Data Referência",
        yTitle: "",
        xValue: [],
        values: []
    })
    const [csvData, setCsvData] = useState([])

    useEffect(() => {
        loadPrices()
    }, [])

    const generateDescription = (value) => {
        const { row } = value
        const { tipo_ativo, indicador, devedor, taxa_accrual } = row
        const devedor_nome_fantasia = devedor ? devedor : ""
        return `${tipo_ativo} ${indicador} ${formatDecimalCase(taxa_accrual, 2)} ${devedor_nome_fantasia}`
    }

    const columns = [
        {
            field: 'cetip',
            headerName: 'CETIP',
            flex: true,
        },
        {
            field: 'tipo_ativo',
            headerName: 'Descrição',
            flex: true,
            renderCell: generateDescription
        },
        {
            field: 'vencimento',
            headerName: 'Vencimento',
            flex: true,
            type: 'date',
            valueGetter: ({ value }) => value && dayjs(value).toDate(),
            valueFormatter: params => dayjs(params.value).format(DATE_FORMAT_SHOW),
        },
    ]

    const loadPrices = () => {
        setLoading(true)
        priceService.getPricesPU().then((response) => {
            const { body } = response
            const rows = body.map((price, index) => ({ ...price, id: index }))
            setPriceList(rows)
            setLoading(false)
            setChartConfig({ ...chartConfig })
        }).catch(() => {
            setLoading(false)
            openAlert("Não foi possível carregar os dados. Recarregue a págine.")
        })
    }

    const openAlert = (message, type) => {
        setAlertMessageState({
            ...alertMessageState,
            open: true,
            message: message,
            type: type
        })
    }

    const closeAlert = () => {
        setAlertMessageState({ ...alertMessageState, open: false })
    }

    const generateCsvData = (body) => {
        let data = body.map((row) => {
            const {
                DataReferencia, CETIP, PU_MTM, PUPAR, PercentualPUPAR, TaxaTIR, TaxaMTM
            } = row

            return {
                "DataReferencia": DataReferencia,
                "CETIP": CETIP,
                "PU_MTM": formatDecimalCaseReplaceComma(PU_MTM),
                "PUPAR": formatDecimalCaseReplaceComma(PUPAR),
                "PercentualPUPAR": formatDecimalCaseReplaceComma(PercentualPUPAR),
                "TaxaTIR": formatDecimalCaseReplaceComma(TaxaTIR),
                "TaxaMTM": formatDecimalCaseReplaceComma(TaxaMTM),
            }
        })
        setCsvData(data)
    }

    const loadChartData = (date) => {
        console.log("data na tela principal", date)
        console.log("price selected na tela principal", priceSelectedList)
        const cetipList = priceSelectedList.map(objeto => objeto.cetip)

        setLoading(true)
        priceService.generatePriceData(date.firstDate, date.endDate, cetipList).then((response) => {
            setLoading(false)
            const { body } = response
            generateCsvData(body)
            const chartData = priceService.generatePUChart(body)

            setChartConfig({
                ...chartConfig,
                yTitle: "PU MTM",
                xValue: chartData.dateList,
                values: chartData.PU_MTM
            })
        }).catch(() => {
            setLoading(false)
            setOpenChart(false)
            openAlert("Não foi possível carregar os dados, tente novamente", "warning")
        })
    }

    const onSelectRow = (rows) => {
        if (rows.length > 5) {
            openAlert("Selecione somente 5 CETIP`s", "warning")
            return
        }

        let selected = []
        rows.map((item) => {
            selected.push(priceList[item])
        })
        setPriceSelectedList(selected)
    }

    const openChartValidate = () => {
        if (priceSelectedList.length !== 0) {
            setOpenChart(true)
        } else {
            openAlert("Selecione ao menos 1 CETIP")
        }
    }

    return <LayoutPage title="Gráfico de PU" loading={loading}>
        <div>
            <AlertMessage open={alertMessageState.open} message={alertMessageState.message} type={alertMessageState.type} close={closeAlert} />
            <Grid container direction="row" alignItems={"center"} justifyContent="flex-end" spacing={3}>
                <Grid item>
                    <Button variant="contained" onClick={openChartValidate}>
                        Gerar Gráfico
                    </Button>
                </Grid>
            </Grid>
            <br />
            <Grid container direction="row" justifyContent="center" spacing={2}>
                <Grid item xl={12} md={12} xs={12}>
                    <Paper>
                        <DataGrid
                            autoHeight
                            checkboxSelection
                            getRowHeight={() => 'auto'}
                            rows={priceList}
                            columns={columns}
                            onRowSelectionModelChange={onSelectRow}
                            sortingOrder={['asc', 'desc']}
                            localeText={localeBRDataGridText}
                        />
                    </Paper>
                </Grid>
            </Grid>

            <LineChartRangeDateModalComponent
                open={openChart}
                disableXAxis
                onClose={() => setOpenChart(false)}
                title={`Gráfico de PU`}
                loadChartData={loadChartData}
                loading={loading}
                csvData={csvData}
                chartData={chartConfig}
            />
        </div>
    </LayoutPage>
}

export default PricePUPage