import { ServiceRequest } from "src/utils/service-request"

class CalcService {

    constructor() {
        this.serviceRequest = new ServiceRequest()
    }

    async loadEndpoint(endpoint) {
        return await this.serviceRequest.get(`${endpoint}?limit=10000`)
    }

    async createTableEndpoint(endpoint, body) {
        return await this.serviceRequest.post(`${endpoint}`, body)
    }

    async updateTableEndpoint(endpoint, id, body) {
        return await this.serviceRequest.patch(`${endpoint}/${id}`, body)
    }

    async deleteTableEndpoint(endpoint, id) {
        return await this.serviceRequest.delete(`${endpoint}/${id}`)
    }
}

export default CalcService