import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Carousel from "react-material-ui-carousel"
import Viewer from 'react-viewer'
import { Card, CardActionArea, CardMedia } from '@mui/material'

const ImageCarousel = (props) => {
    const [selectedImage, setSelectedImage] = useState(0)
    const [isViewerOpen, setIsViewerOpen] = useState(false)

    const handleOpen = (index) => {
        setSelectedImage(index)
        setIsViewerOpen(true)
    }

    const handleClose = () => {
        setSelectedImage(0) // Reinicia o índice ao fechar
        setIsViewerOpen(false)
    }

    const styles = {
        image: {
            cursor: 'pointer',
            width: '100%',
            height: '100%'
        }
    }

    return (
        <div>
            {props.images.length > 0 &&
                <Carousel autoPlay={false} fullHeightHover={false} >
                    {props.images.map((image, index) => (
                        <Card key={index}>
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image={image}
                                    style={styles.image}
                                    onClick={() => handleOpen(index)}
                                />
                            </CardActionArea>
                        </Card>
                    ))}
                </Carousel>
            }

            <Viewer
                visible={isViewerOpen}
                onClose={handleClose}
                images={props.images.map((src) => { return { src } })}
                activeIndex={selectedImage}
            />
        </div>
    )
}

ImageCarousel.propTypes = {
    images: PropTypes.array
}

export default ImageCarousel
