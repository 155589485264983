import { Button, Grid, TextField } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import React, { useEffect, useState } from "react"
import ModalDialogPage from "src/components/modal-dialog"
import TableGray from "src/components/table/table-gray"
import PropTypes from 'prop-types'
import EmissaoService from "src/services/emissao-service"
import dayjs from "dayjs"
import { DATE_FORMAT_SHOW } from "src/utils/constants"
import SkeletonLine from "src/components/skeleton-line"

const tableExample = [
    {
        cells: ["Razão Social: ", "CNPJ: "]
    },
    {
        cells: ["Setor: ", "Subsetor:"]
    }
]

const UltimoRatingDevedor = ({ cpfCnpj }) => {
    const ultimoRatingDevedorDefault = { data_validade: "", fonte: "", rating: "" }
    const [openMostrarTodos, setOpenMostrarTodos] = useState(false)
    const emissaoService = new EmissaoService()
    const [ultimoRatingDevedor, setUltimoRatingDevedor] = useState(ultimoRatingDevedorDefault)
    const [loadingRatingDevedor, setLoadingRatingDevedor] = useState(false)

    useEffect(() => {
        console.log('UltimoRatingDevedor: ', cpfCnpj)
        if (cpfCnpj) loadData(cpfCnpj)
    }, [cpfCnpj])

    const loadData = (cpfCnpj) => {
        setLoadingRatingDevedor(true)
        emissaoService.getUltimoRatingDevedor(cpfCnpj).then((response) => {
            const { body } = response
            const { data } = body

            if (data !== null) {
                const { DataValidade, fonte, Rating } = data

                setUltimoRatingDevedor({
                    data_validade: dayjs(DataValidade).format(DATE_FORMAT_SHOW),
                    fonte: fonte,
                    rating: Rating,
                })
            } else {
                setUltimoRatingDevedor(ultimoRatingDevedorDefault)
            }
        }).catch(() => {
            setUltimoRatingDevedor(ultimoRatingDevedorDefault)
        }).finally(() => {
            setLoadingRatingDevedor(false)
        })
    }

    return <>
        {loadingRatingDevedor ? <SkeletonLine /> :
            <Grid container direction="row" alignItems={"center"} justifyContent="space-between" spacing={2}>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <TextField fullWidth label="Data Validade" value={ultimoRatingDevedor.data_validade} />
                </Grid>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <TextField fullWidth label="Fonte" value={ultimoRatingDevedor.fonte} />
                </Grid>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <TextField fullWidth label="Rating" value={ultimoRatingDevedor.rating} />
                </Grid>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <Button fullWidth variant="contained" onClick={() => setOpenMostrarTodos(true)}>Mostrar Todos</Button>
                </Grid>
            </Grid>
        }

        <ModalDialogPage open={openMostrarTodos}
            title="Último Rating Devedor"
            close={() => {
                setOpenMostrarTodos(false)
            }}>
            <>
                <Grid container direction="column" spacing={2}>
                    <Grid item xs={3} md={3} xl={3}>
                        <TableGray data={tableExample} />
                    </Grid>
                </Grid>
                <br />
                <Grid container direction="row">
                    <Grid item xs={3} md={3} xl={3}>
                        <Button fullWidth variant="contained" onClick={() => { }}>+ Adicionar Rating</Button>
                    </Grid>
                </Grid>
                <br />
                <Grid container direction="column">
                    <Grid item xs={12} md={12} xl={12}>
                        <DataGrid
                            autoHeight
                            columns={[
                                { field: 'DataReferencia', headerName: 'Data Referencia', flex: 1 },
                                { field: 'DataValidade', headerName: 'Data Validade', flex: 1 },
                                { field: 'Setor', headerName: 'Setor', flex: 1 },
                                { field: 'Subsetor', headerName: 'Subsetor', flex: 1 },
                                { field: 'Fonte', headerName: 'Fonte', flex: 1 },
                                { field: 'ScoreRating', headerName: 'Score Rating', flex: 1 },
                                { field: 'Rating', headerName: 'Rating', flex: 1 },
                                { field: 'Sinal', headerName: 'Sinal', flex: 1 },
                                { field: 'Perspectiva', headerName: 'Perspectiva', flex: 1 },
                                { field: 'Observação', headerName: 'Observação', flex: 1 },
                                { field: 'AnáliseCreditoEmissao', headerName: 'Análise Crédito Emissão', flex: 1 },
                            ]}
                            rows={[]}
                        />
                    </Grid>
                </Grid>
            </>
        </ModalDialogPage>
    </>
}

UltimoRatingDevedor.propTypes = {
    cpfCnpj: PropTypes.string
}

export default UltimoRatingDevedor