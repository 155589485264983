import * as React from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import PropTypes from 'prop-types'


const SelectValue = (props) => {

    return <FormControl variant="standard" fullWidth>
        <InputLabel id={props.id}>{props.label}</InputLabel>
        <Select
            labelId={props.id}
            value={props.value}
            onChange={(event) => props.changeValue(event.target.value)}
            label={props.label}
        >
            {props.options.map((item, index) => <MenuItem key={index} value={item.value}>{item.description}</MenuItem>)}
        </Select>
    </FormControl>
}

SelectValue.propTypes = {
    options: PropTypes.array,
    value: PropTypes.string,
    changeValue: PropTypes.func,
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
}

export default SelectValue