import { MenuItem, TextField } from "@mui/material"
import React from "react"
import PropTypes from 'prop-types'

const ParameterIntervalField = (props) => {

    const onChange = (event) => {
        props.onChange(event.target.value)
    }

    return (
        <TextField
            label="Intervalo"
            select
            focused
            fullWidth
            value={props.value}
            onChange={onChange}>
            {props.filterList.map((interval, index) => <MenuItem key={index} value={interval.value}>{interval.description}</MenuItem>)}
        </TextField>
    )
}

ParameterIntervalField.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    filterList: PropTypes.array
}

export default ParameterIntervalField